import Rollbar from 'rollbar'
import packageJson from '../../package.json'

const rollbar = new Rollbar({
  accessToken: process.env.VUE_APP_ROLLBAR_API_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: true,
  payload: {
    environment: process.env.NODE_ENV,
    client: {
      javascript: {
        code_version: packageJson.version,
        source_map_enabled: true,
      },
    },
  },
})
// rollbar.configure()

export default rollbar
