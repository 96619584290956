export const ADD_FACILITY = 'ADD_FACILITY'
export const ADD_INPUT_ERROR = 'ADD_INPUT_ERROR'
export const ADD_PARTNER_HOLIDAY = 'ADD_PARTNER_HOLIDAY'
export const ADD_LTL_RETURN = 'ADD_LTL_RETURN'
export const ADD_PERMISSION_TO_USER = 'ADD_PERMISSION_TO_USER'
export const ADD_DROP_SHIP_PRODUCT = 'ADD_DROP_SHIP_PRODUCT'
export const APPLY_FACILITY = 'APPLY_FACILITY'
export const APPLY_PARTNER = 'APPLY_PARTNER'
export const APPLY_TOKEN = 'APPLY_TOKEN'
export const APPLY_VENDOR = 'APPLY_VENDOR'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_STEP = 'CHANGE_STEP'
export const CHECK_AUTH = 'CHECK_AUTH'
export const CLEAR_INPUT_ERRORS = 'CLEAR_INPUT_ERRORS'
export const CLEAR_DROP_SHIP_PRODUCTS = 'CLEAR_DROP_SHIP_PRODUCTS'
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS'
export const CREATE_EXPORT_PRODUCTS_JOB = 'CREATE_EXPORT_PRODUCTS_JOB'
export const CREATE_NOT_LISTED_REASON = 'CREATE_NOT_LISTED_REASON'
export const CREATE_VENDOR_PRODUCT = 'CREATE_VENDOR_PRODUCT'
export const DELETE_ASSEMBLY_INSTRUCTIONS = 'DELETE_ASSEMBLY_INSTRUCTIONS'
export const DELETE_FACILITY = 'DELETE_FACILITY'
export const DELETE_FILE = 'DELETE_FILE'
export const DELETE_IMAGES = 'DELETE_IMAGES'
export const DELETE_INPUT_ERROR = 'DELETE_INPUT_ERROR'
export const DELETE_PARTNER_HOLIDAY = 'DELETE_PARTNER_HOLIDAY'
export const DELETE_VENDOR_PRODUCT = 'DELETE_VENDOR_PRODUCT'
export const END_LOADER = 'END_LOADER'
export const EXPORT_PRODUCTS = 'EXPORT_PRODUCTS'
export const EXPORT_RBR_ON_HAND = 'EXPORT_RBR_ON_HAND'
export const FETCH_ASSEMBLY_INSTRUCTIONS = 'FETCH_ASSEMBLY_INSTRUCTIONS'
export const FETCH_ATTRIBUTE_VALUES = 'FETCH_ATTRIBUTE_VALUES'
export const FETCH_CATEGORY_ATTRIBUTES = 'FETCH_CATEGORY_ATTRIBUTES'
export const FETCH_CONDITION_TOOLTIP = 'FETCH_CONDITION_TOOLTIP'
export const FETCH_DISPOSAL_FEES = 'FETCH_DISPOSAL_FEES'
export const FETCH_DROP_SHIP_PRODUCTS = 'FETCH_DROP_SHIP_PRODUCTS'
export const FETCH_DROP_SHIP_QUEUE = 'FETCH_DROP_SHIP_QUEUE'
export const FETCH_DS_ACTIVITY_REPORT = 'FETCH_DS_ACTIVITY_REPORT'
export const FETCH_FACILITY = 'FETCH_FACILITY'
export const FETCH_FACILITIES = 'FETCH_FACILITIES'
export const FETCH_FACILITY_USERS = 'FETCH_FACILITY_USERS'
export const FETCH_GENERAL_COLORS = 'FETCH_GENERAL_COLORS'
export const FETCH_GENERAL_MATERIALS = 'FETCH_GENERAL_MATERIALS'
export const FETCH_ON_HAND_REPORT = 'FETCH_ON_HAND_REPORT'
export const FETCH_LISTED_ONS = 'FETCH_LISTED_ONS'
export const FETCH_LTL_RETURNS = 'FETCH_LTL_RETURNS'
export const FETCH_MANIFESTS = 'FETCH_MANIFESTS'
export const FETCH_NEAREST_RRC = 'FETCH_NEAREST_RRC'
export const FETCH_NOT_LISTED_REASONS = 'FETCH_NOT_LISTED_REASONS'
export const FETCH_ORDER = 'FETCH_ORDER'
export const FETCH_ORDER_LINE_LOG = 'FETCH_ORDER_LINE_LOG'
export const FETCH_ORDERS_PDF = 'FETCH_ORDERS_PDF'
export const FETCH_ORDERS = 'FETCH_ORDERS'
export const FETCH_PARTNER_ACTIVITY_REPORT = 'FETCH_PARTNER_ACTIVITY_REPORT'
export const FETCH_PARTNER_HOLIDAYS = 'FETCH_PARTNER_HOLIDAYS'
export const FETCH_PARTNER_USER = 'FETCH_PARTNER_USER'
export const FETCH_PARTNER_USERS = 'FETCH_PARTNER_USERS'
export const FETCH_PARTNERS = 'FETCH_PARTNERS'
export const FETCH_PRINTERS = 'FETCH_PRINTERS'
export const FETCH_PROCESSING_FEES = 'FETCH_PROCESSING_FEES'
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'
export const FETCH_RBR_ACTIVITY_REPORT = 'FETCH_RBR_ACTIVITY_REPORT'
export const FETCH_RUSH_PRIMARY_CATEGORIES = 'FETCH_RUSH_PRIMARY_CATEGORIES'
export const FETCH_RUSH_PRODUCT = 'FETCH_RUSH_PRODUCT'
export const FETCH_RUSH_PRODUCTS = 'FETCH_RUSH_PRODUCTS'
export const FETCH_RUSH_SECONDARY_CATEGORIES = 'FETCH_RUSH_SECONDARY_CATEGORIES'
export const FETCH_STORES = 'FETCH_STORES'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_VENDOR_PRODUCT = 'FETCH_VENDOR_PRODUCT'
export const FETCH_VENDORS = 'FETCH_VENDORS'
export const FULFILL_ORDER_ITEM = 'FULFILL_ORDER_ITEM'
export const GET_CATALOG_JOBS = 'GET_CATALOG_JOBS'
export const GET_EXPORT_PRODUCTS_JOBS = 'GET_EXPORT_PRODUCTS_JOBS'
export const GET_EXPORT_PRODUCTS_JOBS_FORMATS = 'GET_EXPORT_PRODUCTS_JOBS_FORMATS'
export const GET_INVENTORY_JOBS = 'GET_INVENTORY_JOBS'
export const GET_MASTER_DATA = 'GET_MASTER_DATA'
export const GET_VENDOR_PRODUCT = 'GET_VENDOR_PRODUCT'
export const GET_VENDOR_PRODUCTS = 'GET_VENDOR_PRODUCTS'
export const INITIATE_RESET_PASSWORD = 'INITIATE_RESET_PASSWORD'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const POST_CATALOG_JOBS = 'POST_CATALOG_JOBS'
export const POST_CATALOG_PARSE = 'POST_CATALOG_PARSE'
export const POST_FILES = 'POST_FILES'
export const POST_IMAGE_URL = 'POST_IMAGE_URL'
export const POST_IMAGES_MULTIPART = 'POST_IMAGES_MULTIPART'
export const POST_INVENTORY_JOBS = 'POST_INVENTORY_JOBS'
export const PUT_COIN = 'PUT_COIN'
export const PUT_VENDOR_PRODUCT = 'PUT_VENDOR_PRODUCT'
export const QUEUE_DROP_SHIP_PRODUCTS = 'QUEUE_DROP_SHIP_PRODUCTS'
export const QUEUE_DROP_SHIP_PRODUCTS_FILE = 'QUEUE_DROP_SHIP_PRODUCTS_FILE'
export const REMOVE_DROP_SHIP_PRODUCT = 'REMOVE_DROP_SHIP_PRODUCT'
export const REMOVE_PERMISSION_FROM_USER = 'REMOVE_PERMISSION_FROM_USER'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PRODUCT_PROCESS = 'RESET_PRODUCT_PROCESS'
export const RESET_LOADERS = 'RESET_LOADERS'
export const SAVE_BREAKPOINT = 'SAVE_BREAKPOINT'
export const SAVE_FACILITY = 'SAVE_FACILITY'
export const SAVE_IS_TRUCKLOAD = 'SAVE_IS_TRUCKLOAD'
export const SAVE_LOCATE_RESULTS = 'SAVE_LOCATE_RESULTS'
export const SAVE_LTL_RETURN = 'SAVE_LTL_RETURN'
export const SAVE_MANIFEST = 'SAVE_MANIFEST'
export const SAVE_PARTNER_ACCOUNTING = 'SAVE_PARTNER_ACCOUNTING'
export const SAVE_PARTNER_BUSINESS_ENTITY = 'SAVE_PARTNER_BUSINESS_ENTITY'
export const SAVE_PARTNER_CUSTOMER_SERVICE = 'SAVE_PARTNER_CUSTOMER_SERVICE'
export const SAVE_PARTNER_HOLIDAY = 'SAVE_PARTNER_HOLIDAY'
export const SAVE_PARTNER_USER = 'SAVE_PARTNER_USER'
export const SAVE_PRINTER = 'SAVE_PRINTER'
export const SAVE_RECEIVE_RESULTS = 'SAVE_RECEIVE_RESULTS'
export const SAVE_STEP_RECEIVE = 'SAVE_STEP_RECEIVE'
export const SAVE_STEP_VERIFY = 'SAVE_STEP_VERIFY'
export const SAVE_STEP_RESHIPPING = 'SAVE_STEP_RESHIPPING'
export const SAVE_STEP_CONDITION = 'SAVE_STEP_CONDITION'
export const SAVE_STEP_LOCATE = 'SAVE_STEP_LOCATE'
export const SAVE_USER = 'SAVE_USER'
export const SAVE_VENDOR = 'SAVE_VENDOR'
export const SET_STATE_PROP = 'SET_STATE_PROP'
export const START_LOADER = 'START_LOADER'
export const UPDATE_CURRENT_PARTNER = 'UPDATE_CURRENT_PARTNER'
export const UPDATE_ORDER_LINE = 'UPDATE_ORDER_LINE'
export const UPDATE_ORDER_LINES = 'UPDATE_ORDER_LINES'
export const UPDATE_PARTNER_USER = 'UPDATE_PARTNER_USER'
export const UPDATE_STEP = 'UPDATE_STEP'
export const VALIDATE_DROP_SHIP_PRODUCTS = 'VALIDATE_DROP_SHIP_PRODUCTS'
export const VALIDATE_DROP_SHIP_PRODUCTS_FILE = 'VALIDATE_DROP_SHIP_PRODUCTS_FILE'