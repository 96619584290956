import {
  ADD_DROP_SHIP_PRODUCT,
  CLEAR_DROP_SHIP_PRODUCTS,
  FETCH_DROP_SHIP_PRODUCTS,
  FETCH_DROP_SHIP_QUEUE,
  QUEUE_DROP_SHIP_PRODUCTS,
  QUEUE_DROP_SHIP_PRODUCTS_FILE,
  REMOVE_DROP_SHIP_PRODUCT,
  VALIDATE_DROP_SHIP_PRODUCTS,
  VALIDATE_DROP_SHIP_PRODUCTS_FILE,
} from "./actions.type"
import {
  SET_DROP_SHIP_PRODUCTS,
  SET_DROP_SHIP_QUEUE,
  SET_EDIT_DROP_SHIP_PRODUCTS,
  SET_EDIT_DROP_SHIP_TOTAL_ROWS,
  SET_EDIT_ORIGINAL_DROP_SHIP_PRODUCTS,
} from "./mutations.type"
import ApiService from '@/services/api.service'
import { getMainImage, filterObjectProperties, replaceNull } from '@/shared/utils'
import { cloneDeep, get, has, groupBy } from 'lodash'

const MAX_LIMITED_QTY = 20

const state = {
  editDropShipProducts: {},
  editDropShipTotalRows: 0,
  editOriginalDropShipProducts: {},
  dropShipProducts: {},
  dropShipQueue: [],
  selectedVendorSku: [],
}

const getters = {
  editDropShipProducts: state => state.editDropShipProducts,
  editDropShipTotalRows: state => state.editDropShipTotalRows,
  editOriginalDropShipProducts: state => state.editOriginalDropShipProducts,
  dropShipProducts: state => state.dropShipProducts,
  dropShipQueue: state => state.dropShipQueue,
  selectedVendorSkus: state => state.selectedVendorSku,
  editDropShipProductsByVendorSku: state => groupBy(state.editDropShipProducts, 'vendorSku')
}

const actions = {
  /**
   * added a dropShipProduct to be selected
   * @param {Object} context
   * @param {Object} product
   */
  [ADD_DROP_SHIP_PRODUCT] (context, product) {
    let products = context.state.dropShipProducts
    // keep these properties from the product so we don't overpollute the data
    const filterProps = ['productName','vendorSku','upc','mpn','secondaryCategory','productCost']
    const setItem = {
      ...filterObjectProperties(product, filterProps),
      image: getMainImage(product),
      quantity: 1,
      percentOffWholesale: 50,
      action: 'SET_QTY',
      //exclusiveToRush: 'Yes',
      _isValid: null,
      _errors: {},
    }
    products[product.vendorSku] = setItem
    context.commit(SET_DROP_SHIP_PRODUCTS, cloneDeep(products))
  },
  /**
   * clear all the selected dropShipProducts
   * @param {Object} context
   */
  [CLEAR_DROP_SHIP_PRODUCTS] (context) {
    context.commit(SET_DROP_SHIP_PRODUCTS, {})
  },
  /**
   * get Rush SKUs that are dropship
   * @param {Object} context
   * @param {String} params.vendorId
   * @param {Integer} [params.sku]
   * @param {String} [params.vendorSku] - single or comma list
   * @param {String} [params.upc]
   * @param {String} [params.mpn]
   * @param {Array} [params.status=[Live,Received]] - array of statuses
   * @param {String} [params.dropshipType=LIMITED]
   * @param {String} [params.manifestSource=DS]
   * @param {Integer} [params.limit=9999]
   * @param {Integer} [params.page=1]
   * @returns
   */
  async [FETCH_DROP_SHIP_PRODUCTS] (context, {
    vendorId,
    sku=null,
    vendorSku=null,
    upc=null,
    mpn=null,
    status=['Live','Received'],
    dropshipType='LIMITED',
    manifestSource='DS',
    limit=9999,
    page=1
  }) {
    let agg = []
    try {
      const response = await ApiService.query(`/rushProducts`, {
        params: {
          vendorId: vendorId,
          currentPage: page,
          limit,
          sku,
          vendorSku,
          upc,
          mpn,
          manifestSource,
          dropshipType,
          status: status.join(',')
        }
      })
      const products = get(response,'data.data.rushProducts',[])
      // roll up the product quantity
      agg = products.reduce((acc,item) => {
        const idx = acc.findIndex(i => i.vendorSku === item.sellerProductId)
        if (idx >= 0) {
          acc[idx].quantity += get(item,'limitedQuantity',1)
        } else {
          acc.push({
            ...item,
            productName: item.name,
            vendorSku: item.sellerProductId,
            secondaryCategory: item.category2,
            percentOffWholesale: get(item,'dsPercentOffWholesale') || 0,
            quantity: get(item,'limitedQuantity',1)
          })
        }
        return acc
      },[])
    } catch ({response}) {
      // suppress 404 since in this case it just means nothing was found
      if (response.statusCode !== 404) {
        console.error('fetching rushProducts failed -> ', response)
        throw (response)
      }
    }
    context.commit(SET_EDIT_DROP_SHIP_PRODUCTS, replaceNull(agg))
    context.commit(SET_EDIT_DROP_SHIP_TOTAL_ROWS, agg.length)
    context.commit(SET_EDIT_ORIGINAL_DROP_SHIP_PRODUCTS, cloneDeep(replaceNull(agg))) // save a copy of the original products when loaded
    return agg
  },
  /**
   * fetch products that are in the dropship queue - keyed by vendorSku
   * @param {Object} context
   * @param {String} params.vendorId
   * @param {String} [params.vendorSku] - single or comma list
   * @param {String} [params.status]
   */
  async [FETCH_DROP_SHIP_QUEUE] (context, { vendorId, vendorSku, status }) {
    let products = {}
    try {
      const { data } = await ApiService.query(`/vendors/${vendorId}/dropshipProductQueue`, {
        params: {
          vendorSku,
          status,
        }
      })
      products = get(data,'data.products',[]).reduce((acc,item) => {
        if (!has(acc,item.vendorSku)) {
          acc[item.vendorSku] = {
            items: [item]
          }
        } else {
          acc[item.vendorSku].items.push(item)
        }
        return acc
      }, {})
    } catch ({response}) {
      // suppress 404 since in this case it just means nothing was found
      if (response.statusCode !== 404) {
        console.error('fetching dropship queue failed -> ', response)
        throw (response)
      }
    }
    context.commit(SET_DROP_SHIP_QUEUE, products)
    return products
  },
  /**
   * add dropShipProducts to queue
   * @param {Object} context
   * @param {Object} params.dropShipProducts
   * @param {String} params.vendorId
   * @param {String} params.page - edit | create
   * @returns
   */
  async [QUEUE_DROP_SHIP_PRODUCTS] (context, { dropShipProducts, vendorId, page='create' }) {
    // validate the selected products
    const resp = await context.dispatch(VALIDATE_DROP_SHIP_PRODUCTS, cloneDeep(dropShipProducts))
    if (page === 'edit') {
      // attach errors to the edit dropship products
      const ds = cloneDeep(context.state.editDropShipProducts)
      Object.entries(resp.dropShipProducts).forEach(([key,value]) => {
        const idx = ds.findIndex(item=>item.vendorSku===key)
        if(idx >= 0) {
          ds[idx]._errors = value._errors
        }
      })
      context.commit(SET_EDIT_DROP_SHIP_PRODUCTS, ds)
    } else {
      context.commit(SET_DROP_SHIP_PRODUCTS, cloneDeep(resp.dropShipProducts))
    }
    if (resp.errorCount > 0) {
      return {
        success: false,
        message: `${resp.errorCount} product(s) errored.`,
        dropshipProducts: cloneDeep(resp.dropShipProducts),
      }
    }

    // prepare the data for the API
    // convert the dropShipProducts object to an array and make sure Yes/No = Y/N
    //const props = ['vendorSku','quantity','percentOffWholesale','exclusiveToRush']
    const props = ['vendorSku','quantity','percentOffWholesale','action']
    let products = Object.values(cloneDeep(dropShipProducts))
      .map(product => ({ ...filterObjectProperties(product, props) }))
      //.map(product => ({ ...product, exclusiveToRush: (product.exclusiveToRush === 'Yes') ? 'Y' : 'N'  }))
    // split multiple actions out to single lines
    let splitProducts = []
    products.forEach(product => {
      const actions = product.action.split(',')
      actions.forEach(action => splitProducts.push({ ...product, action }))
    })
    products = splitProducts

    const { data } = await ApiService.post(`/vendors/${vendorId}/dropshipProductQueue`, products)
    const results = get(data,'data.products',[])

    // successful queueing - refetch dropShipQueue
    if (page === 'edit') {
      context.dispatch('FETCH_DROP_SHIP_QUEUE', { vendorId, status: 'NULL' })
    }

    let numProductsQueued = 0
    let numProductsErrored = 0

    // map results into object by vendorSku
    const mappedResults = results.reduce((obj, item) => {
      obj[item.vendorSku] = { ...item }
      return obj
    },{})

    // combine the messages from the results into the dropShipProducts
    const invalidProducts = Object.entries(dropShipProducts).reduce((obj, [key,item]) => {
      // if the results dont contain the vendorSku, just return what was originally there
      if (!has(mappedResults,item.vendorSku)) {
        obj[key] = { ...item }
        return obj
      }
      // return only invalid items
      const isValid = ([200,201].includes(mappedResults[item.vendorSku].statusCode)) ? true : false
      if (isValid) {
        ++numProductsQueued
      } else {
        ++numProductsErrored
        obj[key] ={
          ...item,
          _isValid: isValid,
          _errors: (get(mappedResults,item.vendorSku))
            ? {
                productName: {
                  invalidFeedback: mappedResults[item.vendorSku].message,
                  isValid: isValid
                }
              }
            : null,
        }
      }
      return obj
    },{})
    context.commit(SET_DROP_SHIP_PRODUCTS, invalidProducts)

    // build the message back
    let messages = []
    if (numProductsErrored > 0) {
      messages.push(`${numProductsErrored} product(s) errored.`)
      if (numProductsQueued > 0) {
        messages.push(`${numProductsQueued} product(s) were queued and removed.`)
      }
    } else {
      messages.push(`${numProductsQueued} product(s) have been queued!`)
    }

    return {
      success: (Object.keys(invalidProducts).length) ? false : true,
      message: messages.join(' ')
    }
  },
  /**
   * add dropShipProducts to queue via spreadsheet upload
   * @param {Object} context
   * @param {Array} params.dropShipProducts
   * @param {String} params.vendorId
   * @returns
   */
  async [QUEUE_DROP_SHIP_PRODUCTS_FILE] (context, { dropShipProducts, vendorId }) {
    // pre-validate the contents of the file and return if errors
    let { validatedProducts, errorCount } = await context.dispatch(VALIDATE_DROP_SHIP_PRODUCTS_FILE, dropShipProducts)
    if (errorCount > 0) {
      return { results: validatedProducts, errorCount: errorCount }
    }
    validatedProducts = validatedProducts.map(item => ({ ...item, action: 'SET_QTY' }))
    const { data } = await ApiService.post(`/vendors/${vendorId}/dropshipProductQueue`, validatedProducts)
    const results = get(data,'data.products',[])
    // map results into object by vendorSku
    const mappedResults = results.reduce((obj, item) => {
      obj[item.vendorSku] = { ...item }
      return obj
    },{})
    // combine the messages from the results into the dropShipProducts
    const mappedProducts = dropShipProducts.map(item => ({
      ...item,
      message: (get(mappedResults,item.vendorSku)) ? mappedResults[item.vendorSku].message : ''
    }))
    return { results: mappedProducts, errorCount: 0 }
  },
  /**
   * remove a dropShipProduct from being selected
   * @param {Object} context
   * @param {String} vendorSku
   */
  [REMOVE_DROP_SHIP_PRODUCT] (context, vendorSku) {
    let products = context.state.dropShipProducts
    delete products[vendorSku]
    context.commit(SET_DROP_SHIP_PRODUCTS, cloneDeep(products))
  },
  /**
   * validate the dropShipProducts in state
   * @param {Object} context
   * @param {Object} dropShipProducts
   * @returns
   */
  [VALIDATE_DROP_SHIP_PRODUCTS] (context, dropShipProducts) {
    let errorCount = 0
    Object.values(dropShipProducts).forEach(product => {
      let errors = {}
      if (product.percentOffWholesale < 0 || product.percentOffWholesale > 100) {
        errors['percentOffWholesale'] = { isValid: false, invalidFeedback: `% Off Wholesale must be 0-100` }
      }
      if (product.quantity < 0) {
        errors['quantity'] = { isValid: false, invalidFeedback: `Quantity minimum is 0` }
      } else if (product.quantity > MAX_LIMITED_QTY) {
        // because this system is currently being abused by vendors, instituting a max limit. This will get pulled out once
        // it moves to a single rush SKU. Then they can create whatever quantity they want
        errors['quantity'] = { isValid: false, invalidFeedback: `Quantity max is ${MAX_LIMITED_QTY}` }
      }
      product._errors = errors
      if (Object.keys(errors).length) {
        ++errorCount
      }
    })
    return { errorCount, dropShipProducts }
  },
  /**
   * validate the dropShipProducts file upload
   * @param {Object} context
   * @param {Array} dropShipProducts
   * @returns
   */
  [VALIDATE_DROP_SHIP_PRODUCTS_FILE] (context, dropShipProducts) {
    let errorCount = 0
    //const required = ['vendorSku', 'quantity', 'percentOffWholesale', 'exclusiveToRush']
    const required = ['vendorSku', 'quantity', 'percentOffWholesale']
    dropShipProducts.forEach(product => {
      let errors = []
      let fields = Object.keys(product).map(item=>item.toLowerCase())
      let missingFields = required.filter(field => !fields.includes(field.toLowerCase()))
      if (missingFields.length) {
        errors.push(`Missing fields: ${missingFields}`)
      }
      if (product.percentOffWholesale < 0 || product.percentOffWholesale > 100) {
        errors.push('Percent Off Wholesale: must be 0-100')
      }
      if (product.quantity < 0) {
        errors.push('Quantity: minimum is 0')
      } else if (product.quantity > MAX_LIMITED_QTY) {
        // force quantity to max if greater instead of an error
        //errors.push(`Quantity: max is ${MAX_LIMITED_QTY}`)
        product.quantity = MAX_LIMITED_QTY
      }
      if (errors.length) {
        errorCount++
      }
      product.message = errors.join(', ')
    })
    return {
      validatedProducts: dropShipProducts,
      errorCount,
    }
  }
}

const mutations = {
  [SET_DROP_SHIP_PRODUCTS] (state, products){
    state.dropShipProducts = products
    state.selectedVendorSku = Object.keys(products)
  },
  [SET_DROP_SHIP_QUEUE] (state, queue) {
    state.dropShipQueue = queue
  },
  [SET_EDIT_DROP_SHIP_PRODUCTS] (state, products) {
    state.editDropShipProducts = products
  },
  [SET_EDIT_DROP_SHIP_TOTAL_ROWS] (state, num) {
    state.editDropShipTotalRows = num
  },
  [SET_EDIT_ORIGINAL_DROP_SHIP_PRODUCTS] (state, products) {
    state.editOriginalDropShipProducts = products
  }
}

export default {
  name: 'dropShipProducts',
  state,
  actions,
  mutations,
  getters
}
