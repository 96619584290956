const parseDigits = /\d/g

/**
 * Formats a phone number as (xxx) xxx-xxxx
 * @param {any} value
 * @returns {string}
 */
export default function phoneFormat (value) {
  if (typeof value !== 'string') {
    return value
  }
  const digits = Array.from(value.matchAll(parseDigits), m => m[0])
  if (digits.length === 10) {
    const areaCode = digits.slice(0, 3)
    const exchangeCode = digits.slice(3, 6)
    const subscriberNumber = digits.slice(6, 11)
    return `(${areaCode.join('')}) ${exchangeCode.join('')}-${subscriberNumber.join('')}`
  }
  return value
}
