import {
  ADD_INPUT_ERROR,
  CLEAR_INPUT_ERRORS,
  DELETE_INPUT_ERROR,
} from "./actions.type"
import {
  SET_INPUT_ERRORS
} from './mutations.type'


const state = {
  inputErrors: []
}

const getters = {
  inputErrors: state => state.inputErrors
}

const actions = {
  /**
   * add an error
   * @param {Object} context
   * @param {String} params.key
   * @param {String} params.message
   */
  [ADD_INPUT_ERROR] (context, { key, message }) {
    let errors = context.state.inputErrors
    errors.push({ key, message })
    context.commit(SET_INPUT_ERRORS, errors)
  },
  /**
   * clear all errors
   * @param {Object} context
   */
  [CLEAR_INPUT_ERRORS] (context) {
    context.commit(SET_INPUT_ERRORS, [])
  },
  /**
   * delete a specific error
   * @param {Object} context
   * @param {String|Array} params.key
   */
  [DELETE_INPUT_ERROR] (context, key) {
    let errors = context.state.inputErrors
    if (Array.isArray(key)) {
      errors = errors.filter(item => !key.includes(item.key))
    } else {
      errors = errors.filter(item => item.key !== key)
    }
    context.commit(SET_INPUT_ERRORS, errors)
  }
}

const mutations = {
  [SET_INPUT_ERRORS] (state, errors) {
    state.inputErrors = [...errors]
  }
}

export default {
  name: 'errors',
  state,
  actions,
  mutations,
  getters
}
