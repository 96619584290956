/**
 * Formats a value to Yes/No
 * @param {any} value
 * @returns {string|null}
 */
 export default function yesNoFormat (value) {
  if (!value) {
    return null
  }
  return ['1','y','yes','true'].includes(value.toString().toLowerCase()) ? 'Yes' : 'No'
}