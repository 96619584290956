<template>
  <CModal v-bind="{ ...$attrs, ...$props }" :show="show" @update:show="close($event, null)" class="thisCustomModal">
    <template #body-wrapper>
      <div :class="bodyWrapperClasses">
        <slot />
      </div>
      <!-- include a loading overlay when loading is true -->
      <Overlay v-if="loadingOverlay" :loading="loading" selector="thisCustomModal" rounded />
    </template>
    <!-- replace header with just a right aligned x for closing -->
    <template v-if="hideHeader" #header-wrapper>
      <span
        class="position-absolute"
        style="right: 10px; top: 5px; z-index: 50"
        @click="close(false, $event)"
        v-if="!hideX"
      >
        <CIcon name="cilX" />
      </span>
      <span v-else />
    </template>
    <!-- replace footer with empty span -->
    <template v-if="hideFooter" #footer-wrapper><span /></template>
    <template v-else #footer>
      <div v-show="!hideFooter">
        <CButton color="secondary" class="mr-2" @click="close(false, $event)" :disabled="loading">{{
          secondaryLabel
        }}</CButton>
        <CButton color="primary" @click="handleOk" :disabled="loading || okDisabled">{{ primaryLabel }}</CButton>
      </div>
    </template>
  </CModal>
</template>

<script>
import Overlay from '@/components/template/ui/Overlay'
export default {
  name: 'Modal',
  components: {
    Overlay,
  },
  props: {
    show: { type: Boolean, required: true },
    hideFooter: { type: Boolean, required: false, default: false },
    hideHeader: { type: Boolean, required: false, default: false },
    hideX: { type: Boolean, required: false, default: false },
    primaryLabel: { type: String, required: false, default: 'OK' },
    secondaryLabel: { type: String, required: false, default: 'Cancel' },
    closeOnOk: { type: Boolean, required: false, default: true },
    closeOnBackdrop: { type: Boolean, required: false, default: true },
    backdrop: { type: Boolean, required: false, default: true },
    loading: { type: Boolean, required: false, default: false },
    loadingOverlay: { type: Boolean, required: false, default: false },
    okDisabled: { type: Boolean, required: false, default: false },
    noBodyScroll: { type: Boolean, required: false, default: false },
    bodyWrapperClasses: { type: String, required: false, default: 'modal-body' },
  },
  methods: {
    close(show, event) {
      if (this.noBodyScroll) {
        document.body.classList.remove('no-scroll')
      }
      this.$emit('close', { show, event })
    },
    handleOk(event) {
      this.$emit('ok', event)
      if (this.closeOnOk) {
        this.close(false, event)
      }
    },
  },
  watch: {
    // when the modal is open, make the body non-scrollable so the modal takes over fullscreen
    show(val) {
      if (this.noBodyScroll) {
        if (val) {
          document.body.classList.add('no-scroll')
        } else {
          document.body.classList.remove('no-scroll')
        }
      }
    },
  },
}
</script>
