import { mapGetters } from 'vuex'
import { has, get } from 'lodash'

export default {
  name: 'globalMixin',
  computed: {
    ...mapGetters([
      'isAdmin',
      'isAuthenticated',
      'isPartner',
      'isInternal',
      'isVendor',
      'currentFacility',
      'currentFacilityId',
      'currentPartner',
      'currentPartnerId',
      'currentStoreId',
      'currentUser',
      'currentUserPermissions',
      'currentVendor',
      'currentVendorId',
      'currentVendorName',
      'stores',
      'env'
    ]),
    urlScheme () {
      return (this.env !== 'production') ? 'http:' : 'https:'
    },
    showAppBreadcrumbs () {
      return this.isAuthenticated && !get(this.$route,'meta.hideBreadcrumbs',false)
    },
    showAppSideBar () {
      return this.isAuthenticated && (this.currentVendorId || this.currentPartnerId) && !get(this.$route,'meta.hideSideBar',false)
    },
  },
  methods: {
    hasPermission (type, permission) {
      let hasPermission = false

      // -----------------------------
      // internal/corelink users can have permissions
      // -----------------------------
      if (this.isInternal) {
        // if the permission exists, lets see if the user has it, or if it is inactive
        if (has(this.currentUserPermissions, type) && has(this.currentUserPermissions[type], permission)) {
          const perm = this.currentUserPermissions[type][permission]
          if (perm.active === 'N' || perm.hasPermission === 'Y') {
            hasPermission = true
          }
        } else {
          // if the permission does not exist, then all users have permission by default
          hasPermission = true
        }
      } else {
        // -----------------------------
        // check external user to see if they have permission
        // -----------------------------

        if (this.isAdmin) {
          hasPermission = true
        } else if (has(this.currentUserPermissions, type) && has(this.currentUserPermissions[type], permission)) {
          const perm = this.currentUserPermissions[type][permission]
          // permission is not active or they have permission
          if (perm.active === 'N' || perm.hasPermission === 'Y') {
            hasPermission = true
          }
        } else {
          // permission does not exist, everyone has permission
          hasPermission = true
        }
      }
      return hasPermission
    }
  }
}
