import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import JwtService from '@/services/jwt.service'
import { get } from 'lodash'
import store from '@/store'
import { hasVendorPermission, actionForTask, actionForExternal } from '@/utils/service.utils'




// intercept the responses and "fix" the error response
// we alwways want an object { response: { message, statusCode }}
axios.interceptors.response.use(
  function(response) {
    return response
  },
  function(error) {
    // Do something with response error
    let { response } = error
    if (response && response.data && response.data.message) {
      return Promise.reject({
        response: {
          message: response.data.message || 'unknown error',
          statusCode: response.data.statusCode || 401,
        },
      })
    } else if (error.response) {
      // convert it so that we can use "({ response }) =>" in a catch
      return Promise.reject({
        response: {
          message: error.response.statusText || 'unknown message',
          statusCode: error.response.status || 0,
        },
      })
    }
    return Promise.reject(error)
  }
)

// intercept the requests and update the app id and attach the access token
axios.interceptors.request.use(
  config => {

    const isInternal = get(store.getters,'isInternal',false)
    const isVendor = get(store.getters,'isVendor',false)

    // if a vendor is making this request, make sure they have the permission if defined
    const allowed = hasVendorPermission(config, { isVendor })
    if (!allowed) {
      throw new axios.Cancel('403 Operation canceled.')
    }

    config = actionForTask(config)
    config = actionForExternal(config, { isInternal })

    // pass every time
    config.headers.common['X-ACCESS-TOKEN'] = `${JwtService.getToken()}`

    return config
  },
  error => Promise.reject(error)
)

// main api service
const ApiService = {
  // when initialized, set these values
  init() {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL
    Vue.axios.defaults.headers.common['X-APP-ID'] = process.env.VUE_APP_API_APP_ID
    Vue.axios.defaults.headers.common['X-DEVICE-ID'] = 'rrc'
  },

  query(resource, params) {
    return Vue.axios.get(resource, params)
  },

  get(resource, slug = '') {
    return Vue.axios.get(`${resource}/${slug}`)
  },

  post(resource, params, headers) {
    return Vue.axios.post(`${resource}`, params, headers)
  },

  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params)
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params)
  },

  delete(resource, params) {
    return Vue.axios.delete(resource, params)
  },
}

export default ApiService