
import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper'
import {
  FETCH_PRINTERS,
  SAVE_PRINTER
} from "./actions.type";
import {
  SET_PRINTERS,
  SET_PRINTER
} from "./mutations.type";

const state = {
  printers: [],
  printer: null
}

const getters = {
  printers: state => state.printers,
  printer: state => state.printer
}

const actions = {

  /**
   * get a list of printed and set the default if not already done
   * @param {Object} context 
   * @returns 
   */
  async [FETCH_PRINTERS] (context) {
    let printers = []
    let error = null
    try {
      const browserPrint = new ZebraBrowserPrintWrapper()
      // if the printer has not been selected yet, set the default
      if (context.state.printer === null) {
        try {
          const defaultPrinter = await browserPrint.getDefaultPrinter()
          if (defaultPrinter) {
            defaultPrinter.description = `${defaultPrinter.manufacturer} - ${defaultPrinter.name}`
          }
          context.commit(SET_PRINTER, defaultPrinter)
        } catch (err) {
          console.error('Error fetching default printer -> ', err)
        }
      }
      const response = await browserPrint.getAvailablePrinters()
      if (!Array.isArray(response)) {
        error = response.message
      } else {
        printers = response.map(item=>({
          ...item,
          description: `${item.manufacturer} - ${item.name}`
        }))
      }
    } catch (err) {
      error = err
      console.error('Error fetching printers => ', err)
    }
    context.commit(SET_PRINTERS, printers)
    return error
  },
  
  /**
   * save the printer selection
   * @param {Object} context 
   * @param {Object|String} printer  ??? not sure on type yet
   */
  [SAVE_PRINTER] (context, printer) {
    context.commit(SET_PRINTER, printer)
  },

}

const mutations = {
  [SET_PRINTERS] (state, printers) {
    state.printers = printers
  },
  [SET_PRINTER] (state, printer) {
    state.printer = printer
  }
}

export default {
  name: 'printers',
  state,
  actions,
  mutations,
  getters
}
