/**
 * Formats a value like currency (ie $10.00)
 * @param {number} value
 * @param {integer} decimals
 * @returns
 */
export default function currency (value, decimals=2, defaultValue=0) {
  // if the valud coming in is a string, check to see if it is actually a numeric as a string like: '22.99'
  // this is because sometimes the API will return a number formatted as a string
  if (typeof value === "string") {
    if (!isNaN(value) && !isNaN(parseFloat(value))) {
      value = parseFloat(value)
    }
  }
  if (typeof value !== "number" || isNaN(value)) {
    value = defaultValue
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimals
  })
  return formatter.format(value)
}