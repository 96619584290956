import ApiService from '@/services/api.service'
import { FETCH_VENDORS, SAVE_VENDOR } from './actions.type'
import { SET_ERROR, SET_VENDORS } from './mutations.type'
import { get } from 'lodash'
import { getErrorMessage } from '@/shared/utils'

const state = {
  vendors: [],
  errors: [],
}

const getters = {
  vendors: state => state.vendors,
  vendorsById: state => state.vendors.reduce((acc, item) => { 
    acc[item.id] = item
    return acc
  }, {}),
  ltlReturnVendors: (state, getters) => get(getters,'vendors',[]).filter(item => item.rrcAllowLtlReturns === 'Y'),
  currentVendorName: (state, getters) => {
    if (getters.currentVendorId === 'current') {
      return get(getters.vendor,'name','')
    }
    return get(state.vendors.find(v => v.id === getters.currentVendorId), 'name', '')
  }
}

const actions = {
  [FETCH_VENDORS](context) {
    return new Promise((resolve, reject) => {
      ApiService.query('vendors', {
        params: {
          limit: 9999,
        },
      })
        .then(({ data }) => {
          const vendors = data.data.vendors.map(v => {
            return { label: v.name, value: v.id, ...v }
          })
          context.commit(SET_VENDORS, vendors)
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [getErrorMessage(response)])
          reject(response)
        })
    })
  },
  /**
   * save changes to a vendor
   * @param {*} context
   * @param {Object} vendorData - containing 1 or more data points of a vendor
   * @returns
   */
  [SAVE_VENDOR] (context, vendorData) {
    return ApiService.put('vendors/current', { ...vendorData })
  },
}

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error
  },
  [SET_VENDORS](state, vendors) {
    state.vendors = vendors
  },
}

export default {
  name: 'vendors',
  state,
  actions,
  mutations,
  getters,
}
