import ApiService from '@/services/api.service'
import { 
  SAVE_PARTNER_BUSINESS_ENTITY,
} from './actions.type'
import TIMEZONES from '@/shared/const/timezones.const'
import STATES from '@/shared/const/states.const'
import { validateEmail, replaceEmpty } from '@/shared/utils'

const validateBusinessEntity = (data) => {
  let errors = []
  if (!data.name){
    errors.push({ key: 'name', message: `Name is required` })
  }
  if (!data.taxIdNumber){
    errors.push({ key: 'taxIdNumber', message: `Tax ID is required` })
  } 
  if (!data.primaryContactName){
    errors.push({ key: 'primaryContactName', message: `Primary Contact Name is required` })
  }
  if (!validateEmail(data.primaryContactEmail)){
    errors.push({ key: 'primaryContactEmail', message: `A valid Primary Contact Email is required` })
  }
  if (!data.primaryContactPhone){
    errors.push({ key: 'primaryContactPhone', message: `Primary Contact Phone is required` })
  }
  if (!data.companyAddress1){
    errors.push({ key: 'companyAddress1', message: `Address 1 is required` })
  }
  if (!data.companyCity){
    errors.push({ key: 'companyCity', message: `City is required` })
  }        
  if (!data.companyStateOrProvince){
    errors.push({ key: 'companyStateOrProvince', message: `State is required` })
  } else {
    const stateArr = STATES.map(item=>item.code)
    if (!stateArr.includes(data.companyStateOrProvince)) {
      errors.push({ key: 'companyStateOrProvince', message: `State [${data.companyStateOrProvince}] is not recognized` })
    }
  }
  if (!data.companyPostalCode){
    errors.push({ key: 'companyPostalCode', message: `Postal Code is required` })
  }                        
  if ((data.companyOperationTimeFrom || data.companyOperationTimeTo) && !data.companyOperationTimeZone) {
    errors.push({ key: 'companyOperationTimeZone', message: `Time Zone is required` })
  } else if (data.companyOperationTimeZone) {
    const tzArr = TIMEZONES.map(item=>item.timezone)
    if (!tzArr.includes(data.companyOperationTimeZone)) {
      errors.push({ key: 'companyOperationTimeZone', message: `Time Zone [${data.companyOperationTimeZone}] is not recognized` })
    }
  }
  if (errors.length) {
    throw { errors }
  }
}


const state = {}

const getters = {}

const actions = {

  /**
   * save partner business entity
   * @param {Object} context 
   * @param {String} param.name
   * @param {String} param.taxIdNumber
   * @param {String} [param.websiteAddress]
   * @param {String} param.primaryContactName
   * @param {String} param.primaryContactPhone
   * @param {String} param.primaryContactEmail
   * @param {String} param.companyAddress1
   * @param {String} [param.companyAddress2]
   * @param {String} param.companyCity
   * @param {String} param.companyStateOrProvince
   * @param {String} param.companyPostalCode
   * @param {String} [param.companyCountry]
   * @param {String} [param.companyOperationDays]
   * @param {String} [param.companyOperationTimeFrom]
   * @param {String} [param.companyOperationTimeTo]
   * @param {String} [param.companyOperationTimeZone] - required when companyOperationTimeFrom or companyOperationTimeTo is provided
   * @returns 
   */
  async [SAVE_PARTNER_BUSINESS_ENTITY](context, { 
    name,
    taxIdNumber,
    websiteAddress=null,
    primaryContactName,
    primaryContactPhone,
    primaryContactEmail,
    companyAddress1,
    companyAddress2=null,
    companyCity,
    companyStateOrProvince,
    companyPostalCode,
    //companyCountry=null',
    companyOperationDays=null, // comma list. ie. Monday,Tuesday,Wednesday
    companyOperationTimeFrom=null, // ie. 0800 - convert to UTC time use their time zone specified
    companyOperationTimeTo=null, // ie. 1900 - convert to UTC time use their time zone specified
    companyOperationTimeZone=null, // ie. US/Central - required when timeFrom/timeTo is present
  }) {
    const businessEntity = replaceEmpty({
      name,
      taxIdNumber,
      websiteAddress,
      primaryContactName,
      primaryContactPhone: primaryContactPhone ? primaryContactPhone.replace(/\D/g,'') : null,
      primaryContactEmail,
      companyAddress1,
      companyAddress2,
      companyCity,
      companyStateOrProvince,
      companyPostalCode,
      companyCountry: 'US',
      companyOperationDays,
      companyOperationTimeFrom,
      companyOperationTimeTo,
      companyOperationTimeZone,
    })

    validateBusinessEntity(businessEntity)
    return await ApiService.put(`partners/${context.getters.currentPartnerId}/business`, { ...businessEntity })
  },

}

const mutations = {}

export default {
  name: 'partnerBusinessEntity',
  state,
  actions,
  mutations,
  getters,
}
