export default {
  path: '/users',
  name: 'Users',
  redirect: '/account/settings',
  component: {
    render(c) {
      return c('router-view')
    },
  },
  children: [
    {
      path: ':id',
      name: 'User',
      component: () => import('@/views/users/User'),
      meta: {
        allow: ['isInternal']
      }
    }
  ]
}