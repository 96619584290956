import store from '../store'
import { get } from 'lodash'

const productsRedirect = (getters) => {
  return getters.isPartner || get(getters.vendorSettings,'isRushProductLookupEnabled')
}


export default {
  path: '/products',
  name: 'Products',
  redirect: productsRedirect(store.getters) ? '/products/lookup' : '/products/catalog',
  component: {
    render(c) {
      return c('router-view')
    },
  },
  children: [
    {
      path: 'process/:sku?',
      name: 'Process Returns',
      component: () => import('@/views/products/process/Layout'),
      meta: {
        label: 'Process Returns',
        allow: [
          ['isPartner'],
          ['isVendor','vendorSettings.isRBRProcessingEnabled']
        ]
      },
    },{
      path: 'process-truckload/:sku?',
      name: 'Process Manifested',
      component: () => import('@/views/products/process/Layout'),
      meta: {
        allow: ['isPartner']
      },
    },{
      path: 'catalog',
      name: 'ProductCatalog',
      component: () => import('@/views/products/ProductList'),
      meta: {
        label: 'Catalog',
        allow: ['isVendor']
      },
    },{
      path: 'rbr',
      name: 'ProductRBR',
      component: () => import('@/views/products/RBRProducts'),
      meta: {
        label: store.getters.isVendor && get(store.getters,'vendorSettings.isRBRProcessingEnabled') ? 'Returns On Hand' : 'RBR On Hand',
        allow: ['isRBRVendor']
      },
    },{
      path: 'dropship',
      name: 'ProductDropShip',
      component: () => import('@/views/products/DropShipProducts'),
      meta: {
        label: 'Active Drop Ship',
        allow: ['isDSVendor']
      },
    },{
      path: 'dropship/create',
      name: 'ProductsDropShipCreate',
      component: () => import('@/views/products/dropship/Create'),
      meta: {
        label: 'Create Drop Ship Products',
        allow: [
          ['isDSVendor','navCreateDropShipProducts']
        ],
      },
      children: [
        {
          path: 'upload',
          name: 'ProductsDropShipUpload',
          component: () => import('@/views/products/dropship/Upload'),
          meta: {
            label: 'Upload',
            allow: [
              ['isDSVendor','navCreateDropShipProducts']
            ],
          },
        }
      ],
    },{
      path: 'upload',
      name: 'ProductUpload',
      component: () => import('@/views/products/ProductUpload'),
      meta: {
        label: 'Upload Products',
        allow: [
          ['isInteral','isVendor']
        ]
      },
    },{
      path: 'exports',
      name: 'ProductExports',
      component: () => import('@/views/products/ExportJobs'),
      meta: {
        label: 'Exports',
        allow: ['isVendor','isPartner']
      },
    },{
      path: 'lookup',
      name: 'Lookup',
      component: () => import('@/views/products/RushProducts'),
      meta: {
        label: 'Lookup',
        allow: ['isPartner',['isVendor','vendorSettings.isRushProductLookupEnabled']]
      },          
    },{
      path: ':vendorSku',
      name: 'ProductDetail',
      component: () => import('@/views/products/ProductDetail'),
      meta: {
        label: 'Edit',
        allow: [
          ['isVendor','isInternal'],
          ['isVendor','allowProductEdit']
        ]
      },
    }
  ],
}