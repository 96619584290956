import moment from 'moment'

/**
 * Formats a date value with moment
 * @param {date} date
 * @param {string} format
 * @param {any} notDateValue
 * @returns
 */
export default function formatMoment (date, format, notDateValue='') {
  if (format === undefined) {
    format = 'MM-DD-YYYY'
  }
  if (date) {
    return moment(date).format(format)
  } else {
    return notDateValue
  }
}