import { get } from 'lodash'
import {
  END_LOADER,
  RESET_LOADERS,
  SET_STATE_PROP,
  START_LOADER,
} from "./actions.type"
import {
  APPLY_STATE_PROP,
  SET_LOADERS,
} from "./mutations.type"

const state = {
  version: null,
  env: null,
  loaders: []
}

const getters = {
  env: state => state.env,
  isAppLoading: state => state.loaders.length ? true : false,
  loadingMessage: state => get(state,'loaders[0].message') || null,
}

const actions = {
  [END_LOADER] (context, key) {
    let loaders = [...context.state.loaders]
    loaders = loaders.filter(item => item.key !== key)
    //setTimeout(() => {
      context.commit(SET_LOADERS, loaders)
    //}, 300)
  },
  [RESET_LOADERS] (context) {
    context.commit(SET_LOADERS, [])
  },

  [SET_STATE_PROP] (context, { prop, val }) {
    context.commit(APPLY_STATE_PROP, { prop, val })
  },
  /**
   * start a loader
   * @param {Object} context 
   * @param {Object|String} params - { key, message=null } or key
   */  
  [START_LOADER] (context, key) {
    let message = null
    if (typeof key === 'object') {
      ({ key, message } = key)
    }
    let loaders = context.state.loaders
    loaders.push({ key, message })
    context.commit(SET_LOADERS, loaders)
  },
}

const mutations = {
  /**
   * @param {Object} state
   * @param {String} param.prop
   * @param {Any} param.val
   */
  [APPLY_STATE_PROP] (state, { prop, val }){
    state[prop] = val
  },
  [SET_LOADERS] (state, loaders) {
    state.loaders = [...loaders]
  }
}

export default {
  name: 'app',
  state,
  actions,
  mutations,
  getters
}
