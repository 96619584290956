
import { SAVE_BREAKPOINT } from './actions.type'
import { SET_BREAKPOINT } from './mutations.type'

const state = {
  breakpoint: null,
}

const getters = {
  breakpoint: state => state.breakpoint,
}

const actions = {
  /**
   * Set the boostrap breakpoint based on window width
   * @param {Object} context 
   * @param {Number} width - window.innerWidth
   */
  [SAVE_BREAKPOINT] (context, width) {
    if (width < 576) {
      context.commit(SET_BREAKPOINT,'xs')
    } else if (width < 768) {
      context.commit(SET_BREAKPOINT,'sm')
    } else if (width < 992) {
      context.commit(SET_BREAKPOINT,'md')
    } else if (width < 1200) {
      context.commit(SET_BREAKPOINT,'lg')
    } else {
      context.commit(SET_BREAKPOINT,'xl')
    }
  }
}

const mutations = {
  [SET_BREAKPOINT](state, breakpoint) {
    state.breakpoint = breakpoint
  },
}

export default {
  name: 'window',
  state,
  actions,
  mutations,
  getters,
}
