<template>
  <CForm @submit.prevent="handleContinue" :class="classes.form" v-if="isInternal || showFacilities">
    <template v-if="isInternal">
      <div>
        <CSelect :options="selectedTypes" :value.sync="selectedType" :class="classes.types" placeholder="select..." />
      </div>
      <template v-if="selectedType">
        <div>
          <CSelect
            :options="selectedOptions"
            :value.sync="selected"
            :class="classes.selected"
            :placeholder="`select ${selectedType.toLowerCase().slice(0, -1)}...`"
            :disabled="!selectedType"
          />
        </div>
      </template>
    </template>
    <div v-if="showFacilities">
      <CSelect
        :options="facilities"
        :value.sync="selectedFacility"
        placeholder="select facility..."
        :class="classes.facilities"
        :disabled="!selected"
      />
    </div>
    <div class="text-right">
      <SubmitButton
        :loading="loading"
        :disabled="disableSubmit"
        type="button"
        :class="classes.submit"
        @click="handleContinue"
      />
    </div>
  </CForm>
</template>

<style scoped>
.management-btn {
  height: 35px;
} /* when loading, the button got really tall */
.management-btn >>> i {
  margin: 0;
} /* icons inside the button made it slightly taller than inputs */
</style>

<script>
import { useActions, useGetters } from 'vuex-composition-helpers'
import { ref, watch, onMounted, computed } from '@vue/composition-api'
import { useRouter } from 'vue2-helpers/vue-router'
import SubmitButton from '@/components/template/ui/SubmitButton'

export default {
  props: {
    inline: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  components: {
    SubmitButton,
  },
  setup(props) {
    const router = useRouter()

    const classes = {
      form: props.inline ? 'd-flex flex-row' : 'd-flex flex-column',
      types: props.inline ? 'mb-0' : 'mb-3',
      selected: props.inline ? 'ml-2 mb-0' : 'ml-0 mb-3',
      facilities: props.inline ? 'ml-2 mb-0' : 'ml-0 mb-3',
      submit: props.inline ? 'ml-2 mb-0 management-btn' : 'ml-0 mb-0',
    }

    const { APPLY_FACILITY, APPLY_PARTNER, APPLY_VENDOR, FETCH_FACILITIES } = useActions([
      'APPLY_FACILITY',
      'APPLY_PARTNER',
      'APPLY_VENDOR',
      'FETCH_FACILITIES',
    ])

    const {
      currentFacilityId,
      currentPartnerId,
      currentVendorId,
      currentType,
      isAdmin,
      isInternal,
      isPartner,
      facilities,
      partners,
      vendors,
    } = useGetters([
      'currentFacilityId',
      'currentPartnerId',
      'currentVendorId',
      'currentType',
      'isAdmin',
      'isInternal',
      'isPartner',
      'facilities',
      'partners',
      'vendors',
    ])

    const disableSubmit = computed(() => {
      if (showFacilities.value) {
        return !selected.value || !selectedFacility.value ? true : false
      } else {
        return !selected.value ? true : false
      }
    })

    const showFacilities = computed(() => {
      return (isInternal.value && selectedType.value === 'Partners') ||
        (!isInternal.value && isPartner.value && isAdmin.value)
        ? true
        : false
    })

    const selectedTypes = ['Partners', 'Vendors']
    let loading = ref(false)
    let selectedType = ref(null)
    let selectedOptions = ref([])
    let selected = ref(0)
    let selectedFacility = ref(0)

    // prepopulate data when component mounts
    onMounted(() => {
      selectedType.value = currentType.value || 'Partners'
      selectedOptions.value = selectedType.value === 'Partners' ? partners.value : vendors.value
      selected.value = currentVendorId.value || currentPartnerId.value || 0
      selectedFacility.value = currentFacilityId.value

      // when changing partners, user must select a facility
      watch(
        () => selected.value,
        (newVal, oldVal) => {
          if (newVal && newVal !== oldVal) {
            selectedFacility.value = 0
            if (selectedType.value === 'Partners' && selected.value) {
              FETCH_FACILITIES({ partnerId: selected.value })
            }
          }
        }
      )
      // when the type changes, we need a different set of selections
      watch(
        () => selectedType.value,
        (newVal, oldVal) => {
          if (newVal && newVal !== oldVal) {
            selectedOptions.value = newVal === 'Partners' ? partners.value : vendors.value
            selected.value = 0
            selectedFacility.value = 0
          }
        }
      )
    })

    // apply the current selection to state
    const handleContinue = async () => {
      loading.value = true
      if (selectedType.value === 'Vendors') {
        await APPLY_VENDOR(selected.value)
      } else {
        await APPLY_PARTNER(selected.value)
        await APPLY_FACILITY(selectedFacility.value)
      }
      router.go()
    }

    return {
      classes,
      disableSubmit,
      facilities,
      handleContinue,
      loading,
      selectedFacility,
      selectedOptions,
      selectedTypes,
      selectedType,
      selected,
      showFacilities,
    }
  },
}
</script>
