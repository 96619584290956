/**
 * when vuex is using persistedstate and you want to expire a piece of state
 * the actual caching is done by vuex persistedstate
 * 
 * import { useVuexCache } from '@/composables/useVuexCache
 * const cache = useVuexCache()
 * 
 * state = {
 *  foo: cache.use(null)
 * }
 * getters = {
 *  foo: state => cache.get(state.foo)
 * }
 * actions = {
 *  getFoo (context) {
 *    if (cache.isValid(context.state.foo)) {
 *      return
 *    }
 *    context.commit('setFoo', 'bar')
 *  }
 * }
 * mutations = {
 *  setFoo (state, value) {
 *    state.foo = cache.set('foo', value)
 *  }
 * }
 */


import { has } from 'lodash'

const cacheTime = 3600 * 1000 // 1 hour in ms
const cacheProperty = 'data'

export function useVuexCache () {

  /**
   * check to see if the cache exists or is expired
   * @param {Object} value - vuex state where cache was set (ie. context.state.conditionTooltip)
   * @returns 
   */
  const isValid = (value) => {
    // not using cache, is expired, or doesn't isn't an expected cached object
    if (
      !has(value,'ts') ||
      value.ts <= new Date().getTime() || 
      value[cacheProperty] === null      
    ) {
      return false
    } 
    return true
  }

  /**
   * get the raw value out of the cache
   * @param {Object} value - vuex state where cache was set (ie. state.conditionTooltip)
   * @returns 
   */
  const get = (value) => {
    return (has(value,cacheProperty)) 
      ? value[cacheProperty] 
      : value
  }

  /**
   * set the object for caching
   * @param {Any} value - value to be cached
   * @param {Integer} validForTime - in ms
   * @returns 
   */
  const set = (value, validForTime=cacheTime) => {
    return {
      ts: new Date().getTime() + validForTime,
      [cacheProperty]: value
    }
  }

  /**
   * set a state property to use cache
   * @param {Any} defaultValue 
   * @returns 
   */
  const use = (defaultValue=null) => {
    return { 
      ts: null, 
      [cacheProperty]: defaultValue 
    } 
  }

  return {
    isValid,
    get,
    set,
    use,
  }
}