<template>
  <span>
    <c-dropdown in-nav>
      <template #toggler>
        <i class="fa fa-user-circle fa-2x" />
      </template>
      <template v-if="isPartner || isVendor">
        <c-dropdown-item disabled>
          <i v-if="isPartner" class="d-block">
            <template v-if="currentFacility">{{ currentFacility.label }}</template>
          </i>
          <i v-else-if="isVendor" class="d-block">
            <template v-if="currentVendor">{{ currentVendor.name }}</template>
          </i>
        </c-dropdown-item>
        <c-dropdown-item v-if="showManagementSelect" @click.prevent="showManagementSelectModal = true">
          <i class="fa fa-arrows-rotate" />&nbsp;
          {{ isVendor ? 'Change Vendor' : 'Change Facility' }}
        </c-dropdown-item>
        <c-dropdown-divider />
      </template>
      <c-dropdown-item :to="{ name: 'Settings' }" v-if="isVendor && !isInternal">
        <i class="fa fa-cog" />&nbsp;Settings
      </c-dropdown-item>
      <c-dropdown-item :to="{ name: 'Logout' }"> <i class="fa fa-lock" />&nbsp;Logout </c-dropdown-item>
    </c-dropdown>
    <Modal
      :show="showManagementSelectModal"
      @close="showManagementSelectModal = false"
      hideFooter
      :title="`${isVendor ? 'Change Vendor' : 'Change Facility'}`"
      class="text-muted"
    >
      <!-- v-if to force remounting so selects preload properly -->
      <ManagementSelect v-if="showManagementSelectModal" />
    </Modal>
  </span>
</template>

<script>
import ManagementSelect from '@/components/template/ManagementSelect'
import Modal from '@/components/template/ui/Modal'
import { get } from 'lodash'

export default {
  name: 'NavbarAccountMenu',
  components: {
    ManagementSelect,
    Modal,
  },
  data() {
    return {
      itemsCount: 42,
      avatarImg: 'img/avatars/no-avatar.jpg',
      showManagementSelectModal: false,
    }
  },
  computed: {
    showManagementSelect() {
      return get(this.$route, 'meta.public', false) !== true && (this.isAdmin || this.isInternal)
    },
  },
}
</script>

<style lang="scss" scoped>
.fa {
  margin-right: 4px;
  margin-top: 4px;
}
</style>
