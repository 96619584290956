import ApiService from '@/services/api.service'
import { get, cloneDeep } from 'lodash'
import { DEFAULT_RUSH_SKU } from '@/shared/const/productsProcessReceive.const'
import {
  CHANGE_STEP,
  CLEAR_PRODUCTS,
  END_LOADER,
  FETCH_PRODUCTS,
  FETCH_RUSH_PRODUCT,
  FETCH_VENDOR_PRODUCT,
  RESET_PRODUCT_PROCESS,
  SAVE_IS_TRUCKLOAD,
  SAVE_LOCATE_RESULTS,
  SAVE_MANIFEST,
  SAVE_RECEIVE_RESULTS,
  SAVE_STEP_RECEIVE,
  SAVE_STEP_VERIFY,
  SAVE_STEP_RESHIPPING,
  SAVE_STEP_CONDITION,
  SAVE_STEP_LOCATE,
  START_LOADER,
  UPDATE_STEP,
} from './actions.type'
import {
  PURGE_PRODUCT_PROCESS,
  SET_IS_TRUCKLOAD,
  SET_LOCATE_RESULTS,
  SET_MANIFEST,
  SET_MANIFEST_SOURCE,
  SET_PRODUCTS,
  SET_PRODUCTS_COUNT,
  SET_PROGRESS,
  SET_RECEIVE_RESULTS,
  SET_RUSH_PRODUCT,
  SET_SKU,
  SET_STEP,
  SET_STEPS,
  SET_VENDOR,
  SET_VENDOR_PRODUCT,
} from './mutations.type'

const DEFAULT_STEPS = [
  {
    slug: 'receive',
    dbDoneKey: 'stepReceiveDone', // the column in the db that represents the done status of the step (Y/N)
    title: 'Receive', // title to display as header of page
    icon: 'fa-truck', // the font awesome icont to represent the step
    complete: false, // if step is done, this will be true
    disabled: false, // if step is not and not the current step, this will be true
    validForm: null, // used during validation
    navBtnText: 'Save & Next',
  },
  {
    slug: 'verify',
    dbDoneKey: 'stepVerifyDone',
    title: 'Verify',
    icon: 'fa-check',
    complete: false,
    disabled: true,
    validForm: null,
    navBtnText: 'Save & Next',
  },
  {
    slug: 'reshipping',
    dbDoneKey: 'stepReshippingDone',
    title: 'Reshipping',
    icon: 'fa-box',
    complete: false,
    disabled: true,
    validForm: null,
    navBtnText: 'Save & Next',
  },
  {
    slug: 'condition',
    dbDoneKey: 'stepConditionDone',
    title: 'Condition',
    icon: 'fa-tag',
    complete: false,
    disabled: true,
    validForm: null,
    navBtnText: 'Save & Next',
  },
  {
    slug: 'locate',
    dbDoneKey: 'stepBuildLocateDone',
    title: 'Locate',
    icon: 'fa-location-dot',
    complete: false,
    disabled: true,
    validForm: null,
    navBtnText: 'Save & Process Another',
  },
]
const MIN_PROGRESS = 0.5
const START_STEP = 'receive'
const NEW_MANIFEST_STEPS = ['receive', 'locate'] // limit to these steps for new manifests

const getDefaultState = () => ({
  isTruckLoad: false,
  locateResults: [],
  manifest: null,
  processProgress: MIN_PROGRESS,
  processSteps: [...DEFAULT_STEPS],
  processSlug: START_STEP,
  products: [],
  productsCount: 0,
  receiveResults: [],
  rushProduct: {},
  rushProductFound: false,
  sku: [], // this gets set from route when fetching product. Would indicate processing an existing SKU(s)
  vendor: {},
  vendorProduct: {},
})

const state = getDefaultState()

const getters = {
  allowCreateProduct: (state, getters) =>
    getters.manifestSource === 'DIRECT_BUY' && getters.defaultCondition === 'Like New',
  currentProcessStep: state => (state.processSteps ? state.processSteps.find(s => s.slug === state.processSlug) : []),
  defaultCondition: state => get(state.manifest, 'defaultProductCondition') || null,
  isTruckLoad: state => state.isTruckLoad,
  manifestSource: state =>
    get(state.manifest, 'manifestSource')
      ? get(state.manifest, 'manifestSource')
      : state.isTruckLoad
      ? 'DIRECT_BUY'
      : 'RBR',
  manifestVendorId: state => get(state.manifest, 'vendorId', null),
  manifestVendorPrefix: state => get(state.manifest, 'vendorPrefixes[0]', 'unknown'),
  processingComplete: state => (state.processSteps.find(item => !item.complete) ? false : true),
  productProcessUrl: state => (state.isTruckLoad ? '/products/process-truckload' : '/products/process'),
  rushProductFound: state => (state.rushProduct.sku ? true : false),
  supplierCodeSelectOptions: (state, getters, rootState, rootGetters) => {
    const isVendor = rootGetters.isVendor
    const currentVendorName = rootGetters.currentVendorName
    // converts the vendor supplier codes to a single option per code
    return rootState.vendors.vendors.reduce((acc, item) => {
      let keep = false
      // if a vendor is logged in, limit to only their codes. They will not be searching across other vendors
      if (isVendor) {
        if (item.name === currentVendorName) {
          keep = true
        }
      } else {
        if (item.partnerTypes.includes('RBR')) {
          keep = true
        }
      }
      if (keep) {
        const codes = [...new Set(item.supplierCodes)]
        codes.forEach(code => {
          acc.push({
            code: code,
            vendorName: item.name,
            vendorId: item.id,
            extendedDescription: isVendor ? code : `${item.name} - ${code}`,
            shippingLabelPhotoRequiredBulkOverstock: item.shippingLabelPhotoRequiredBulkOverstock,
            shippingLabelPhotoRequiredBulkReturns: item.shippingLabelPhotoRequiredBulkReturns,
            shippingLabelPhotoRequiredDaily: item.shippingLabelPhotoRequiredDaily,
            trackingNumberRequiredBulkOverstock: item.trackingNumberRequiredBulkOverstock,
            trackingNumberRequiredBulkReturns: item.trackingNumberRequiredBulkReturns,
            trackingNumberRequiredDaily: item.trackingNumberRequiredDaily,
            trashPhotoRequiredBulkOverstock: item.trashPhotoRequiredBulkOverstock,
            trashPhotoRequiredBulkReturns: item.trashPhotoRequiredBulkReturns,
            trashPhotoRequiredDaily: item.trashPhotoRequiredDaily,
          })
        })
      }
      return acc
    }, [])
  },
}

const actions = {
  /**
   * change which step you are on
   * @param {Object} context
   * @param {Object} slug - the step you are moving to which matches processSteps[i].slug
   */
  [CHANGE_STEP](context, slug) {
    // if the step you are trying to move to is disabled, don't allow it
    const steps = context.state.processSteps
    const step = steps.find(item => item.slug === slug)
    if (step) {
      if (step.disabled) {
        return
      }
    }
    context.commit(SET_STEP, slug)
  },

  /**
   * clear out the products
   * @param {Object} context
   */
  [CLEAR_PRODUCTS](context) {
    context.commit(SET_PRODUCTS, [])
    context.commit(SET_RUSH_PRODUCT, {})
    context.commit(SET_VENDOR_PRODUCT, {})
    context.commit(SET_VENDOR, {})
    context.commit(SET_SKU, [])
  },

  /**
   * find vendor catalog products that match the lookup
   * @param {Object} context
   * @param {Object} param.selectedManifest
   * @param {Object} param.selectedVendor
   * @param {String} param.productSearchText
   */
  async [FETCH_PRODUCTS](context, { selectedManifest, selectedVendor, productSearchText }) {
    const isVendor = context.getters.isVendor
    const currentVendorId = context.getters.currentVendorId
    const { isRBRProcessingEnabled } = context.getters.vendorSettings
    const isTruckLoad = context.state.isTruckLoad
    const storeId = isTruckLoad ? context.getters.currentStoreId : null
    const allowCreateProduct = context.getters.allowCreateProduct
    const defaultCondition = context.getters.defaultCondition

    let totalCount = 0
    let searchText = productSearchText
    let vendorId = null
    let manifestId = isTruckLoad ? get(selectedManifest, 'manifestId') || null : null

    if (!isTruckLoad) {
      if (isVendor) {
        vendorId = isRBRProcessingEnabled ? currentVendorId : null
      } else {
        vendorId = get(selectedVendor, 'vendorId') || get(selectedManifest, 'vendorId')
      }
    }

    // reasons to exit
    if ((!vendorId && !manifestId) || !searchText || (isVendor && !isRBRProcessingEnabled)) {
      await context.dispatch(CLEAR_PRODUCTS)
      return
    }

    const { data } = await ApiService.post(`products/lookup`, {
      filter: searchText,
      vendorId: vendorId,
      manifestId: manifestId,
      storeId: storeId,
      limit: 20, // only first 20 in case a bunch are returned.
    })
    let p = get(data, 'data.products', [])

    // if ending in A or C, search without that ending character. Overstock/Wayfair sometimes have
    // the extra character on the package but not in the vendor catalog record
    const lastChar = searchText.slice(-1)
    if ((p.length === 0 && lastChar.toLowerCase() === 'a') || lastChar.toLowerCase() === 'c') {
      const newSearchText = searchText.slice(0, -1)
      const { data } = await ApiService.post(`products/lookup`, {
        filter: newSearchText,
        vendorId: vendorId,
        manifestId: manifestId,
        storeId: storeId,
        limit: 20, // only first 20 in case a bunch are returned.
      })
      p = get(data, 'data.products', [])
      if (p.length) {
        searchText = newSearchText
      }
    }

    // when checking in a Like New manifest, if there are no products found, search
    // across all vendors for the product
    if (!isVendor) {
      // not availabe for vendors processing their own products
      if (p.length === 0 && isTruckLoad && defaultCondition === 'Like New') {
        const { data } = await ApiService.post(`products/lookup`, {
          filter: searchText,
          pullDataForwardFlag: true,
          limit: 20, // only first 20 in case a bunch are returned
        })
        p = get(data, 'data.products', [])
        totalCount = get(data, 'metaData.totalCount') || 0
      }
    }

    // go through each product and check how close a match it was to the search
    // as well as pull up some of the staing product data to top level
    let products = p.map(item => {
      const searchTextMatch =
        item.vendorSku === searchText ||
        item.mpn === searchText ||
        item.upc === searchText ||
        item.productName === searchText
      const exactMatch = vendorId ? item.vendorId === vendorId && searchTextMatch : false
      const matchText = exactMatch
        ? 'Exact Match'
        : item.vendorSku === searchText
        ? 'Vendor SKU Match'
        : item.mpn === searchText
        ? 'UPC Match'
        : item.upc === searchText
        ? 'MPN Match'
        : item.productName === searchText
        ? 'Product Name Match'
        : ''
      return {
        ...item,
        _exactMatch: exactMatch,
        _matchText: matchText,
        _hasStagingProduct: get(item, 'stagingProduct.sellerProductId') ? true : false,
        checkInNote: get(item, 'stagingProduct.checkInNote'),
        quantityAvailable: allowCreateProduct ? 1 : get(item, 'stagingProduct.quantityAvailable') || 0,
      }
    })
    // if there are exact matches, only return those products. There is no reason to show anything else
    const exactMatchProduct = products.filter(item => item._exactMatch === true)
    if (exactMatchProduct.length) {
      products = exactMatchProduct
    }
    context.commit(SET_PRODUCTS_COUNT, totalCount)
    context.commit(SET_PRODUCTS, [...products])
  },

  /**
   * get the Rush product an any relevant data needed for processing
   * @param {Object} context
   * @param {Integer} param.sku - 7 digit Rush SKU
   * @param {String} param.slug - the slug of the step that was just processed
   */
  async [FETCH_RUSH_PRODUCT](context, { sku, slug = null }) {
    const { isRushProductLookupEnabled } = context.getters.vendorSettings
    const isVendor = context.getters.isVendor

    const isTruckLoad = context.state.isTruckLoad
    const manifest = isTruckLoad ? context.state.manifest : null
    const currentStoreId = context.getters.currentStoreId
    const currentVendorId = context.getters.currentVendorId || null
    const currentPartnerId = context.getters.currentPartnerId || null
    const manifests = context.getters.manifests

    let processSlug = START_STEP
    let processProgress = MIN_PROGRESS
    let processSteps = context.state.processSteps
    let currentStepIdx = 0

    // when no SKU is sent in, assume starting at beginning and set it up
    // truckloads should maintain the last selected manifest
    if (!sku || (isVendor && !isRushProductLookupEnabled)) {
      await context.dispatch(SAVE_MANIFEST, {
        manifest: manifest,
        processSteps: [...DEFAULT_STEPS],
        processSlug,
      })
      context.commit(SET_PROGRESS, processProgress)
      context.commit(SET_RUSH_PRODUCT, {})
      return
    }

    // sku can come is as the proper array sometimes
    let skus = sku
    // split SKUs into an array and get the first one
    if (!Array.isArray(sku)) {
      skus = sku.split(',')
      skus = skus.map(item => ({
        ...DEFAULT_RUSH_SKU,
        sku: item,
      }))
    }
    sku = parseInt(skus[0].sku)

    context.commit(SET_SKU, skus)

    context.dispatch(START_LOADER, 'FETCH_RUSH_PRODUCT')

    // GET THE RUSH PRODUCT
    const rushProducts = await ApiService.query(`/rushProducts`, {
      params: {
        sku,
        storeId: currentStoreId,
        vendorId: currentVendorId,
        partnerId: currentPartnerId,
        limit: 1,
        includeShippingBoxes: true,
        productLite: false,
      },
    })
    let rushProduct =
      get(rushProducts, 'data.data.rushProducts', []).find(item => parseInt(item.sku) === parseInt(sku)) || {}

    // DETERMINE CURRENT STEP AND UPDATE STEPS PROGRESS
    if (rushProduct) {
      if (slug) {
        currentStepIdx = processSteps.findIndex(s => s.slug === slug) + 1 // when processing a step, make sure to go to the next instead of letting it calc based on database
      } else {
        currentStepIdx = processSteps.findIndex(s => rushProduct[s.dbDoneKey] === 'N') // first step that is not done is the current step
      }
      processSteps = processSteps.map((item, idx) => {
        const complete = rushProduct[item.dbDoneKey] === 'Y'
        if (complete) {
          processProgress = processProgress + 1
        }
        return {
          ...item,
          complete,
          disabled: !complete && idx > currentStepIdx, // make sure current step is not disabled
        }
      })
      processSlug =
        currentStepIdx > -1 && currentStepIdx <= processSteps.length - 1 ? processSteps[currentStepIdx].slug : 'locate'
    }

    // GET VENDOR PRODUCT
    await context.dispatch(FETCH_VENDOR_PRODUCT, {
      vendorId: rushProduct.vendorId,
      vendorSku: rushProduct.sellerProductId,
    })

    await context.dispatch(SAVE_MANIFEST, {
      manifest: manifests ? manifests.find(item => item.manifestId === rushProduct.manifestId) : null,
      processSteps,
      processSlug,
    })

    // Commit the rest
    context.commit(SET_PROGRESS, processProgress)
    context.commit(SET_RUSH_PRODUCT, rushProduct)

    context.dispatch(END_LOADER, 'FETCH_RUSH_PRODUCT')

    // return true if Rush product was found, false if not
    return rushProduct.sku ? true : false
  },

  /**
   * get a Vendor product
   * @param {Object} context
   * @param {String} param.vendorId
   * @param {String} param.vendorSku
   * @param {Boolean} [param.exactMatchFlag=true]
   */
  async [FETCH_VENDOR_PRODUCT](context, { vendorId, vendorSku, exactMatchFlag = true }) {
    let vendorProduct = null
    if (vendorId && vendorSku) {
      const { data } = await ApiService.post('/products/lookup', {
        vendorId: vendorId,
        vendorSku: vendorSku,
        exactMatchFlag,
      })
      vendorProduct =
        get(data, 'data.products', []).find(item => item.vendorSku.toLowerCase() === vendorSku.toLowerCase()) || null
    }
    context.commit(SET_VENDOR_PRODUCT, vendorProduct)
  },

  /**
   * reset the state
   * @param {Object} context
   */
  [RESET_PRODUCT_PROCESS](context, statePropsToPersist = []) {
    context.commit(PURGE_PRODUCT_PROCESS, statePropsToPersist)
  },

  /**
   * save whether user is processing a truckload
   * @param {Object} context
   * @param {Boolean} isTruckLoad
   */
  [SAVE_IS_TRUCKLOAD](context, isTruckLoad) {
    context.commit(SET_IS_TRUCKLOAD, isTruckLoad)
    // this happens on mounted of layout, if not a truckload, make sure steps are reset
    if (!isTruckLoad) {
      context.commit(SET_STEPS, [...DEFAULT_STEPS])
    }
  },

  /**
   * save the results from submitting locate
   * @param {Object} context
   * @param {Array} results - array of DEFAULT_RUSH_SKU
   */
  [SAVE_LOCATE_RESULTS](context, results = []) {
    context.commit(SET_LOCATE_RESULTS, results)
  },

  /**
   * save the selected manifest in state
   * @param {Object} context
   * @param {Object} [manifest=null]
   * @param {Array} [processSteps=null] - will get from state if not passed in
   * @param {String} [processSlug=receive] - slug of the current step
   */
  [SAVE_MANIFEST](context, { manifest = null, processSteps = null, processSlug = 'receive' }) {
    if (!processSteps) {
      //processSteps = context.state.processSteps
      processSteps = [...DEFAULT_STEPS]
    }
    // New manifest loads dont go through these steps
    if (context.state.isTruckLoad && manifest && manifest.defaultProductCondition === 'New') {
      const steps = processSteps.filter(item => NEW_MANIFEST_STEPS.includes(item.slug))
      context.commit(SET_STEPS, steps)
      context.commit(SET_MANIFEST_SOURCE, manifest.manifestSource)
    } else {
      context.commit(SET_STEPS, processSteps)
      context.commit(SET_MANIFEST_SOURCE, 'RBR')
    }
    context.commit(SET_STEP, processSlug)
    context.commit(SET_MANIFEST, manifest)
  },

  /**
   * save the results from submitting receive
   * @param {Object} context
   * @param {Array} results - array of DEFAULT_RUSH_SKU
   */
  [SAVE_RECEIVE_RESULTS](context, results = []) {
    context.commit(SET_RECEIVE_RESULTS, results)
  },

  /**
   * save the receive step - this supports both a put and post (edit vs create)
   * @param {Object} context
   * @param {String} param.vendorSupplierCode - vendorSupplierCode or manifestId is required
   * @param {Integer} param.manifestId - vendorSupplierCode or manifestId is required
   * @param {String} param.vendorSku
   * @param {Integer} param.rushSku
   * @param {String} [param.trackingNumber]
   * @param {Boolean} [param.isTrash=false]
   * @param {String} [param.notes]
   * @param {String} [param.customerFirstName]
   * @param {String} [param.customerLastName]
   */
  async [SAVE_STEP_RECEIVE](
    context,
    {
      vendorSupplierCode = null,
      manifestId = null,
      vendorId = null,
      vendorSku,
      rushSku,
      trackingNumber = null,
      isTrash = false,
      notes = null,
      customerFirstName = null,
      customerLastName = null,
    }
  ) {
    const isVendor = context.getters.isVendor
    const { isRBRProcessingEnabled } = context.getters.vendorSettings
    if (isVendor && !isRBRProcessingEnabled) {
      return
    }

    // vendorSupplierCode is for RBR, cannot have manifestId present
    if (vendorSupplierCode) {
      manifestId = null
    }

    // if we have a Rush product, then we are updating an existing product
    const rushProduct = context.state.rushProduct
    const method = rushProduct.sku ? 'put' : 'post'
    const endpoint = method === 'put' ? `products/process/receive/${rushProduct.sku}` : `products/process/receive`
    let params = {}
    if (method === 'put') {
      params = {
        facilityId: context.getters.currentFacilityId,
        vendorSupplierCode,
        trackingNumber,
        isTrash,
        notes,
        customerFirstName,
        customerLastName,
      }
    } else {
      params = {
        facilityId: context.getters.currentFacilityId,
        storeId: context.getters.currentStoreId,
        vendorSupplierCode,
        manifestId,
        vendorId,
        vendorSku,
        rushSku,
        trackingNumber,
        isTrash,
        notes,
        customerFirstName,
        customerLastName,
      }
    }
    return await ApiService[method](endpoint, { ...params })
  },

  /**
   * save the verify step
   * @param {Object} context
   * @param {Array} param.verifications
   * @param {String} param.notes
   * @param {Integer} [param.sku] - only used when completing receive step for Like New Load and VC product has to be created
   * @param {Array} [param.vendorProductImages=[]] - when there are no VC images to verify, user will add them. [{ field, url }]. NULLs are filtered off
   * @returns
   */
  async [SAVE_STEP_VERIFY](context, { verifications, notes, sku = null, vendorProductImages = [] }) {
    const isVendor = context.getters.isVendor
    const { isRBRProcessingEnabled } = context.getters.vendorSettings
    if (isVendor && !isRBRProcessingEnabled) {
      return
    }

    const rushProduct = context.state.rushProduct
    const thisSku = rushProduct.sku || sku
    const params = {
      facilityId: context.getters.currentFacilityId,
      verifications,
      notes,
      vendorProductImages: vendorProductImages.filter(item => item.url),
    }
    return await ApiService.put(`products/process/verify/${thisSku}`, { ...params })
  },

  /**
   * save the reshipping step
   * @param {Object} context
   * @param {Array} param.boxes - { shippingWeight, packageLength, packageWidth, packageHeight }
   * @param {String} param.reusePackaging - Y|N
   * @param {String} param.inOriginalBoxes - Y|N
   * @param {String} param.notes
   * @param {String} param.incorrectBoxDims - Y|N
   * @returns
   */
  async [SAVE_STEP_RESHIPPING](context, { boxes, reusePackaging, inOriginalBoxes, notes, incorrectBoxDims }) {
    const isVendor = context.getters.isVendor
    const { isRBRProcessingEnabled } = context.getters.vendorSettings
    if (isVendor && !isRBRProcessingEnabled) {
      return
    }

    // make sure the box values are floats
    boxes = boxes.reduce((acc, box) => {
      let newBox = { ...box }
      Object.keys(box).forEach(key => {
        newBox[key] = parseFloat(box[key]) || null
      })
      acc.push(newBox)
      return acc
    }, [])
    const rushProduct = context.state.rushProduct
    const params = {
      facilityId: context.getters.currentFacilityId,
      boxes,
      reusePackaging,
      inOriginalBoxes,
      notes,
      incorrectBoxDims,
    }
    return await ApiService.put(`products/process/reshipping/${rushProduct.sku}`, { ...params })
  },

  /**
   * save the condition step
   * @param {Object} context
   * @param {String} param.conditionName - New | Like New | Damaged | Trash
   * @param {Array} param.damage
   * @param {String} param.missingHardware - Y|N
   * @param {String} param.assemblyInstructions - Y|N
   * @param {String} param.notes
   * @returns
   */
  async [SAVE_STEP_CONDITION](context, { conditionName, damage, missingHardware, assemblyInstructions, notes }) {
    const isVendor = context.getters.isVendor
    const { isRBRProcessingEnabled } = context.getters.vendorSettings
    if (isVendor && !isRBRProcessingEnabled) {
      return
    }

    const rushProduct = context.state.rushProduct
    const params = {
      facilityId: context.getters.currentFacilityId,
      conditionName,
      damage,
      missingHardware,
      assemblyInstructions,
      notes,
    }
    return await ApiService.put(`products/process/condition/${rushProduct.sku}`, { ...params })
  },

  /**
   * save the locate step
   * @param {Object} context
   * @param {Object} param.location - { area, zone, location }
   * @param {String} param.notes
   * @param {Integer} [param.sku]
   * @returns
   */
  async [SAVE_STEP_LOCATE](context, { location, notes, sku = null }) {
    const isVendor = context.getters.isVendor
    const { isRBRProcessingEnabled } = context.getters.vendorSettings
    if (isVendor && !isRBRProcessingEnabled) {
      return
    }

    if (!sku) {
      sku = context.state.rushProduct.sku
    }
    const params = {
      location,
      notes,
      partnerId: context.getters.currentPartnerId || null,
      storeId: context.getters.currentStoreId || null,
      facilityId: context.getters.currentFacilityId,
    }
    return await ApiService.put(`products/process/locate/${sku}`, { ...params })
  },

  /**
   * update the current step's data
   * @param {Object} context
   * @param {Object} step
   */
  [UPDATE_STEP](context, step) {
    const steps = context.state.processSteps.map(item => {
      if (item.slug === step.slug) {
        return { ...step }
      }
      return { ...item }
    })
    context.commit(SET_STEPS, steps)
  },
}

const mutations = {
  /**
   * reset the state back the original
   * @param {Object} state
   */
  [PURGE_PRODUCT_PROCESS](state, statePropsToPersist = []) {
    // eslint-disable-next-line no-unused-vars
    const newState = getDefaultState()
    const newKeys = Object.keys(newState)
    const oldKeys = Object.keys(state)

    // maintain these from state
    let maintainKeys = ['isTruckLoad', 'processSteps', 'manifest']
    if (statePropsToPersist.length) {
      maintainKeys = maintainKeys.concat(statePropsToPersist)
    }
    maintainKeys.forEach(k => (newState[k] = state[k]))

    // remove properties that may not be in the new version of the reset state
    oldKeys.forEach(key => {
      if (!newKeys.includes(key)) {
        delete state[key]
      }
    })
    Object.assign(state, newState)
  },

  /**
   * save the isTruckLoad state
   * @param {Object} state
   * @param {Boolean} isTruckLoad
   */
  [SET_IS_TRUCKLOAD](state, isTruckLoad) {
    state.isTruckLoad = isTruckLoad
  },

  /**
   * save the locate results in state
   * @param {Object} state
   * @param {Array} results
   */
  [SET_LOCATE_RESULTS](state, results) {
    state.locateResults = results
  },

  /**
   * save the selected manifest
   * @param {Object} state
   * @param {Object} manifest
   */
  [SET_MANIFEST](state, manifest) {
    state.manifest = manifest
  },

  /**
   * save the smanifest source
   * @param {Object} state
   * @param {String} source
   */
  [SET_MANIFEST_SOURCE](state, source) {
    state.manifestSource = source
  },

  /**
   * set the vendor catalog products found
   * @param {Object} state
   * @param {Array} products - vendor catalog products
   */
  [SET_PRODUCTS](state, products) {
    state.products = cloneDeep(products)
  },

  /**
   * set the total count of products returned by product lookup
   * @param {Object} state
   * @param {Number} count
   */
  [SET_PRODUCTS_COUNT](state, count) {
    state.productsCount = count
  },

  /**
   * set the progress of the step process
   * @param {Object} state
   * @param {Float} progress
   */
  [SET_PROGRESS](state, progress) {
    state.processProgress = progress
  },

  /**
   * save the recieve results in state
   * @param {Object} state
   * @param {Array} results
   */
  [SET_RECEIVE_RESULTS](state, results) {
    state.receiveResults = results
  },

  /**
   * sets the Rush SKU in state
   * @param {Object} state
   * @param {Integer} sku
   */
  [SET_SKU](state, sku) {
    state.sku = sku
  },

  /**
   * set the Rush product in state
   * @param {Object} state
   * @param {Object} product - the Rush product
   */
  [SET_RUSH_PRODUCT](state, product) {
    state.rushProduct = product
  },

  /**
   * set the current step's slug that is being processed in state
   * @param {Object} state
   * @param {String} slug
   */
  [SET_STEP](state, slug) {
    state.processSlug = slug
  },

  /**
   * set updated steps in state
   * @param {Object} state
   * @param {Array} steps
   */
  [SET_STEPS](state, steps) {
    state.processSteps = steps
  },

  /**
   * set the vendor in state
   * @param {Object} state
   * @param {Object} vendor
   */
  [SET_VENDOR](state, vendor) {
    state.vendor = { ...vendor }
  },

  /**
   * set the Vendor product in state
   * @param {Object} state
   * @param {Object} vendorProduct
   */
  [SET_VENDOR_PRODUCT](state, vendorProduct) {
    state.vendorProduct = { ...vendorProduct }
  },
}

export default {
  name: 'productProcess',
  state,
  actions,
  mutations,
  getters,
}
