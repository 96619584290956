import Vue from "vue"
import Vuex from "vuex"
import { default as createLocalStorage } from 'vuex-persistedstate'
import localforage from 'localforage'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const saveToLocalStoragePaths = ['auth'] // this must be sychronous storage
let saveToIndexedDbPaths = [] // all other state will be saved in async storage indexedDb
let resourceModules = {} // gather up modules

const vuexPersistEmitter = () => {
  return store => {
    store.subscribe(mutation => {
      // the router will use this to continue routing once storage is loaded
      if (mutation.type === 'RESTORE_MUTATION') {
          store._vm.$root.$emit('storageReady');
      }
    })
  }
}

const vuexLocal = new VuexPersistence({
  key: `rrc-state-${process.env.NODE_ENV}`,
  strictMode: false,
  asyncStorage: true,
  storage: localforage,
  reducer: state => {
    let reducedState = {}
    Object.keys(state).forEach(key => {
      if (!saveToLocalStoragePaths.includes(key)) {
        reducedState[key] = state[key]
      }
    })
    return reducedState
  }
})

function loadStores() {
  const context = require.context('@/store', false, /.module.js$/i);
  return context.keys()
    .map(context)         // import module
    .map(m => m.default)  // get `default` export from each resolved module
}

loadStores().forEach(resource => {
  resourceModules[resource.name] = resource;
  // if we are not saving to localStorage, we are saving to indexedDb

  if (resource.name !== 'app') {
    if (!saveToLocalStoragePaths.includes(resource.name)) {
      saveToIndexedDbPaths.push(resource.name)
    }
  }
})

export default new Vuex.Store({
  mutations: {
    RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION
  },
  modules: resourceModules,
  plugins: [
    vuexLocal.plugin,
    vuexPersistEmitter(),
    createLocalStorage({
      key: `rrc-state-${process.env.NODE_ENV}`,
      paths: saveToLocalStoragePaths,
    }),
  ],
})
