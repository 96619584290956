import ApiService from '@/services/api.service'
import { FETCH_PARTNERS, UPDATE_CURRENT_PARTNER } from './actions.type'
import { SET_PARTNERS } from './mutations.type'
import { get } from 'lodash'

const state = {
  partners: [],
}

const getters = {
  partners: state => state.partners,
}

const actions = {

  /**
   * get partners for current user or all partners if internal user
   * @param {Object} context 
   * @param {Boolean} param.isInteral
   * @param {Boolean} param.isPartner
   * @returns 
   */
  async [FETCH_PARTNERS](context, { isInternal=null, isPartner=null }={}) {
    const internalFlag = isInternal || context.getters.isInternal
    const partnerFlag = isPartner || context.getters.isPartner
    let partners = []
    if (internalFlag) {
      const { data } = await ApiService.get('partners')
      partners = get(data,'data.partners',[])
    } else if (partnerFlag) {
      const { data } = await ApiService.get('partners/current')
      partners = [data.data]
    }
    partners = partners.map(item => ({ ...item, label: item.name, value: item.id }))   
    context.commit(SET_PARTNERS, partners)
    return
  },

  /**
   * update the current partner logged in as or selected. this updates the state.partners array
   * @param {Object} context 
   * @param {Object} partnerData - must include id of partner (i.e. { id: '1241525A3215235', name: 'foo' })
   */
  [UPDATE_CURRENT_PARTNER] (context, partnerData) {
    let partners = context.state.partners
    const idx = partners.findIndex(item=>item.id === partnerData.id)
    if (idx >= 0) {
      partners[idx] = { ...partners[idx], ...partnerData }
      context.commit(SET_PARTNERS, [ ...partners])
    }
  }
}

const mutations = {
  [SET_PARTNERS](state, partners) {
    state.partners = partners
  },
}

export default {
  name: 'partners',
  state,
  actions,
  mutations,
  getters,
}
