export default {
  path: '/administrative',
  redirect: '/administrative/accounting',
  name: 'Administrative',
  component: {
    render(c) {
      return c('router-view')
    },
  },
  children: [
    {
      path: 'accounting',
      name: 'Accounting',
      component: () => import('@/views/administrative/Accounting'),
      meta: {
        allow: [
          ['isAdmin','isPartner']
        ]
      }
    },{
      path: 'business-entity',
      name: 'Business Entity',
      component: () => import('@/views/administrative/BusinessEntity'),
      meta: {
        allow: [
          ['isAdmin','isPartner']
        ]
      }
    },{
      path: 'cs',
      name: 'Customer Service',
      component: () => import('@/views/administrative/CustomerService'),
      meta: {
        allow: [
          ['isAdmin','isPartner']
        ]
      }
    },{
      path: 'facilities',
      name: 'Facilities',
      component: () => import('@/views/administrative/Facilities'),
      meta: {
        allow: [
          ['isAdmin','isPartner']
        ]
      }
    },{
      path: 'holiday-schedule',
      name: 'Holiday Schedule',
      component: () => import('@/views/administrative/HolidaySchedule'),
      meta: {
        allow: [
          ['isAdmin','isPartner']
        ]
      }
    },{
      path: 'users',
      name: 'Users',
      component: () => import('@/views/administrative/PartnerUsers'),
      meta: {
        allow: [
          ['isAdmin','isPartner']
        ]
      }
    },
  ],
}