import ApiService from '@/services/api.service'
import { 
  SAVE_PARTNER_ACCOUNTING,
} from './actions.type'
import TIMEZONES from '@/shared/const/timezones.const'
import STATES from '@/shared/const/states.const'
import { validateEmail } from '@/shared/utils'

const validateAccounting = (data) => {
  let errors = []
  if (!data.apName){
    errors.push({ key: 'apName', message: `Name is required` })
  }
  if (!validateEmail(data.apEmail)){
    errors.push({ key: 'apEmail', message: `A valid email is required` })
  } 
  if (!data.apPhone){
    errors.push({ key: 'apPhone', message: `Phone is required` })
  }
  if (!data.apAddress1){
    errors.push({ key: 'apAddress1', message: `Address 1 is required` })
  }
  if (!data.apCity){
    errors.push({ key: 'apCity', message: `City is required` })
  }
  if (!data.apStateOrProvince){
    errors.push({ key: 'apStateOrProvince', message: `State is required` })
  } else {
    const stateArr = STATES.map(item=>item.code)
    if (!stateArr.includes(data.apStateOrProvince)) {
      errors.push({ key: 'apStateOrProvince', message: `State [${data.apStateOrProvince}] is not recognized` })
    }
  }
  if (!data.apPostalCode){
    errors.push({ key: 'apPostalCode', message: `Postal Code is required` })
  }                        
  if ((data.apOperationTimeFrom || data.apOperationTimeTo) && !data.apOperationTimeZone) {
    errors.push({ key: 'apOperationTimeZone', message: `Time Zone is required` })
  } else if (data.apOperationTimeZone) {
    const tzArr = TIMEZONES.map(item=>item.timezone)
    if (!tzArr.includes(data.apOperationTimeZone)) {
      errors.push({ key: 'apOperationTimeZone', message: `Time Zone [${data.apOperationTimeZone}] is not recognized` })
    }
  }
  if (errors.length) {
    throw { errors }
  }
}

const state = {}

const getters = {}

const actions = {

  /**
   * get a specific partner user
   * @param {Object} context 
   * @param {String} param.apName
   * @param {String} param.apEmail
   * @param {String} param.apPhone
   * @param {String} [param.apRemittanceTo]
   * @param {String} param.apAddress1
   * @param {String} [param.apAddress2]
   * @param {String} param.apCity
   * @param {String} param.apStateOrProvince
   * @param {String} param.apPostalCode
   * @param {String} [param.apCountry]
   * @param {String} [param.apOperationDays]
   * @param {String} [param.apOperationTimeFrom]
   * @param {String} [param.apOperationTimeTo]
   * @param {String} [param.apOperationTimeZone] - required when apOperationTimeFrom or apOperationTimeTo is provided
   * @returns 
   */
  async [SAVE_PARTNER_ACCOUNTING](context, { 
    apName,
    apEmail,
    apPhone, // 10 character string
    apRemittanceTo=null,
    apAddress1,
    apAddress2=null,
    apCity,
    apStateOrProvince, // 2 character code
    apPostalCode, // 5 character string to handle leading 0
    //apCountry=null, // 2 character code
    apOperationDays=null, // ie. list of Monday | Tuesday | etc
    apOperationTimeFrom=null, // ie. 0800
    apOperationTimeTo=null, // ie. 1900
    apOperationTimeZone=null // ie. US/Central - required when timeFrom/timeTo is present
  }) {
    const accounting = {
      apName,
      apEmail,
      apPhone: apPhone ? apPhone.replace(/\D/g,'') : null,
      apRemittanceTo,
      apAddress1,
      apAddress2,
      apCity,
      apStateOrProvince,
      apPostalCode,
      apCountry:'US',
      apOperationDays,
      apOperationTimeFrom,
      apOperationTimeTo,
      apOperationTimeZone
    }
    validateAccounting(accounting)
    return await ApiService.put(`partners/${context.getters.currentPartnerId}/accounting`, accounting)
  },

}

const mutations = {}

export default {
  name: 'partnerAccounting',
  state,
  actions,
  mutations,
  getters,
}
