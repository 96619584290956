import ApiService from '@/services/api.service'
import { get } from 'lodash'
import store from '@/store'
import {
  FETCH_PARTNER_ACTIVITY_REPORT,
  FETCH_RBR_ACTIVITY_REPORT,
  FETCH_DS_ACTIVITY_REPORT,
  FETCH_ON_HAND_REPORT,
} from './actions.type'

const state = {}

const getters = {}

const actions = {
  /**
   * Fetch partner activity reports
   * @param {Object} context
   * @param {String} params.type - TOTALS, PROCESSED, DISPOSED, FULFILLMENT, STORAGE
   * @param {String} [params.facilityId]
   * @param {String} [params.dateCreatedStart] - UTC 2021-01-01 00:00:00
   * @param {String} [params.dateCreatedEnd] - UTC 2021-01-01 00:00:00
   * @returns
   */
  [FETCH_PARTNER_ACTIVITY_REPORT] (context, {
    type,
    facilityId=null,
    dateCreatedStart=null,
    dateCreatedEnd=null
  }={}) {
    return ApiService.query('partnerActivity', {
      params: {
        type,
        partnerId: context.getters.currentPartnerId,
        facilityId: (context.getters.isInternal || context.getters.isAdmin) ? facilityId : null,
        dateStart: dateCreatedStart,
        dateEnd: dateCreatedEnd
      },
    })
  },
  /**
   * Fetch activity reports
   * @param {Object} context
   * @param {String} params.type - SOLD, DISPOSED, RETURNED, RECEIVED, TOTALS
   * @param {String} [params.vendorId]
   * @param {String} [params.source] - rushmarket | outlet
   * @param {String} [params.dateCreatedStart] - UTC 2021-01-01 00:00:00
   * @param {String} [params.dateCreatedEnd] - UTC 2021-01-01 00:00:00
   * @returns
   */
  [FETCH_RBR_ACTIVITY_REPORT] (context, {
    type,
    vendorId=null,
    source=null,
    dateCreatedStart=null,
    dateCreatedEnd=null
  }={}) {
    return ApiService.query('crcActivity', {
      params: {
        type,
        vendorId,
        source,
        dateCreatedStart,
        dateCreatedEnd
      },
    })
  },
  /**
   * Fetch dropship activity reports
   * @param {Object} context
   * @param {String} params.type - SOLD, RETURNED, TOTALS
   * @param {String} [params.vendorId]
   * @param {String} [params.source] - rushmarket | outlet
   * @param {String} [params.dateCreatedStart] - UTC 2021-01-01 00:00:00
   * @param {String} [params.dateCreatedEnd] - UTC 2021-01-01 00:00:00
   * @returns
   */
  [FETCH_DS_ACTIVITY_REPORT] (context, {
    type,
    vendorId=null,
    source=null,
    dateCreatedStart=null,
    dateCreatedEnd=null
  }={}) {
    return ApiService.query('dropshipActivity', {
      params: {
        type,
        vendorId,
        source,
        dateCreatedStart,
        dateCreatedEnd
      },
    })
  },
  /**
   * @description Fetch on hand summary for RBR
   * @function FETCH_ON_HAND_REPORT
   * @param {Object} context
   * @param {String} [params.vendorId]
   * @param {String[]} [params.processedBy] - only used when a vendor is processing their own products
   * @returns {Promise}
   */
  [FETCH_ON_HAND_REPORT] (context, {
    vendorId=null,
    processedBy=null
  }={}) {
    let params = { vendorId }
    if (store.getters.isVendor) {
      if (!get(store.getters,'vendorSettings.isRBRProcessingEnabled')) {
        processedBy = ['Rush']
      }
    }
    params.processedBy = Array.isArray(processedBy) && processedBy.length ? processedBy.join(',') : null
    return ApiService.query('onHandReport', { params })
  }
}

const mutations = {

}

export default {
  name: 'reporting',
  state,
  actions,
  mutations,
  getters,
}
