/**
 * Formats a number with decimals and thousands separator
 * @param {any} value
 * @param {number} [decimals=2]
 * @param {string} [thousandsSeparator=,]
 * @returns {string}
 */
export default function formatNumber (value, decimals=2, thousandsSeparator=',') {
  let result = parseFloat(value).toFixed(decimals).toString();
  if(thousandsSeparator) result = result.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator)
  return result
}
