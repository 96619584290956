import ApiService from '@/services/api.service'
import { get } from 'lodash'
import {
  FETCH_ATTRIBUTE_VALUES,
  FETCH_CATEGORY_ATTRIBUTES,
  FETCH_GENERAL_COLORS,
  FETCH_GENERAL_MATERIALS,
  FETCH_RUSH_PRIMARY_CATEGORIES,
  FETCH_RUSH_SECONDARY_CATEGORIES,
} from './actions.type'
import {
  SET_GENERAL_COLORS,
  SET_GENERAL_MATERIALS,
  SET_RUSH_PRIMARY_CATEGORIES,
  SET_RUSH_SECONDARY_CATEGORIES,
} from './mutations.type'
import { useVuexCache } from '@/composables/useVuexCache'

const cache = useVuexCache()

const state = {
  generalColors: cache.use([]), // attribute Color values - when we dont know the category
  generalMaterials: cache.use([]), // attribute Material value - when we dont know the category
  rushPrimaryCategories: cache.use([]),
  rushSecondaryCategories: cache.use([]),
}

const getters = {
  generalColors: state => cache.get(state.generalColors),
  generalMaterials: state => cache.get(state.generalMaterials),
  rushPrimaryCategories: state => cache.get(state.rushPrimaryCategories),
  rushSecondaryCategories: state => cache.get(state.rushSecondaryCategories),
}

const actions = {
  /**
   * get a list of values for a specific attribute
   * @param {Object} context 
   * @param {String} param.attributeName
   * @returns {Array}
   */
  async [FETCH_ATTRIBUTE_VALUES] (context, { attributeName }) {
    const { data } = await ApiService.query(`/categoryAttributes/values`, {
      params: { name: attributeName }
    })
    return get(data,'data.categoryAttributeValues',[]).map(item=>item.value)
  },
  /**
   * get attributes for the category
   * @param {Object} context 
   * @param {String} param.categoryName
   * @returns 
   */
  async [FETCH_CATEGORY_ATTRIBUTES] (context, { categoryName }) {
    const { data } = await ApiService.query(`/categoryAttributes/required`, {
      params: { categoryName }
    })
    return get(data,'data.categoryAttributes',[])
  },
  /**
   * get general color options
   * @param {Object} context
   */
   async [FETCH_GENERAL_COLORS] (context) {
    if (cache.isValid(context.state.generalColors)) {
      return context.getters.generalColors
    }
    const { data } = await ApiService.query(`/categoryAttributes/values`, {
      params: { name: 'Color' }
    })
    const colors = get(data,'data.categoryAttributeValues',[]).map(item=>item.value)
    context.commit(SET_GENERAL_COLORS, colors)
    return colors
  },
  /**
   * get general material options
   * @param {Object} context
   */
   async [FETCH_GENERAL_MATERIALS] (context) {
    if (cache.isValid(context.state.generalMaterials)) {
      return context.getters.generalMaterials
    }
    const { data } = await ApiService.query(`/categoryAttributes/values`, {
      params: { name: 'Material' }
    })
    const materials = get(data,'data.categoryAttributeValues',[]).map(item=>item.value)
    context.commit(SET_GENERAL_MATERIALS, materials)
    return materials
  },
  /**
   * get rush primary categories
   * @param {Object} context
   */
  async [FETCH_RUSH_PRIMARY_CATEGORIES] (context) {
    if (cache.isValid(context.state.rushPrimaryCategories)) {
      return context.getters.rushPrimaryCategories
    }
    const { data } = await ApiService.query(`/rushCategories`, {
      params: {
        parentId: 0,
        limit: 9999,
      }
    })
    const categories = get(data,'data.categories',[])
    context.commit(SET_RUSH_PRIMARY_CATEGORIES, categories)
    return categories
  },
  /**
   * get rush secondary categories
   * @param {Object} context
   */
  async [FETCH_RUSH_SECONDARY_CATEGORIES] (context) {
    if (cache.isValid(context.state.rushSecondaryCategories)) {
      return context.getters.rushSecondaryCategories
    }    
    const { data } = await ApiService.get(`/categories/children`)
    const categories = get(data,'data.children',[]).map(item=>({ ...item, value: item.categoryId, label: `${item.category1} > ${item.category2}` }))
    context.commit(SET_RUSH_SECONDARY_CATEGORIES, categories)
    return categories
  },
}

const mutations = {
  [SET_GENERAL_COLORS] (state, colors) {
    state.generalColors = cache.set(colors)
  },
  [SET_GENERAL_MATERIALS] (state, materials) {
    state.generalMaterials = cache.set(materials)
  },  
  [SET_RUSH_PRIMARY_CATEGORIES] (state, categories) {
    state.rushPrimaryCategories = cache.set(categories)
  },
  [SET_RUSH_SECONDARY_CATEGORIES] (state, categories) {
    state.rushSecondaryCategories = cache.set(categories)
  },
}

export default {
  name: 'categories',
  state,
  actions,
  mutations,
  getters,
}
