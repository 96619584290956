
import { useActions } from 'vuex-composition-helpers'
import { debounce } from 'lodash'

export function useBreakpoint () {

  const {
    SAVE_BREAKPOINT
  } = useActions(['SAVE_BREAKPOINT'])

  const onResize = debounce (() => {
    SAVE_BREAKPOINT(window.innerWidth)
  }, 50)

  return {
    onResize
  }
}
