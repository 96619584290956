import ApiService from '@/services/api.service'
import { get } from 'lodash'
import {
  ADD_PERMISSION_TO_USER,
  FETCH_USER,
  REMOVE_PERMISSION_FROM_USER,
  SAVE_USER,
} from './actions.type'
import {
  SET_USER,
  SET_USER_BEFORE_EDIT,
} from './mutations.type'

const state = {
  user: null,
  userBeforeEdit: null,
}

const getters = {}

const actions = {
  /**
   * add a permission to a user
   * @param {Object} context
   * @param {String} params.vendorId
   * @param {String} params.userId
   * @param {String} params.permissionId
   * @returns
   */
  [ADD_PERMISSION_TO_USER] (context, { vendorId, userId, permissionId }) {
    return ApiService.post(`/vendors/${vendorId}/users/${userId}/permissions/${permissionId}`)
  },
  /**
   * get a user's account data
   * @param {Object} context
   * @param {String} params.vendorId
   * @param {String} params.userId
   * @returns
   */
  async [FETCH_USER] (context, { vendorId, userId }) {
    const { data } = await ApiService.get(`/vendors/${vendorId}/users/${userId}`)
    context.commit(SET_USER, get(data,'data.users[0]',null))
    context.commit(SET_USER_BEFORE_EDIT, get(data,'data.users[0]',null))
  },
  /**
   * remove a permission to a user
   * @param {Object} context
   * @param {String} params.vendorId
   * @param {String} params.userId
   * @param {String} params.permissionId
   * @returns
   */
  [REMOVE_PERMISSION_FROM_USER] (context, { vendorId, userId, permissionId }) {
    return ApiService.post(`/vendors/${vendorId}/users/${userId}/permissions/${permissionId}`)
  },
  /**
   * save changes to a user
   * @param {Object} context
   * @param {String} params.vendorId
   * @param {Object} params.user
   */
  async [SAVE_USER] (context, { vendorId, user }) {
    await ApiService.put(`/vendors/${vendorId}/users/${user.id}`, user)
    // re-fetch the user after save
    context.dispatch(FETCH_USER, { vendorId, userId: user.id })
  }
}

const mutations = {
  [SET_USER] (state, user) {
    state.user = user
  },
  [SET_USER_BEFORE_EDIT] (state, user) {
    state.userBeforeEdit = user
  }
}

export default {
  name: 'users',
  state,
  actions,
  mutations,
  getters,
}
