import ApiService from "@/services/api.service"
import { replaceEmpty } from '@/shared/utils'
import { get } from 'lodash'
import {
  ADD_FACILITY,
  DELETE_FACILITY,
  FETCH_FACILITIES,
  FETCH_FACILITY,
  SAVE_FACILITY
} from "./actions.type"
import {
  SET_FACILITIES    
} from './mutations.type'
  

const validateFacility = (facility) => {
  let errors = []
  if (!facility.name){
    errors.push({ key: 'name', message: `Name is required` })
  }   
  if (!facility.address1){
    errors.push({ key: 'address1', message: `Address 1 is required` })
  }
  if (!facility.city){
    errors.push({ key: 'city', message: `City is required` })
  }
  if (!facility.stateOrProvince){
    errors.push({ key: 'stateOrProvince', message: `State/Province is required` })
  }
  if (!facility.postalCode){
    errors.push({ key: 'postalCode', message: `Postal Code is required` })
  }
  if (!facility.country){
    errors.push({ key: 'country', message: `Country is required` })
  }
  if (errors.length) {
    throw {
      errors
    }
  } 
}

const getFacilitiesEndpointSettings = (context, { partnerId=null, vendorId=null}={}) => {
  const thisPartnerId = partnerId || context.getters.currentPartnerId
  const thisVendorId = vendorId || context.getters.currentVendorId
  let settings = { url: null, id: null, type: 'vendor' } // assumed vendor

  if (thisPartnerId) {
    settings.url = `/partners/${thisPartnerId}/facilities`
    settings.id = thisPartnerId
    settings.type = 'partner'
  } else if (thisVendorId) {
    // TODO: update endpoint to vendors?
    settings.url = `/vendors/${thisVendorId}/facilities`
    settings.id = thisVendorId
  }
  return settings
}


const state = {
  facilities: [],
}

const getters = {
  facilities: state => state.facilities,
}

const actions = {

  /**
   * add a new facility to current partner
   * @param {Object} context 
   * @param {String} param.name
   * @param {String} param.address1
   * @param {String} [param.address2]
   * @param {String} param.city
   * @param {String} param.stateOrProvince
   * @param {String} param.postalCode
   * @param {String} param.country
   * @param {String} [param.dockType]
   * @returns
   */
  async [ADD_FACILITY] (context, { 
    name,
    address1,
    address2=null,
    city,
    stateOrProvince,
    postalCode,
    country,
    dockType=null
  }) {
    const facility = replaceEmpty({
      name,
      address1,
      address2,
      city,
      stateOrProvince,
      postalCode,
      country,
      dockType
    })
    validateFacility(facility)
    return await ApiService.post(`partners/${context.getters.currentPartnerId}/facilities`, facility)
  },

  /**
   * delete a facility from current partner
   * @param {Object} context 
   * @param {String} facilityId 
   * @returns 
   */
  async [DELETE_FACILITY] (context, facilityId) {
    return await ApiService.delete(`partners/${context.getters.currentPartnerId}/facilities/${facilityId}`)
  },

  /**
   * get a list of facilities
   * @param {Object} context 
   * @param {Boolean} [param.isAdmin] - required when fetching from login
   * @param {String} [param.partnerId] - required when fetching from login
   * @param {String} [param.vendorId] - required when fetching from login
   */
  async [FETCH_FACILITIES] (context, { 
    isAdmin=null, 
    partnerId=null,
    vendorId=null,
  }={}) {

    let facilities = []

    const adminFlag = isAdmin || context.getters.isAdmin
    const settings = getFacilitiesEndpointSettings(context, { partnerId, vendorId })

    if (adminFlag) {
      // at initial login, internal users will have to select which they are managing... a vendor or partner. 
      // until that selection, we wont know which endpoint to use
      if (settings.url) {
        const { data } = await ApiService.get(`${settings.url}`)
        facilities = data.data.facilities      
      }
    } else {
      const { data } = await ApiService.get(`${settings.url}/current`)
      const facilityData = get(data,'data')
      if (facilityData) {
        facilities = [facilityData] // turn into array so the getter works
      }
    }

    facilities = facilities.map(item=>({ ...item, label: item.name, value: item.id }))
    context.commit(SET_FACILITIES, facilities)
    return facilities
  },

  /**
   * get a specific facility
   * @param {Object} context 
   * @param {String} [param.facilityId] - admins and internal users will have this as a selection
   */
  async [FETCH_FACILITY] (context, { facilityId=null, parnterId=null }={}) {
    const thisFacilityId = facilityId || context.getters.currentFacilityId
    const settings = getFacilitiesEndpointSettings(context, { facilityId, parnterId })
    return await ApiService.get(`${settings.url}/${thisFacilityId}`)
  },


  /**
     * add a new facility to current partner
     * @param {Object} context 
     * @param {String} param.id
     * @param {String} param.name
     * @param {String} param.address1
     * @param {String} [param.address2]
     * @param {String} param.city
     * @param {String} param.stateOrProvince
     * @param {String} param.postalCode
     * @param {String} param.country
     * @param {String} [param.dockType]
     * @returns
     */
  async [SAVE_FACILITY] (context, { 
    id,
    name,
    address1,
    address2=null,
    city,
    stateOrProvince,
    postalCode,
    country,
    dockType=null
  }) {
    const facility = replaceEmpty({
      name,
      address1,
      address2,
      city,
      stateOrProvince,
      postalCode,
      country,
      dockType
    })
    validateFacility(facility)   
    return await ApiService.put(`partners/${context.getters.currentPartnerId}/facilities/${id}`, facility)
  },
}

const mutations = {
  [SET_FACILITIES] (state, facilities) {
    state.facilities = [...facilities]
  }
}

export default {
  name: 'facilities',
  state,
  actions,
  mutations,
  getters
}
  