import ApiService from '@/services/api.service'
import { 
  FETCH_PARTNER_USER,
  FETCH_PARTNER_USERS,
  SAVE_PARTNER_USER,
  UPDATE_PARTNER_USER,
} from './actions.type'
import { PARTNER_USER_TYPES } from '@/shared/const/partnerUserTypes.const'


const getUsersEndpoint = (context, type) => {
  if (type.toUpperCase() === 'CORPORATE') {
    return `partners/${context.getters.currentPartnerId}/corporateUsers`
  }
  return `partners/${context.getters.currentPartnerId}/facilityUsers`
}

const getUserEndpoint = (context, type, facilityId=null) => {
  if (type.toUpperCase() === 'CORPORATE') {
    return `partners/${context.getters.currentPartnerId}/corporateUsers`
  }
  return `partners/${context.getters.currentPartnerId}/facilities/${facilityId || context.getters.currentFacilityId}/users`
}

const validateUser = (user) => {
  let errors = []
  if (!user.type) {
    errors.push({ key: 'type', message: 'User Type is required' })
  }
  if (!user.firstName) {
    errors.push({ key: 'firstName', message: 'First name is required' })
  }
  if (!user.lastName) {
    errors.push({ key: 'lastName', message: 'Last name is required' })
  }
  if (!user.email) {
    errors.push({ key: 'email', message: 'Email is required' })
  }
  if (!user.role) {
    errors.push({ key: 'role', message: 'Role is required' })
  }
  if (user.type) {
    if (!user.facilityId && user.type.toUpperCase() === 'FACILITY') {
      errors.push({ key: 'facilityId', message: 'Facility is required' })
    }
  }
  if (!user.status) {
    errors.push({ key: 'status', message: 'Status is required' })
  }
  if (errors.length) {
    throw {
      errors
    }
  }  
}

const state = {}

const getters = {}

const actions = {

  /**
   * get a specific partner user
   * @param {Object} context 
   * @param {String} param.userId - ID of the user you are fetching
   * @param {String} param.type - type of user you are fetching
   * @returns 
   */
  async [FETCH_PARTNER_USER](context, { userId, type }) {
    if (!PARTNER_USER_TYPES.includes(type.toUpperCase())) {
      throw new Error(`Partner user type [${type}] is not recognized`)
    }
    return await ApiService.get(`${getUserEndpoint(context, type)}/${userId}`)
  },

  /**
   * get partner users
   * @param {Object} context 
   * @param {String} param.type - type of users you are fetching
   * @returns 
   */
  async [FETCH_PARTNER_USERS](context, { type }) {
    if (!PARTNER_USER_TYPES.includes(type.toUpperCase())) {
      throw new Error(`Partner user type [${type}] is not recognized`)
    }    
    return await ApiService.get(`${getUsersEndpoint(context, type)}`)
  },

  /**
   * add a new partner user
   * @param {Object} context 
   * @param {String} param.user.firstName
   * @param {String} param.user.lastName
   * @param {String} param.user.email
   * @param {String} param.user.type
   * @param {String} param.user.role
   * @param {String} [param.user.facilityId]
   * @returns 
   */
  async [SAVE_PARTNER_USER] (context, {
    firstName,
    lastName,
    email,
    type,
    role,
    facilityId=null,
  }) {
    const userPayload = { firstName, lastName, email, type, role, facilityId, status: 'ACTIVE' }
    validateUser(userPayload)
    return await ApiService.post(`${getUserEndpoint(context, type, facilityId)}`, { user: userPayload, permissions: [] })
  },

  /**
   * update an existing partner user
   * @param {Object} context 
   * @param {String} param.user.firstName
   * @param {String} param.user.lastName
   * @param {String} param.user.email
   * @param {String} param.user.role
   * @param {String} param.user.facilityId
   * @param {String} param.user.status
   * @param {Array} param.permissions - array of permissionIds
   * @returns 
   */
  async [UPDATE_PARTNER_USER] (context, {
    user,
    permissions=[]
  }={}) {
    const userPayload = { ...user }
    validateUser(userPayload)
    return await ApiService.put(`${getUserEndpoint(context, user.type)}/${user.id}`, {
      user: userPayload,
      permissions: permissions.reduce((acc,item) => {
        if (item.checked) {
          acc.push(item.id)
        }
        return acc
      },[])
    })
  }
}

const mutations = {}

export default {
  name: 'partnerUsers',
  state,
  actions,
  mutations,
  getters,
}
