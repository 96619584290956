import { 
  ADD_LTL_RETURN,
  FETCH_LTL_RETURNS,
  FETCH_NEAREST_RRC,
  SAVE_LTL_RETURN,
} from "./actions.type"
import ApiService from '@/services/api.service'
import moment from "moment"

// needs at least 1 prop or else the vue inspector errors in development
const state = {
  nearest: null
}

const getters = {}

const actions = {

  /**
   * create a new LTL Return 
   * @param {Object} context 
   * @param {String} param.rma
   * @param {String} param.customerFirstName
   * @param {String} param.customerLastName
   * @param {String} param.customerEmail
   * @param {String} param.customerAddressType - Residential or Commercial
   * @param {String} param.customerAddress1
   * @param {String} [param.customerAddress2]
   * @param {String} param.customerCity
   * @param {String} param.customerState - 2 character state code
   * @param {String} param.customerZip - 5 digit zip
   * @param {String} param.customerPhone - 10 digit phone number
   * @param {String} [param.customerPhoneExt]
   * @param {String} [param.commercialHoursOfOperation]
   * @param {String} [param.commercialPhone]
   * @param {String} [param.commercialPhoneExt]
   * @param {Array} param.ltlReturnItems
   * @returns 
   */
  async [ADD_LTL_RETURN] (context, {
    rma,
    customerFirstName,
    customerLastName, 
    customerEmail,
    customerAddressType,
    customerAddress1,
    customerAddress2=null,
    customerCity,
    customerState,
    customerZip,
    customerPhone,
    customerPhoneExt=null,
    commercialHoursOfOperation=null,
    commercialPhone=null,
    commercialPhoneExt=null,    
    commercialEmail=null,
    ltlReturnItems,

  }) {
    if (!customerFirstName || !customerLastName || ! customerEmail || !customerAddressType || !customerAddress1 || !customerCity || !customerState || !customerZip || !customerPhone || !ltlReturnItems.length) {
      throw new Error('Missing required data')
    }
    if (customerAddressType === 'Commercial') {
      if (!commercialHoursOfOperation || !commercialPhone) {
        throw new Error('Hours of operation and phone number are required for commercial addresses')
      }
    }
    const response = await ApiService.post(`/routing/ltl/${context.getters.currentVendorId}`, {
      rma,
      customerFirstName,
      customerLastName, 
      customerEmail,
      customerAddressType,
      customerAddress1,
      customerAddress2,
      customerCity,
      customerState,
      customerZip,
      customerPhone,
      customerPhoneExt,
      commercialHoursOfOperation,
      commercialPhone,
      commercialPhoneExt,
      commercialEmail,
      ltlReturnItems
    })
    return response
  },

  /**
   * get a list of LTL Returns
   * @param {Object} context 
   * @param {String} [param.id]
   * @param {String} [param.rma]
   * @param {String} [param.vendorId] - comma list
   * @param {String} [param.status] - comma list
   * @param {Date} param.dateCreatedStart - YYYY-MM-DD
   * @param {Date} param.dateCreatedEnd - YYY-MM-DD
   * @param {Integer} [param.limit=5000]
   * @param {Integer} [param.offset=0]
   * @returns 
   */
  async [FETCH_LTL_RETURNS] (context, {
    id=null,
    rma=null,
    vendorId=null,
    status=null,
    dateCreatedStart,
    dateCreatedEnd,
    limit=5000, // pagination is done on client at the moment and defaults to last 1 month
    offset=0  
  }) {
    const response = await ApiService.query(`/routing/ltl`, {
      params: {
        id,
        rma,
        vendorId,
        status,
        dateCreatedStart: dateCreatedStart ? moment(`${dateCreatedStart} 00:00:00`).utc().format('YYYY-MM-DD HH:mm:ss') : null,
        dateCreatedEnd: dateCreatedEnd ? moment(`${dateCreatedEnd} 23:59:59`).utc().format('YYYY-MM-DD HH:mm:ss') : null,
        limit,
        offset
      }
    })
    return response
  },

  async [FETCH_NEAREST_RRC] (context, {
    isValidVendorSku,
    vendorId,
    vendorSku,
    zipCode,
  }) {
    const response = await ApiService.query('/routing/nearestRrc', {
      params: {
        isValidVendorSku,
        vendorId,
        vendorSku,
        zipCode
      }
    })

    return response.data.data
  },

  /**
   * update an existing LTL Return
   * @param {Object} context 
   * @param {String} param.id
   * @param {String} param.status - the new status
   * @param {Decimal} [param.estShipCost] - required when status = Estimated
   * @param {Decimal} [param.estRecovery] - required when status = Estimated
   * @param {String} [param.trackingNumber] - required when status = Scheduled
   * @param {Integer} [param.estDaysToPickup] - required when status = Scheduled
   * @param {String} [param.trackingLink]
   * @param {String} [param.vendorId] - used when an Internal user is submitting and update since they can manage all vendors regardless of which logged in as
   * @returns 
   */
  async [SAVE_LTL_RETURN] (context, {
    id,
    status,
    estShipCost=null,
    estRecovery=null,
    trackingNumber=null,
    estDaysToPickup=null,
    trackingLink=null,
    vendorId=null
  }) {
    // external vendor logins should always use the getter
    // internal logins should always use the vendorId passed in
    const thisVendorId = context.getters.isInternal ? vendorId : context.getters.currentVendorId 

    const response = await ApiService.put(`/routing/ltl/${thisVendorId}/${id}`, {
      status,
      estShipCost: estShipCost ? parseFloat(estShipCost) : null,
      estRecovery: estRecovery ? parseFloat(estRecovery) : null,
      trackingNumber,
      estDaysToPickup: estDaysToPickup ? parseInt(estDaysToPickup) : null,
      trackingLink
    })
    return response
  },
}

const mutations = {}

export default {
  name: 'routing',
  state,
  actions,
  mutations,
  getters
}
