<template>
  <c-navbar dark color="dark" class="border-bottom">
    <c-toggler v-if="showAppSideBar" class="border-0 mr-3" in-navbar @click="toggleSidebar">
      <i :class="`fa ${showSidebar ? 'fa-times' : 'fa-bars'}`"></i>
    </c-toggler>
    <c-navbar-brand :to="{ name: 'Dashboard' }">
      <!-- <img class="navbar-brand-minimized" src="/img/logo.png" width="30" height="30" alt="Rush ReCommerce" /> -->
      <img class="navbar-brand-minimized" src="/img/recommerce-logo.png" height="30" alt="Rush ReCommerce" />
    </c-navbar-brand>
    <!-- <c-toggler in-navbar class="d-md-down-none" @click="toggleSidebar" /> -->
    <c-navbar-nav v-if="isAuthenticated" class="ml-auto flex-row align-items-center">
      <c-nav-item v-if="isPartner && currentFacility" disabled class="d-none d-md-block">
        <i class="h5 text-white">{{ currentFacility.label }}</i>
      </c-nav-item>
      <c-nav-item v-if="isVendor && currentVendor" disabled class="d-none d-md-block">
        <i class="h5 text-white">{{ currentVendor.name }}</i>
      </c-nav-item>
      <c-nav-item class="ml-2">
        <NavbarAccountMenu />
      </c-nav-item>
    </c-navbar-nav>
  </c-navbar>
</template>

<script>
import useAppSidebar from '@/composables/useAppSidebar'
import NavbarAccountMenu from '@/components/template/NavbarAccountMenu'
import { computed } from '@vue/composition-api'
import { useRoute } from 'vue2-helpers/vue-router'
import { useGetters } from 'vuex-composition-helpers'
import { get } from 'lodash'

export default {
  name: 'AppHeader',
  components: {
    NavbarAccountMenu,
  },
  setup() {
    const { toggleSidebar, showSidebar } = useAppSidebar()
    const route = useRoute()

    const { isAdmin, isInternal } = useGetters(['isAdmin', 'isInternal'])

    const showManagementSelect = computed(
      () => get(route, 'meta.public', false) !== true && (isAdmin.value || isInternal.value)
    )

    return {
      showManagementSelect,
      toggleSidebar,
      showSidebar,
    }
  },
}
</script>
