export default {
  path: '/account',
  redirect: '/account/login',
  name: 'Account',
  component: {
    render(c) {
      return c('router-view')
    },
  },
  children: [
    {
      path: 'login',
      name: 'Login',
      meta: {
        public: true,
      },
      component: () => import('@/views/auth/Login'),
    },{
      path: 'logout',
      name: 'Logout',
      meta: {
        public: true,
      },
      component: () => import('@/views/auth/Logout'),
    },{
      path: 'resetpassword',
      name: 'ResetPassword',
      meta: {
        public: true,
      },
      component: () => import('@/views/auth/ResetPassword'),
    },{
      path: 'changepassword',
      name: 'ChangePassword',
      meta: {
        hideBreadcrumbs: true,
        hideSideBar: true
      },
      component: () => import('@/views/account/ChangePassword'),
    },{
      path: 'settings',
      name: 'Settings',
      meta: {
        allow: ['isVendor']
      },
      component: () => import('@/views/account/Settings'),
    },
  ],
}