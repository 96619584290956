export default {
  path: '/images',
  name: 'Images',
  redirect: '/images/upload',
  component: {
    render(c) {
      return c('router-view')
    },
  },
  children: [
    {
      path: 'upload',
      name: 'ImagesUpload',
      component: () => import('@/views/images/ImagesUpload'),
      meta: {
        label: 'Upload Images',
        allow: ['isVendor']
      },
    },
  ],
}