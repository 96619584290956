<template>
  <div class="c-app position-relative">
    <AppSidebar v-if="showAppSideBar" />
    <CWrapper>
      <AppHeader />
      <div class="c-body">
        <AppBreadcrumbs v-if="showAppBreadcrumbs" />
        <main class="c-main py-3">
          <CContainer fluid class="main-content-container p-0 p-sm-3">
            <transition name="fade" mode="out-in">
              <router-view :key="routerViewKey" />
            </transition>
          </CContainer>
        </main>
      </div>
      <AppFooter />
    </CWrapper>
    <!-- app loading overlay: use action START_LOADER and END_LOADER to trigger this -->
    <Overlay v-bind="overlayProps" style="z-index:9999;" class="app-loader" />
  </div>
</template>

<style scoped>
.app-loader ::v-deep > div {
  position: fixed !important;
}
.main-content-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.c-main {
  padding-top: 1rem;
}
</style>

<script>
import AppSidebar from '@/components/template/AppSidebar'
import AppHeader from '@/components/template/AppHeader'
import AppBreadcrumbs from '@/components/template/AppBreadcrumbs'
import AppFooter from '@/components/template/AppFooter'
import Overlay from '@/components/template/ui/Overlay'
import { useBreakpoint } from '@/composables/useBreakpoint'
import { onMounted, onBeforeUnmount, nextTick, computed } from '@vue/composition-api'
import { useGetters } from 'vuex-composition-helpers'
import { useRoute } from 'vue2-helpers/vue-router'

export default {
  name: 'App',
  components: {
    AppSidebar,
    AppHeader,
    AppBreadcrumbs,
    AppFooter,
    Overlay,
  },
  setup() {
    const route = useRoute()
    const { onResize } = useBreakpoint()
    const { currentPartnerId, currentVendorId, isAppLoading, loadingMessage } = useGetters([
      'currentPartnerId',
      'currentVendorId',
      'isAppLoading',
      'loadingMessage',
    ])

    onMounted(() => {
      nextTick(() => window.addEventListener('resize', onResize)) // watch for resize to update breakpoint
      onResize() // call on initial load to set breakpoint
    })

    onBeforeUnmount(() => {
      try {
        window.removeEventListener('resize')
      } catch (e) {
        /* do nothing */
      }
    })

    const routerViewKey = computed(
      () => `${route.path}-${currentVendorId.value || null}-${currentPartnerId.value || null}`
    )

    const overlayProps = computed(() => {
      let p = { selector: 'body', loading: isAppLoading.value }
      if (loadingMessage.value) {
        p.text = loadingMessage.value
      }
      return p
    })

    return {
      routerViewKey,
      overlayProps,
    }
  },
}
</script>

<style>
body.no-scroll {
  overflow: hidden;
}
</style>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
