import ApiService from '@/services/api.service'
import store from '@/store'
import { get, keyBy } from 'lodash'
import {
  CREATE_NOT_LISTED_REASON,
  CREATE_VENDOR_PRODUCT,
  DELETE_ASSEMBLY_INSTRUCTIONS,
  DELETE_VENDOR_PRODUCT,
  FETCH_ASSEMBLY_INSTRUCTIONS,
  FETCH_LISTED_ONS,
  FETCH_NOT_LISTED_REASONS,
  GET_VENDOR_PRODUCT,
  GET_VENDOR_PRODUCTS,
  POST_FILES,
  POST_CATALOG_PARSE,
  POST_CATALOG_JOBS,
  PUT_COIN,
  PUT_VENDOR_PRODUCT,
} from './actions.type'
import {
  SET_ASSEMBLY_INSTRUCTIONS,
  SET_LISTED_ONS,
  SET_NOT_LISTED_REASONS,
  SET_VENDOR_PRODUCT,
  SET_VENDOR_PRODUCT_BEFORE_EDIT,
  SET_VENDOR_PRODUCT_PROP,
  SET_VENDOR_PRODUCT_READONLY_FIELDS,
} from './mutations.type'

const state = {
  listedOns: [],
  notListedReasons: [],
  assemblyInstructions: [],
  vendorProduct: null,
  vendorProductBeforeEdit: null,
  vendorProductReadonlyFields: [],
}

const getters = {
  assemblyInstructions: state => state.assemblyInstructions,
  listedOnOptions: state => state.listedOns,
  notListedReasons: state => state.notListedReasons,
  vendorProduct: state => state.vendorProduct,
  vendorProductBeforeEdit: state => state.vendorProductBeforeEdit,
  vendorProductReadonlyFields: state => state.vendorProductReadonlyFields,
}

const actions = {
  async [CREATE_NOT_LISTED_REASON] (context, reason) {
    await ApiService.post(`/marketplaces/notListedReasons`, {
      reason
    })
    context.dispatch(FETCH_NOT_LISTED_REASONS)
  },
  async [CREATE_VENDOR_PRODUCT] (context, { vendorId, product }) {
    const { data } = await ApiService.post(`/vendors/${vendorId}/products`, product)
    return data
  },
  async [DELETE_ASSEMBLY_INSTRUCTIONS] (context, fileId) {
    await ApiService.delete(`/files/${fileId}`)
    let files = context.state.assemblyInstructions.filter(item=>item.fileId !== fileId)
    context.commit(SET_ASSEMBLY_INSTRUCTIONS, files)
  },
  [DELETE_VENDOR_PRODUCT](context, item) {
    let endpoint = 'vendors/' + item.vendorId + '/products/' + item.id
    return ApiService.delete(endpoint)
  },
  async [FETCH_ASSEMBLY_INSTRUCTIONS] (context, { vendorId, vendorSku }) {
    try {
      const { data } = await ApiService.query('/files', {
        params: {
          vendorId,
          vendorSku,
          tag: 'assembly instructions',
        }
      })
      context.commit(SET_ASSEMBLY_INSTRUCTIONS, get(data,'data.files',[]))
    } catch (err) {
      if (get(err,'statusCode') === 404 || get(err,'response.statusCode') === 404) {
        context.commit(SET_ASSEMBLY_INSTRUCTIONS, [])
      } else {
        console.error(err)
        throw(err)
      }
    }
  },
  async [FETCH_LISTED_ONS] (context) {
    if (!context.rootGetters.isInternal) {
      return
    }
    let { data } = await ApiService.get('/marketplaces/listedOns')
    const options = get(data,'data.listedOns',[]).map(item => {
      return {
        value: item.id,
        label: item.platform
      }
    })
    context.commit(SET_LISTED_ONS, options)
  },
  async [FETCH_NOT_LISTED_REASONS] (context) {
    if (!context.rootGetters.isInternal) {
      return
    }
    let { data } = await ApiService.get('/marketplaces/notListedReasons')
    const options = get(data,'data.notListedReasons',[]).map(item => {
      return {
        value: item.id,
        label: item.reason
      }
    })
    context.commit(SET_NOT_LISTED_REASONS, options)
  },
  /**
   *
   * @param {Object} context
   * @param {String} params.vendorId
   * @param {String} params.vendorSku
   * @param {Boolean} [params.vendorSkuExactMatchFlag=true] - set false to do a %LIKE% match
   * @param {String} [params.ltlReturnItemId] - include this if you are getting LTL Return Item data
   * @returns
   */
  async [GET_VENDOR_PRODUCT] (context, { 
    vendorId, 
    vendorSku, 
    vendorSkuExactMatchFlag=true, 
    ltlReturnItemId=null 
  }) {
    const isInternal = store.getters.isInternal
    const listedOnOptions = context.state.listedOns
    const notListedReasons = context.state.notListedReasons
    let product = null
    let readonlyFields = ['vendorSku','coinId']
    if (!isInternal) {
      readonlyFields = readonlyFields.concat([
        'primaryCategory',
        'secondaryCategory',
        'styleTag1',
        'styleTag2',
        'productDescription',
        'primaryColor',
        'colorSpecific',
        'primaryMaterial',
        'secondaryMaterial',
        'materialSpecific',
        'attributeName1',
        'attributeValue1',
        'attributeName2',
        'attributeValue2',
        'attributeName3',
        'attributeValue3',
        'attributeName4',
        'attributeValue4',
        'attributeName5',
        'attributeValue5',
        'attributeName6',
        'attributeValue6',
        'seatingCapacity',
        'shipType',
      ])
    }
    try {
      const { data } = await ApiService.query(`vendors/${vendorId}/products`, { params: { vendorSku, vendorSkuExactMatchFlag, ltlReturnItemId }})
      product = get(data,'data.products[0]',null)
      if (product) {
        product.listedOn = product.listedOn.map(item => {
          const option = listedOnOptions.find(opt=>opt.label === item || opt.label === product.label)
          if (option) return option
        })
        product.notListedReasons = product.notListedReasons.map(item => {
          const option = notListedReasons.find(opt=>opt.label === item || opt.label === product.label)
          if (option) return option
        })
        // inputRadioGroup expects string
        product.listedOnMarketplace = product.listedOnMarketplace.toString()
      }
    } catch (err) {
      if (get(err,'response.statusCode') !== 404) {
        throw(err)
      }
    }
    context.commit(SET_VENDOR_PRODUCT_READONLY_FIELDS, readonlyFields)
    context.commit(SET_VENDOR_PRODUCT_BEFORE_EDIT, product)
    context.commit(SET_VENDOR_PRODUCT, product)
    return product
  },
  async [GET_VENDOR_PRODUCTS](context, {
    vendorId,
    filter=null,
    productName=null,
    vendorSku=null,
    mpn=null,
    upc=null,
    primaryCategory=null,
    secondaryCategory=null,
    sortDesc,
    perPage,
    currentPage,
    sortBy,
    includeDropshipCount=false,
    includeActionLog=false,
    eligibleForDropship=null,
  }={}) {
    let endpoint = `vendors/${vendorId}/products`
    let sortDir = sortDesc ? 'DESC' : 'ASC'
    let params = {
      params: {
        filter,
        productName,
        vendorSku,
        mpn,
        upc,
        primaryCategory,
        secondaryCategory,
        limit: perPage,
        offset: (currentPage - 1) * perPage,
        sortBy: (sortBy && sortBy !== null && sortBy !== '') ? `${sortBy}:${sortDir}` : null,
        eligibleForDropship,
      },
    }
    const {data} = await ApiService.query(endpoint, params)

    let vendorProductsResp = data
    let vendorSkus = []
    // check to see if the product is eligible for limited quantity dropship
    vendorProductsResp.data.products = vendorProductsResp.data.products.map(item => {
      vendorSkus.push(item.vendorSku)
      return {
        ...item,
        _selectable: item.eligibleForDropship ? true : false
      }
    })
    vendorSkus = vendorSkus.join(',')

    // attach a count of how many dropship rush products there are
    if (includeDropshipCount===true) {
      const dropshipProducts = await context.dispatch('FETCH_DROP_SHIP_PRODUCTS', { vendorId, vendorSku: vendorSkus })
      const dsKeyed = keyBy(dropshipProducts, 'sellerProductId')
      vendorProductsResp.data.products = vendorProductsResp.data.products.map(item => {
        const ds = get(dsKeyed,item.vendorSku) || { quantity: 0, percentOffWholesale: 50 }
        return {
          ...item,
          quantity: ds.quantity,
          percentOffWholesale: ds.percentOffWholesale,
          _selectable: ds.quantity > 0 ? false : item._selectable
        }
      })
    }

    // attach pending dropship queue actions
    if (includeActionLog===true) {
      const actionLog = await context.dispatch('FETCH_DROP_SHIP_QUEUE', { vendorId, vendorSku: vendorSkus, status: 'NULL' })
      vendorProductsResp.data.products = vendorProductsResp.data.products.map(item => {
        const log = get(get(actionLog,item.vendorSku),'items') || []
        return {
          ...item,
          actionLog: log,
          _selectable: log.length ? false : item._selectable
        }
      })
    }



    return vendorProductsResp
  },
  async [PUT_COIN] (context, payload) {
    // if we have an access token, send it to the API so that logging can happen
    const accessToken = store.getters.accessToken
    const headers = { headers: {} }
    if (accessToken) {
      headers.headers['X-ACCESS-TOKEN'] = store.getters.accessToken
    }
    let endpoint = `coins/${payload.coinId}`
    return await ApiService.put(endpoint, payload.data, headers)
  },
  async [POST_FILES] (context, {
    originalName=null,
    name,
    url,
    tag,
    storageContext='CATALOG',
    storagePath,
    nameCollision='UNIQUE',
    vendorId,
    vendorSku,
    type
  }) {
    return await ApiService.post('/files', {
      originalName,
      name,
      url,
      tag,
      context: storageContext,
      relativePath: storagePath,
      nameCollision,
      vendorId,
      vendorSku,
      type
    })
  },
  [POST_CATALOG_PARSE](context, payload) {
    return new Promise((resolve, reject) => {
      // validation
      if (!payload.vendorId) {
        reject({ statusCode: 400, message: 'Vendor ID is missing. Upload failed.' })
        return
      }
      if (!payload.storageContext) {
        reject({ statusCode: 400, message: 'Missing storageContext. Please contact support.' })
        return
      }
      if (!payload.formData || payload.formData.catalog === 'null') {
        reject({ statusCode: 400, message: 'Please select a file.' })
        return
      }
      // send request
      let endpoint = 'vendors/' + payload.vendorId + '/catalogParse?context=' + payload.storageContext
      let headers = {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
      ApiService.post(endpoint, payload.formData, headers)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response)
        })
    })
  },
  [POST_CATALOG_JOBS](context, payload = {}) {
    return new Promise((resolve, reject) => {
      // validation
      if (!payload.vendorId) {
        reject({ statusCode: 400, message: 'Vendor ID is missing. Upload failed.' })
        return
      }
      if (!payload.storageContext) {
        reject({ statusCode: 400, message: 'Missing storageContext. Please contact support.' })
        return
      }
      if (!payload.formData || payload.formData.catalog === 'null') {
        reject({ statusCode: 400, message: 'Please select a file.' })
        return
      }
      // send request
      let endpoint = 'vendors/' + payload.vendorId + '/catalogJobs?context=' + payload.storageContext
      let headers = {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
      ApiService.post(endpoint, payload.formData, headers)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response)
        })
    })
  },
  [PUT_VENDOR_PRODUCT](context, payload) {
    const endpoint = 'vendors/' + payload.vendorId + '/products/' + payload.productId
    const params = payload.item
    // if we have an access token, send it to the API so that logging can happen
    const accessToken = store.getters.accessToken
    let headers = { headers: {} }
    if (accessToken) {
      headers.headers['X-ACCESS-TOKEN'] = accessToken
    }
    return ApiService.put(endpoint, params, headers)
  },
}

const mutations = {
  [SET_ASSEMBLY_INSTRUCTIONS] (state, files) {
    state.assemblyInstructions = files
  },
  [SET_LISTED_ONS] (state, options) {
    state.listedOns = options
  },
  [SET_NOT_LISTED_REASONS] (state, options) {
    state.notListedReasons = options
  },
  [SET_VENDOR_PRODUCT] (state, product) {
    state.vendorProduct = product
  },
  [SET_VENDOR_PRODUCT_PROP] (state, {prop,value}) {
    state.vendorProduct[prop] = value
  },
  [SET_VENDOR_PRODUCT_BEFORE_EDIT] (state, product) {
    state.vendorProductBeforeEdit = { ...product }
  },
  [SET_VENDOR_PRODUCT_READONLY_FIELDS] (state, fields) {
    state.vendorProductReadonlyFields = fields
  }
}

export default {
  name: 'products',
  state,
  actions,
  mutations,
  getters,
}
