import ApiService from '@/services/api.service'
import { get } from 'lodash'
import { replaceNull } from '@/shared/utils'
import formatMoment from '@/shared/filters/moment'
import {
  CREATE_EXPORT_PRODUCTS_JOB,
  GET_EXPORT_PRODUCTS_JOBS,
  GET_EXPORT_PRODUCTS_JOBS_FORMATS,
} from "./actions.type"
import {
  SET_EXPORT_PRODUCTS_JOBS,
  SET_EXPORT_PRODUCTS_JOBS_FORMATS,
  SET_EXPORT_PRODUCTS_JOBS_TOTAL,
} from "./mutations.type"

const state = {
  exportProductsJobs: [],
  exportProductsJobsFormats: [],
  exportProductsJobsTotal: 0,
}

const getters = {
  exportProductsJobs: state => state.exportProductsJobs,
  exportProductsJobsFormats: state => state.exportProductsJobsFormats,
  exportProductsJobsTotal: state => state.exportProductsJobsTotal,
}

const actions = {
  /**
   * queue products for export
   * @param {Object} context
   * @param {String} [params.vendorId] - required for internal user
   * @param {String} params.submitterType - USER | VENDOR
   * @param {String} params.submitterId - userId or vendorId depending on submitterType
   * @param {String} params.sendToEmail - where to send to completion email
   * @returns
   */
  [CREATE_EXPORT_PRODUCTS_JOB] (context, {
    vendorId=null,
    submitterType,
    submitterId,
    sendToEmail,
  }) {
    const dateForFilename = formatMoment(new Date(), 'YYYY-MM-DD')
    return ApiService.post(`/products/exportJobs`, {
      context: 'CATALOG',
      format: 'RRC',
      label: `Product Catalog ${dateForFilename}`,
      vendorId,
      submitterType,
      submitterId,
      sendToEmail
    })
  },
  /**
   * get product export jobs so that user can download the complete file or see progress
   * @param {Object} context
   * @param {string} params.submitterType
   * @param {string} params.submitterId
   * @param {Integer} [params.limit] - number of export jobs to get
   * @param {Integer} [params.offset] - what record to start at
   */
  async [GET_EXPORT_PRODUCTS_JOBS] (context, {
    submitterType,
    submitterId,
    limit=10,
    offset=0,
  }) {
    const { data } = await ApiService.query('/products/exportJobs', {
      params: {
        submitterType,
        submitterId,
        limit,
        offset,
      }
    })
    context.commit(SET_EXPORT_PRODUCTS_JOBS_TOTAL, get(data,'metaData.totalCount',0))
    context.commit(SET_EXPORT_PRODUCTS_JOBS, replaceNull(get(data,'data.exportJobs',[])))
    return
  },
  async [GET_EXPORT_PRODUCTS_JOBS_FORMATS] (context) {
    const { data } = ApiService.get('/products/exportFormats')
    context.commit(SET_EXPORT_PRODUCTS_JOBS_FORMATS, get(data,'data.exportJobFormats',[]))
  },
}

const mutations = {
  [SET_EXPORT_PRODUCTS_JOBS] (state, jobs) {
    state.exportProductsJobs = [ ...jobs ]
  },
  [SET_EXPORT_PRODUCTS_JOBS_TOTAL] (state, num) {
    state.exportProductsJobsTotal = num
  },
  [GET_EXPORT_PRODUCTS_JOBS_FORMATS] (state, formats) {
    state.exportProductsJobsFormats = [ ...formats ]
  }
}

export default {
  name: 'productExports',
  state,
  actions,
  mutations,
  getters
}
