import { ref } from '@vue/composition-api'
import sidebarNav from '@/_nav'

const showSidebar = ref(false)
export default function() {
  const toggleSidebar = () => {
    showSidebar.value = !showSidebar.value
  }

  return {
    toggleSidebar,
    showSidebar,
    sidebarNav,
  }
}
