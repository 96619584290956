import ApiService from "@/services/api.service"
import { sortBy } from 'lodash'
import {
  FETCH_FACILITY_USERS
} from "./actions.type"
import {
  SET_FACILITY_USERS
} from './mutations.type'
  

const state = {
  facilityUsers: [],
}

const getters = {
  facilityUsers: state => sortBy(state.facilityUsers, ['firstName']),
}

const actions = {
  /**
   * get a list of facility users (manager and works)
   * @param {Object} context 
   * @param {String} [param.facilityId] 
   */
  async [FETCH_FACILITY_USERS] (context, { 
    facilityId,
    types='MANAGER,WORKER',
    status=null
  }={}) {
    let facilityUsers = []
    const { data } = await ApiService.query(`/partners/${context.getters.currentPartnerId}/facilities/${facilityId}/users`,{
      params: {
        types,
        status // does not support yet
      }
    })
    facilityUsers = data.data.users    
    // once status is supported by API, we can remove this  
    if (status) {
      facilityUsers = facilityUsers.filter(user => status.includes(user.status))
    }
    context.commit(SET_FACILITY_USERS, facilityUsers)
  },
}

const mutations = {
  [SET_FACILITY_USERS] (state, facilityUsers) {
    state.facilityUsers = [...facilityUsers]
  }
}

export default {
  name: 'facilityUsers',
  state,
  actions,
  mutations,
  getters
}
  