import ApiService from '@/services/api.service'
import { 
  SAVE_PARTNER_CUSTOMER_SERVICE,
} from './actions.type'
import TIMEZONES from '@/shared/const/timezones.const'
import { validateEmail } from '@/shared/utils'

const validateCustomerService = (data) => {
  let errors = []
  if (!data.csName){
    errors.push({ key: 'csName', message: `Name is required` })
  }
  if (!validateEmail(data.csEmail)){
    errors.push({ key: 'csEmail', message: `A valid Email is required` })
  }    
  if ((data.csOperationTimeFrom || data.csOperationTimeTo) && !data.csOperationTimeZone) {
    errors.push({ key: 'csOperationTimeZone', message: `Time Zone is required` })
  } else if (data.csOperationTimeZone) {
    const tzArr = TIMEZONES.map(item=>item.timezone)
    if (!tzArr.includes(data.csOperationTimeZone)) {
      errors.push({ key: 'csOperationTimeZone', message: `Time Zone [${data.csOperationTimeZone}] is not recognized` })
    }
  }
  if (errors.length) {
    throw {
      errors
    }
  }
}

const state = {}

const getters = {}

const actions = {

  /**
   * get a specific partner user
   * @param {Object} context 
   * @param {String} param.csName
   * @param {String} param.csEmail
   * @param {String} [param.csPhone]
   * @param {String} [param.csOperationDays]
   * @param {String} [param.csOperationTimeFrom]
   * @param {String} [param.csOperationTimeTo]
   * @param {String} [param.csOperationTimeZone] - required when csOperationTimeFrom or csOperationTimeTo is provided
   * @returns 
   */
  async [SAVE_PARTNER_CUSTOMER_SERVICE](context, { 
    csName,
    csEmail,
    csPhone=null,
    csOperationDays=null,
    csOperationTimeFrom=null,
    csOperationTimeTo=null,
    csOperationTimeZone=null,
  }) {
    const customerService = {
      csName,
      csEmail,
      csPhone: csPhone ? csPhone.replace(/\D/g,'') : null,
      csOperationDays,
      csOperationTimeFrom,
      csOperationTimeTo,
      csOperationTimeZone      
    }
    validateCustomerService(customerService)
    return await ApiService.put(`partners/${context.getters.currentPartnerId}/cs`, customerService)
  },

}

const mutations = {}

export default {
  name: 'partnerCustomerService',
  state,
  actions,
  mutations,
  getters,
}
