/**
 * Takes a camelCase string and converts it to Camel Case
 * @param {string} str
 * @returns
 */
 export default function deCamelCase (str) {
  if (str !== undefined && str !== null) {
    str = str
      .replace(/([A-Z])/g, ' $1') // insert a space before all caps
      .replace(/^./, function(str){ return str.toUpperCase(); }) // uppercase the first character
  }
  return str
}