import ApiService from '@/services/api.service'
import { get } from 'lodash'
import { FETCH_MANIFESTS } from './actions.type'
import { SET_MANIFESTS } from './mutations.type'

const state = {
  manifests: null
}

const getters = {
  manifests: state => state.manifests,
  //newManifests: state => state.manifest.filter(item=>item.defaultProductCondition==='New')
}

const actions = {

  /**
   * fetch manifests associate to current storeID
   * @param {object} context 
   * @returns 
   */
  async [FETCH_MANIFESTS] (context) {
    let m = []
    try {
      const { data } = await ApiService.query('manifests', { 
        params: { 
          storeId: context.getters.currentStoreId
        }
      })
      m = get(data,'data.manifests',[])
    } catch (err) {
      if (get(err,'response.statusCode') !== 404) {
        throw(err)
      }
    }
    context.commit(SET_MANIFESTS, m)
  }

}

const mutations = {
  [SET_MANIFESTS] (state, manifests) {
    state.manifests = manifests
  }
}

export default {
  name: 'manifests',
  state,
  actions,
  mutations,
  getters,
}
