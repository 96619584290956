import rmDebugger from '@/utils/debugger.util'

// Vue 2
export default {
  install: function(Vue) {
    Vue.debugger = rmDebugger
    Vue.prototype.$debugger = rmDebugger
  },
}

// Vue 3
/* export default {
  install: function (app) {
    app.config.globalProperties.$debugger = rmDebugger
  },
} */
