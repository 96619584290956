import ApiService from '@/services/api.service'
import { FETCH_STORES } from './actions.type'
import { SET_STORES } from './mutations.type'

const state = {
  stores: [],
}

const getters = {
  stores (state) { return state.stores }
}

const actions = {
  /**
   * get stores
   * @param {Object} context
   */
  async [FETCH_STORES](context) {
    const { data } = await ApiService.query('stores', {
      params: { limit: 9999, },
    })
    context.commit(SET_STORES, data.data.stores)
  },
}

const mutations = {
  [SET_STORES](state, stores) {
    state.stores = stores
  },
}

export default {
  name: 'stores',
  state,
  actions,
  mutations,
  getters,
}
