// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'core-js/stable'
import Vue from 'vue'
import '@/lib/composition-api'
import CoreuiVue from '@coreui/vue'
import { CIcon } from '@coreui/icons-vue'
import {
  cilTrash, cilFile, cilWarning,
  cilBan, cilX, cilFilterX,
  cilCamera, cilCheckAlt, cilPlus,
  cilChevronCircleRightAlt, cilChevronCircleDownAlt, cilCheckCircle,
  cilMoney, cilCouch, cilCopy,
  cilPencil, cilNotes, cilMinus,
  cilTruck, cilCheck, cilInbox,
  cilTag, cilLocationPin, cilImagePlus
} from '@coreui/icons'
import vSelect from 'vue-select'
import Rollbar from '@/plugins/rollbar'
import Debugger from '@/plugins/debugger'
import '@/shared/filters'
import CxltToastr from 'cxlt-vue2-toastr'
import moment from 'moment'
import App from '@/App'
import router from '@/router'
import store from '@/store'
import ApiService from '@/services/api.service'
import { SET_STATE_PROP } from '@/store/actions.type'
import VueBarcodeScanner from 'vue-barcode-scanner'
import VueMask from 'v-mask' // https://github.com/probil/v-mask

/* GLOBAL MIXIN - only used for app-wide state and utils, otherwise use composables*/
import globalMixin from '@/mixins/global'

Vue.mixin(globalMixin)

Vue.component('v-select', vSelect)
Vue.component('CIcon', CIcon)

Vue.use(VueMask)
Vue.use(moment)
Vue.use(CoreuiVue)
Vue.use(Rollbar)
Vue.use(Debugger)
Vue.use(CxltToastr, {
  closeButton: true,
  position: 'top right', // position: 'top full width',
  showDuration: 300,
  hideDuration: 300,
  timeOut: 12000,
  progressBar: false,
})
Vue.use(VueBarcodeScanner, {
  sensitivity: 30, // default is 100
  callbackAfterTimeout: true // default is false
})

// set the app version in the store
store.dispatch(SET_STATE_PROP, { prop: 'version', val: process.env.VUE_APP_VERSION })
store.dispatch(SET_STATE_PROP, { prop: 'env', val: process.env.NODE_ENV })

ApiService.init()

// import styles
/* font awesome */
import '@fortawesome/fontawesome-free/scss/fontawesome.scss'
import '@fortawesome/fontawesome-free/scss/solid.scss'
import '@fortawesome/fontawesome-free/scss/regular.scss'
import '@fortawesome/fontawesome-free/scss/brands.scss'
/* toastr style */
import '../node_modules/cxlt-vue2-toastr/dist/css/cxlt-vue2-toastr.css'
// Import Main styles for this application
import './assets/scss/style.scss'
// v-select style
import '../node_modules/vue-select/src/scss/vue-select.scss'

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: { App },
  icons: {
    cilTrash, cilFile, cilWarning,
    cilBan, cilX, cilFilterX,
    cilCamera, cilCheckAlt, cilPlus,
    cilChevronCircleRightAlt, cilChevronCircleDownAlt, cilCheckCircle,
    cilMoney, cilCouch, cilCopy,
    cilPencil, cilNotes, cilMinus,
    cilTruck, cilCheck, cilInbox,
    cilTag, cilLocationPin, cilImagePlus
  }
})
