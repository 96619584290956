import { snakeCase, get, clone } from 'lodash'


/**
 * checks for valid email string
 * @param {string} email 
 * @returns 
 */
export function validateEmail (email) {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return pattern.test(String(email).toLowerCase())
}

/**
 * Gives a random number between min/max range
 * @param {integer} min
 * @param {integer} max
 * @returns
 */
export function random (min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

/**
 * Randomize array element order in-place.
 * Using Durstenfeld shuffle algorithm.
 * @param {array} array
 * @returns
 */
export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1))
    let temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}

/**
 * Replaces NULL values with empty strings
 * @param {Object/Array} json
 * @param {Any} replaceWith - optional value to replace with
 * @returns
 */
export const replaceNull = (json, replaceWith='') => {
  return JSON.parse(
    JSON.stringify(json, function (key, value) {
      return value === null ? replaceWith : value
    })
  )
}

/**
 * Replaces empty values with null
 * @param {Object/Array} json
 * @returns
 */
export const replaceEmpty = (json) => {
  return JSON.parse(
    JSON.stringify(json, function (key, value) {
      return value === '' ? null : value
    })
  )
}

/**
 * snake case keys in an object
 * @param {Object} object
 * @returns
 */
export const snakeCaseKeys = (object) => {
  return Object
    .entries(object)
    .reduce((carry, [key, value]) => {
      carry[snakeCase(key)] = value
      return carry
    }, {})
}

/**
 * export data as a CSV file
 * @param {array} data
 * @param {array} fields - [{ key, label }]
 * @param {object} options - { filename: string  }
 */
export const downloadCSVData = (data, fields, {
  filename='export'
}={}) => {

  function processRow (fields, row) {
    let csv = ''
    csv += fields.map(field => {
      let cell = row[field.key]
      switch (typeof cell) {
        case 'number':
          cell = (Math.round(cell*100)/100).toFixed(2)
        break
        case 'string':
          cell = cell.replace(/"/g, '""')
        break
      }
      if (cell === null || cell === 'null') {
        cell = ''
      }
      // if there is a leading zero, we need to make sure we keep it
      //if (cell.toString().charAt(0) === '0') {
      // if the cell is only numbers, make excel treat it as a string
      if (/^\d+$/.test(cell)) { 
        return `="${cell}"`;
      }

      return `"${cell}"`
    }).join(',')
    csv += '\n'
    return csv
  }
  // headers
  let csv = ''
  csv += fields.map(f=>f.label).join(',')
  csv += '\n'
  // data rows
  data.forEach((row) => {
    // rows are groupings of items, we need to loop the items
    if ('items' in row) {
      row.items.forEach((item) => {
        csv += processRow(fields, item)
      })
    } else {
      csv += processRow(fields, row)
    }
  })
  // download to browser
  const anchor = document.createElement('a')
  anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
  anchor.target = '_blank'
  anchor.download = `${filename}.csv`
  anchor.click();
}

/**
 * find the message in a catch error
 * @param {Object|String} err
 * @param {String} defaultMessage
 */
export const getErrorMessage = (err, defaultMessage='Unknown error') => {
  if (typeof err === 'string') {
    return err
  }
  // message from API is generally at response.message, but check under data first
  return get(err,'response.data.message') ||
    get(err,'response.message') ||
    get(err,'message') ||
    defaultMessage
}


/**
 * get the best main image from a vendor product
 * @param {Object} vcProduct
 * @returns
 */
export const getMainImage = (vcProduct) => {
  return vcProduct.mainImageKnockout ||
    vcProduct.mainImageLifestyle ||
    vcProduct.altImage3 ||
    vcProduct.altImage4 ||
    vcProduct.altImage5 ||
    vcProduct.swatchImage6
}

/**
 * removes unwanted properties from an object
 * @param {Object} obj
 * @param {Array} keepProps - Array of object properties to keep
 * @returns
 */
export const filterObjectProperties = (obj, keepProps) => {
  return keepProps.reduce((item,key) => ({ ...item, [key]: obj[key] }), {})
}

/**
 * capitalize first letter in string
 * @param {String} string
 * @returns
 */
export const capFirst = (string) => {
  if (typeof string === 'string') {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string
}

/**
 * changes "cancel_the_product" to "Cancel the product"
 * @param {String} str 
 */
export const snakeToString = (str) => {
  let newString = str.split('_').join(' ')
  return newString.charAt(0).toUpperCase() + newString.slice(1);
}

/**
 * converts "hello_world_test" to "Hello World Test"
 * @param {String} s 
 * @returns 
 */
export const snakeToTitleCase = (s) => {
  return s.replace (/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
   .replace (/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase()) // First char after each -/_
}

/**
 * replace line breaks with comma and then remove spaces
 * @param {String} list 
 * @param {Boolean} [keepEmpty=false]
 * @param {Boolean} [removeDuplicates=true]
 * @returns 
 */
export const cleanList = (list, keepEmpty=false, removeDuplicates=true) => {
  if (list) {
    let cleanedList = clone(list).replace(/(?:\r\n|\r|\n)/g, ',')   // removing this to keep spaces   .replace(/\s+/g, '')
    // get rid of empty values
    if (!keepEmpty) {
      cleanedList = cleanedList.split(/[,]+/).filter(function(v){ return v !== '' }).join(',') // changed [ ,] to [,] so that we only split on comma
    }
   // remove duplicates 
    if (removeDuplicates) {
      cleanedList = cleanedList.split(/[,]+/).filter((item, pos, self) => { return self.indexOf(item) == pos }).join(',') // remove duplicates
    }

    return (cleanedList !== null && cleanedList.length) ? cleanedList : null
  }
  return (list !== null && list.length) ? list : null
}

/**
 * gets the valid package weights from the product
 * @param {Object} product 
 * @returns {Array}
 */
export const getBoxes = (product) => {
  let b = []
  for (let i=1; i<=20; i++) {
    let l = product[`packageLength${i}`]
    let w = product[`packageWidth${i}`]
    let h = product[`packageHeight${i}`]
    let we = product[`shippingWeight${i}`]
    if (l || w || h || we) {
      b.push({
        packageLength: l,
        packageWidth: w,
        packageHeight: h,
        shippingWeight: we
      })
    }
  } 
  return b 
}

/**
 * get the property from an object using dot notation
 * i.e. dotFromObj({a:{b:{c:1}}},'a.b.c') returns 1
 * @param {Object} obj 
 * @param {String} path 
 * @returns {Any}
 */
export const pathFromObj = (obj, path) => {
  if (typeof obj === 'object' && typeof path === 'string') {  
    return path.split('.').reduce((o,i)=>o[i], obj)
  } 
  return null
}
