import store from '@/store'

export default {
  path: '/reporting',
  name: 'Reporting',
  component: {
    render(c) {
      return c('router-view')
    },
  },
  children: [
    {
      path: 'rbr-activity',
      name: 'RBRActivity',
      component: () => import('@/views/reporting/RBRActivity'),
      meta: {
        label: store.getters.isVendor ? 'Rush Activity' : 'RBR Activity',
        allow: [
          ['isRBRVendor','vendorSettings.isRBRActivityReportEnabled']
        ]
      },
    },{
      path: 'dropship-activity',
      name: 'DropShipActivity',
      component: () => import('@/views/reporting/DropShipActivity'),
      meta: {
        label: 'Drop Ship Activity',
        allow: ['isDSVendor']
      },
    },{
      path: 'partner-activity',
      name: 'PartnerActivity',
      component: () => import('@/views/reporting/PartnerActivity'),
      meta: {
        label: store.getters.isVendor ? 'Vendor Activity' : 'Activity',
        allow: [
          ['isVendor','vendorSettings.isActivityReportEnabled'],
          ['isPartner','isAdmin'],
          ['isPartner','isManager'],
        ]
      },
    },{
      path: 'dashboard',
      name: 'ReportingDashboard',
      component: () => import('@/views/reporting/ReportingDashboard'),
      meta: {
        label: 'Dashboard',
      },
    },
  ],
}