import qs from 'query-string'
import cookies from 'js-cookie'

const params = qs.parse(location.search)
//const debug = process.env.NODE_ENV === 'development' || params.debug || cookies.get('rrc_debugger')
const debug = params.debug || cookies.get('rrc_debugger')

export const debugLog = (...args) => {
  if (debug) {
    console.log('%c[DEBUG]', 'background: orange; color: #333;', ...args)
  }
}
export const debugError = (...args) => {
  if (debug) {
    console.error('%c[ERROR]', 'background: red; color: #fff;', ...args)
  }
}

export default {
  log: debugLog,
  error: debugError,
}
