/**
 * capitalizes the first letter in a word (or all words)
 * @param {String} value
 * @param {Boolean} decimals
 * @returns
 */
export default function capFirst (value, all=false) {
  if (!all) {
    return value.charAt(0).toUpperCase() + value.toLowerCase().slice(1)
  }
  return value.toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
}