<template>
  <c-sidebar
    :show="showSidebar"
    dropdown-mode="openActive"
    @update:show="
      $emit('hide')
      toggleSidebar()
    "
  >
    <c-render-function flat :content-to-render="sidebarNav" />
  </c-sidebar>
</template>

<script>
import useAppSidebar from '@/composables/useAppSidebar'
import { has } from 'lodash'
import { pathFromObj } from '@/shared/utils'

export default {
  name: 'AppSidebar',
  setup(props, context) {
    const { showSidebar, sidebarNav, toggleSidebar } = useAppSidebar()

    sidebarNav[0]._children = filterSidebarNav(sidebarNav[0]._children)

    function filterSidebarNav(items) {
      let filteredItems = items.filter(item => {
        if (item.items) {
          item.items = filterSidebarNav(item.items)
        }
        // check to see if there are any rules to show it
        if (has(item, 'showItem')) {
          // if any are true, show the nav item
          const showNavItem = item.showItem.find(prop => {
            // if the prop is an array of props, all must be truePmove
            if (Array.isArray(prop)) {
              const foundFalse = prop.find(p => pathFromObj(context.root.$store.getters, p) === false)
              return !foundFalse
            }
            return pathFromObj(context.root.$store.getters, prop) === true
          })
          if (showNavItem) {
            return true
          }
        } else {
          // item does not have any showItem rules, always show it
          return true
        }
      })
      return filteredItems
    }

    return {
      showSidebar,
      sidebarNav,
      toggleSidebar,
    }
  },
}
</script>
