import ApiService from '@/services/api.service'
import JwtService from '@/services/jwt.service'
import { get, has } from 'lodash'
import { debugLog } from '@/utils/debugger.util'

import {
  APPLY_FACILITY,
  APPLY_PARTNER,
  APPLY_TOKEN,
  APPLY_VENDOR,
  CHANGE_PASSWORD,
  CHECK_AUTH,
  FETCH_FACILITIES,
  FETCH_PARTNERS,
  FETCH_VENDORS,
  LOGIN,
  LOGOUT,
  INITIATE_RESET_PASSWORD,
  RESET_PASSWORD,
} from './actions.type'
import {
  PURGE_AUTH,
  SET_AUTH,
  SET_FACILITY,
  SET_PARTNER,
  SET_TOKEN,
  SET_USER,
  SET_USER_PERMISSIONS,
  SET_VENDOR,
} from './mutations.type'


const state = {
  user: {},
  userPermissions: {},
  facility: {},
  vendor: {},
  partner: {},
  isAuthenticated: !!JwtService.getToken(),
  accessToken: JwtService.getToken(),
  navCreateDropShipProducts: false,
  isReturnRoutingEnabled: false,
  allowProductEdit: false
}

const getters = {
  accessToken: state => state.accessToken,
  allowProductEdit: state => state.allowProductEdit,
  isAdmin: (state, getters) => getters.isInternal ? true : (getters.isPartner) ? getters.userRole === 'ADMIN' : false,
  isManager: (state, getters) => getters.isInternal ? true : (getters.isPartner) ? getters.userRole === 'MANAGER' : false,
  isWorker: (state, getters) => getters.isInternal ? true : (getters.isPartner) ? getters.userRole === 'WORKER' : false,
  isAuthenticated: state => state.isAuthenticated,
  isDSVendor: (state, getters) => get(getters.vendor,'partnerTypes',[]).includes('DS'),
  isRBRVendor: (state, getters) => get(getters.vendor,'partnerTypes',[]).includes('RBR'),
  isVendor: (state, getters) => Object.keys(getters.vendor).length ? true : false,
  isInternal: state => get(state,'user.internal',false),
  isPartner: state => Object.keys(state.partner).length ? true : false,
  isReturnRoutingEnabled: state => state.isReturnRoutingEnabled,
  isLtlReturnsRoutingEnabled: (state, getters) => get(getters,'currentVendor.rrcAllowLtlReturns') === 'Y',
  isLtlReturnsShowEstRecovery: (state, getters) => get(getters,'currentVendor.rrcLtlReturnsShowEstRecovery') === 'Y',
  vendorSettings: (state, getters) => {
    return {
      isRBRProcessingEnabled: get(getters,'currentVendor.rrcAllowProcessingRbr') === 'Y',
      isRushProductLookupEnabled: get(getters,'currentVendor.rrcAllowRushProductLookup') === 'Y',
      isRushProductChangeLocationEnabled: get(getters,'currentVendor.rrcAllowRushChangeLocation') === 'Y',
      isOrderDashBoardEnabled: get(getters,'currentVendor.rrcAllowOrderDashboard') === 'Y',
      isOrderLookupEnabled: get(getters,'currentVendor.rrcAllowOrderLookup') === 'Y',
      isActivityReportEnabled: get(getters,'currentVendor.rrcAllowActivityReport') === 'Y',
      isRBRActivityReportEnabled: get(getters,'currentVendor.rrcAllowRbrActivityReport') === 'Y',
    }
  },
  changePassword: (state, getters) => {
    if (getters.isInteral) {
      return false
    }
    const flag = get(state.user,'passwordResetFlag',1)
    return (flag === 1) ? false : true // 1 indicates the password has been reset
  },
  currentType: (state, getters) => { 
    // what is the user managing
    // internal users can manage Partners and Vendors
    // parterns that are admin can manage facilities
    if (getters.isInternal) {
      return (getters.currentPartnerId) ? 'Partners' : (getters.currentVendorId) ? 'Vendors' : ''
    } else if (getters.isPartner) {
      return 'Partners'
    } else {
      return 'Vendors'
    }
  },
  currentFacility: (state, getters) => {
    return !getters.isAdmin ? get(getters,'facilities[0]') : getters.facilities.find(item=>item.id===get(state.facility,'id'))
  }, 
  currentFacilityId: (state, getters) => {
    return (getters.isAdmin || getters.isInternal) ? get(getters.currentFacility,'id') : 'current'
  },
  currentStoreId: (state, getters) => {
    return get(getters.currentFacility,'storeId') || null
  },
  currentPartner: (state, getters) => {
    return getters.isPartner
      ? (!getters.isAdmin) ? get(getters,'partners[0]') : getters.partners.find(item=>item.id === state.partner.id)
      : null
  },
  currentPartnerId: (state, getters) => {
    return getters.isPartner
      ? getters.isInternal ? get(getters.currentPartner,'id') : 'current'
      : null
  },
  currentUser: state => ({ ...state.user }),
  currentUserPermissions: state => state.userPermissions,
  currentVendor: state => ({ ...state.vendor }),
  currentVendorId: (state, getters) => {
    return (getters.isInternal) ? get(state,'vendor.id','') 
      : (getters.isPartner) ?  '' 
      : (getters.isAuthenticated) ? 'current' : ''
  },
  currentVendorPrefix: state => get(state,'vendor.prefixes[0]',''),
  navCreateDropShipProducts: state => state.navCreateDropShipProducts,
  submitterType: (state, getters) => getters.userType || null,
  userRole: state => get(state,'user.role') || null,
  userType: state => get(state,'user.userType') || null,  
  vendor: state => state.vendor,
}

const actions = {
  async [APPLY_FACILITY] (context, facilityId) {
    if (context.getters.isAdmin) {
      await context.commit(SET_FACILITY, context.getters.facilities.find(f=>f.id===facilityId) || {})
    } else {
      await context.commit(SET_FACILITY, get(context.getters,'facilities[0]') || null)
    }
  },  
  async [APPLY_PARTNER] (context, partnerId) {
    // internal users can manage multiple partners
    // partner logins are all specific to 1 partner
    if (context.getters.isInternal) {
      await context.commit(SET_PARTNER, context.rootState.partners.partners.find(p=>p.id===partnerId) || {})
    } else if (!partnerId) {
      await context.commit(SET_PARTNER, {})
    } else {
      await context.commit(SET_PARTNER, context.rootState.partners.partners[0])
    }
  },
  [APPLY_TOKEN] ({ commit }, token) {
    commit(SET_TOKEN, token)
  },
  [APPLY_VENDOR]({ commit, rootState, dispatch }, vendorId) {
    return new Promise(resolve => {
      const vendor = rootState.vendors.vendors.find(v => v.id === vendorId)
      commit(SET_VENDOR, vendor)
      if (vendor) {
        dispatch(FETCH_FACILITIES, { isAdmin: true, vendorId }).then(facilities => {
          commit(SET_FACILITY, get(facilities,'[0]') || null)
          resolve()
        })
      } else {
        resolve()
      }
    })
  },
  [CHECK_AUTH](context) {
    return new Promise((resolve, reject) => {
      // there is no token or user object, so lets make sure the state is completely purged of the user data
      if (!JwtService.getToken() || !context.state.user) {
        context.commit(PURGE_AUTH)
        reject()
      } else {
        const isInternal = context.getters.isInternal
        const isPartner = context.getters.isPartner
        const isVendor = context.getters.isVendor
        const userRole = context.getters.userRole
        const userType = context.getters.userType
        const endpoint =
          (isInternal) ? 'users/current'
          : (isVendor) ? 'vendors/current/users/current'
          : (isPartner && userRole === 'ADMIN') ? 'partners/current' : 'partners/current/facilities/current/users/current'
        ApiService.get(endpoint)
          .then(({ data }) => {
            // vendor logins will get back { user, vendor }
            let user
            let vendor = {}
            if (!isInternal && isVendor) {
              user = { ...data.data.user }
              user.userName = `${data.data.user.firstName} ${data.data.user.lastName}`
              vendor = { ...data.data.vendor }
            } else if (!isInternal && isPartner && has(data,'data.user')) {
              user = { ...data.data.user }
            } else {
              user = { ...data.data }
            }

            user.changePassword = context.getters.changePassword
            user.userType = userType

            if (isVendor && !isInternal) {
              context.commit(SET_VENDOR, vendor)
            }

            context.commit(SET_AUTH)
            context.commit(SET_USER, user)
            resolve(data)
          })
          .catch(({ response }) => {
            debugLog('CHECK AUTH ERROR -> ', response)
            context.commit(PURGE_AUTH)
            reject(response)
          })
      }
    })
  },
  async [LOGIN](context, credentials) {
    try {
      // login the user and get the token
      const loginResp = await ApiService.post('partnerHub/login', credentials)
      const loginData = loginResp.data.data

      context.commit(SET_TOKEN, loginData.accessToken)

      // token received, now get the current user data
      const isVendor = get(loginData,'vendorFlag',false)
      const isPartner = get(loginData,'partnerFlag',false)
      const isInternal = !isPartner && !isVendor
      const userRole = get(loginData,'role',null)
      const userType = get(loginData,'userType',null)
      const changePassword = get(loginData,'passwordResetFlag',false)
      const isAdmin = isInternal || userRole === 'ADMIN'
      const endpoint =
        (isInternal) ? 'users/current'
        : (isVendor) ? 'vendors/current/users/current'
        : (isPartner && userRole === 'ADMIN') ? 'partners/current' : 'partners/current/facilities/current/users/current'
      // set available users permissions
      if (isPartner) {
        context.commit(SET_USER_PERMISSIONS,{
          'content': {},
          'event': {}
        })
      } else if (isVendor) {
        // very similar structure from what internal users get
        // this is testing for vendor multi user that is shelved for now
        context.commit(SET_USER_PERMISSIONS,
          {
            'content': {},
            'event': {
              // 'products.delete': {
              //   description: 'Delete products',
              //   active: 'Y',
              //   hasPermission: 'Y'
              // },
              // 'products.edit': {
              //   description: 'Edit products',
              //   active: 'Y',
              //   hasPermission: 'Y'
              // },
              // 'users.manage': {
              //   description: 'Manage users',
              //   active: 'Y',
              //   hasPermission: 'Y'
              // },
              // 'users.add': {
              //   description: 'Add new users',
              //   active: 'Y',
              //   hasPermission: 'Y'
              // },
              // 'users.edit': {
              //   description: 'Edit existing users',
              //   active: 'Y',
              //   hasPermission: 'N'
              // },
              // 'users.delete': {
              //   description: 'Delete existing users',
              //   active: 'Y',
              //   hasPermission: 'N'
              // },
            }
          }
        )
      } else {
        context.commit(SET_USER_PERMISSIONS, get(loginData,'userPermissions',null))
      }

      const currentResp = await ApiService.get(endpoint)

      let user
      let vendor = {}
      if (isVendor) {
        user = { ...currentResp.data.data.user }
        user.userName = `${currentResp.data.data.user.firstName} ${currentResp.data.data.user.lastName}`
        vendor = { ...currentResp.data.data.vendor }
      } else if (isPartner && has(currentResp,'data.data.user')) {
        user = { ...currentResp.data.data.user }
      } else {
        user = { ...currentResp.data.data }
      }

      user.changePassword = changePassword
      user.userType = userType

      await context.commit(SET_USER, user)

      // get and set additional data before continuing
      await Promise.all([
        context.dispatch(FETCH_VENDORS, { isInternal }),
        context.dispatch(FETCH_PARTNERS, { isInternal, isPartner }),
        context.dispatch(FETCH_FACILITIES, { 
          isAdmin, 
          partnerId: (isPartner) ? 'current' : '', 
          vendorId: (isVendor) ? 'current' : '',
        })
      ])

      await context.dispatch(APPLY_FACILITY, user.facilityId)
      await context.dispatch(APPLY_PARTNER, isPartner)
      await context.commit(SET_VENDOR, vendor)
      await context.commit(SET_AUTH)

      return user
    } catch (error) {
      debugLog('LOGIN ERROR -> ', error)
      context.commit(PURGE_AUTH)
      return error
    }
  },
  [LOGOUT](context) {
    return new Promise(resolve => {
      const endpoint =
        (context.getters.isInternal)
          ? 'users/current/logout'
          : (context.getters.isPartner)
            ? 'partners/current/logout'
            : 'vendors/current/logout'
      ApiService.post(endpoint, {})
        .then(({ data }) => {
          context.commit(PURGE_AUTH)
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(PURGE_AUTH)
          resolve(response)
        })
    })
  },
  /**
   * initiate a password reset from logged out
   * @param {Object} context 
   * @param {String} param.email
   * @returns 
   */
  [INITIATE_RESET_PASSWORD] (context, { email }) {
    return ApiService.post('partnerHub/resetPassword', {
      email
    })
  },
  /**
   * Set password to complete the password reset from logged out
   * @param {Object} context 
   * @param {String} param.verificationId
   * @returns 
   */
  [RESET_PASSWORD] (context, { password, verificationId }) {
    return ApiService.post('partnerHub/changePassword', {
      password,
      verificationId
    })
  },
  /**
   * when a user wants to change their password while authenticated
   * @param {Object} context
   * @param {String} password
   * @returns
   */
  [CHANGE_PASSWORD] (context, password) {
    return new Promise((resolve, reject) => {

      const endpoint =
      (context.getters.isPartner)
        ? (context.getters.userRole === 'ADMIN')
          ? 'partners/current'
          : 'partners/current/facilities/current/users/current'
        : (context.getters.isVendor) ? 'vendors/current/users/current' : 'users/current'

      let user = { ...context.state.user }
      ApiService.put(endpoint, {
        password,
        passwordResetFlag: 1
      })
        .then(() => {
          // after a successful change, update the state that is has been updated
          user.changePassword = false
          user.passwordResetFlag = 1
          context.commit(SET_USER, user)
          resolve()
        })
        .catch(err=>reject(err))
    })
  }
}

const mutations = {
  [PURGE_AUTH](state) {
    state.isAuthenticated = false
    state.user = {}
    state.userPermissions = {}
    state.vendor = {}
    state.partner = {}
    state.facility = {}
    JwtService.destroyToken()
  },
  [SET_AUTH](state) {
    state.isAuthenticated = true
  },  
  [SET_FACILITY] (state, facility) {
    state.facility = facility
  },
  [SET_PARTNER] (state, partner) {
    if (partner && Object.keys(partner).length) {
      state.vendor = {}
      state.navCreateDropShipProducts = false
      state.isReturnRoutingEnabled = false
      state.allowProductEdit = false
    }
    state.partner = partner
  },
  [SET_TOKEN](state, token) {
    JwtService.saveToken(token)
  },
  [SET_USER](state, user) {
    state.user = user
  },
  [SET_USER_PERMISSIONS](state, permissions) {
    state.userPermissions = permissions
  },
  [SET_VENDOR](state, vendor) {
    if (Object.keys(vendor).length) {
      state.partner = {}
      //state.facility = {}
    }
    state.vendor = vendor
    state.navCreateDropShipProducts =  get(vendor,'rrcNavCreateSellableProducts') === 'Y'
    state.isReturnRoutingEnabled = get(vendor, 'rrcAllowReturnRouting') === 'Y'
    state.allowProductEdit = get(vendor, 'rrcProductEdit') === 'Y'
  },
}

export default {
  name: 'auth',
  state,
  actions,
  mutations,
  getters,
}
