import Vue from 'vue'
import currency from './filters/currency'
import formatMoment from './filters/moment'
import sumCurrency from './filters/sumCurrency'
import sumNumber from './filters/sumNumber'
import yesNoFormat from './filters/yesNoFormat'
import deCamelCase from './filters/deCamelCase'
import capFirst from './filters/capFirst'
import phone from './filters/phone'
import formatNumber from './filters/formatNumber'


// usage in template: $filters.sumCurrency(item.items, 'monthlyStorageFee')

const plug = {
	install (Vue) {
    Vue.prototype.$filters = {
      currency,
      moment: formatMoment,
      sumNumber,
      sumCurrency,
      yesNoFormat,
      capFirst,
      phone,
      formatNumber
    }
	}
}

Vue.use(plug)

/* allow use in template piping {{ myNumber | currency }} */
Vue.filter('yesNoFormat', yesNoFormat)
Vue.filter('currency', currency)
Vue.filter('deCamelCase', deCamelCase)
Vue.filter('capFirst', capFirst)
Vue.filter('phone', phone)

// Vue 3
/* export default {
  install: function (app) {
    app.config.globalProperties.$filters = {
      currency,
      moment: formatMoment
    }
  },
} */