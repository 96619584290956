import ApiService from '@/services/api.service'
import { POST_INVENTORY_JOBS } from './actions.type'

const state = {}

const getters = {}

const actions = {
  [POST_INVENTORY_JOBS](context, payload) {
    return new Promise((resolve, reject) => {
      // validation
      if (!payload.vendorId) {
        reject({ statusCode: 400, message: 'Vendor ID is missing. Upload failed.' })
        return
      }
      if (!payload.formData || payload.formData.inventory === 'null') {
        reject({ statusCode: 400, message: 'Please select a file.' })
        return
      }
      // send request
      let endpoint = 'vendors/' + payload.vendorId + '/inventoryJobs'
      let headers = {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
      ApiService.post(endpoint, payload.formData, headers)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response)
        })
    })
  },
}

const mutations = {}

export default {
  name: 'inventory',
  state,
  actions,
  mutations,
  getters,
}
