export default {
  path: '/routing',
  name: 'Routing',
  component: {
    render(c) {
      return c('router-view')
    },
  },
  children: [
    {
      path: 'route-my-return',
      name: 'RouteMyReturn',
      component: () => import('@/views/routing/RouteMyReturn'),
      meta: {
        label: 'Small Parcel Routing',
        allow: [
          ['isInternal','isVendor'],
          ['isReturnRoutingEnabled']
        ]
      },
    },{
      path: 'ltl',
      name: 'LTLReturns',
      component: () => import('@/views/routing/LTLReturns'),
      meta: {
        label: 'LTL Returns',
        allow: [
          ['isInternal','isVendor'],
          ['isLtlReturnsRoutingEnabled']
        ]
      }
    }
  ],
}