export const APPLY_STATE_PROP = 'APPLY_STATE_PROP'
export const PURGE_AUTH = 'PURGE_AUTH'
export const PURGE_PRODUCT_PROCESS = 'PURGE_PRODUCT_PROCESS'
export const RESET_STATE = 'RESET_STATE'
export const SET_ASSEMBLY_INSTRUCTIONS = 'SET_ASSEMBLY_INSTRUCTIONS'
export const SET_AUTH = 'SET_AUTH'
export const SET_BREAKPOINT = 'SET_BREAKPOINT'
export const SET_DISPOSAL_FEES = 'SET_DISPOSAL_FEES'
export const SET_DROP_SHIP_QUEUE = 'SET_DROP_SHIP_QUEUE'
export const SET_EDIT_DROP_SHIP_PRODUCTS = 'SET_EDIT_DROP_SHIP_PRODUCTS'
export const SET_EDIT_DROP_SHIP_TOTAL_ROWS = 'SET_EDIT_DROP_SHIP_TOTAL_ROWS'
export const SET_EDIT_ORIGINAL_DROP_SHIP_PRODUCTS = 'SET_EDIT_ORIGINAL_DROP_SHIP_PRODUCTS'
export const SET_ERROR = 'SET_ERROR'
export const SET_EXPORT_PRODUCTS_JOBS = 'SET_EXPORT_PRODUCTS_JOBS'
export const SET_EXPORT_PRODUCTS_JOBS_FORMATS = 'SET_EXPORT_PRODUCTS_JOBS_FORMATS'
export const SET_EXPORT_PRODUCTS_JOBS_TOTAL = 'SET_EXPORT_PRODUCTS_JOBS_TOTAL'
export const SET_FACILITY = 'SET_FACILITY'
export const SET_FACILITIES = 'SET_FACILITIES'
export const SET_FACILITY_USERS = 'SET_FACILITY_USERS'
export const SET_GENERAL_COLORS = 'SET_GENERAL_COLORS'
export const SET_GENERAL_MATERIALS = 'SET_GENERAL_MATERIALS'
export const SET_INPUT_ERRORS = 'SET_INPUT_ERRORS'
export const SET_IS_TRUCKLOAD = 'SET_IS_TRUCKLOAD'
export const SET_LISTED_ONS= 'SET_LISTED_ONS'
export const SET_LOADERS = 'SET_LOADERS'
export const SET_LOCATE_RESULTS = 'SET_LOCATE_RESULTS'
export const SET_NOT_LISTED_REASONS = 'SET_NOT_LISTED_REASONS'
export const SET_MANIFEST = 'SET_MANIFEST'
export const SET_MANIFEST_SOURCE = 'SET_MANIFEST_SOURCE'
export const SET_MANIFESTS = 'SET_MANIFESTS'
export const SET_MASTER_DATA_STATE = 'SET_MASTER_DATA_STATE'
export const SET_PARTNER = 'SET_PARTNER'
export const SET_PARTNERS = 'SET_PARTNERS'
export const SET_PRINTERS = 'SET_PRINTERS'
export const SET_PRINTER = 'SET_PRINTER'
export const SET_PRODUCTS = 'SET_PRODUCTS'
export const SET_PRODUCTS_COUNT = 'SET_PRODUCTS_COUNT'
export const SET_PROCESSING_FEES = 'SET_PROCESSING_FEES'
export const SET_PROGRESS = 'SET_PROGRESS'
export const SET_DROP_SHIP_PRODUCTS = 'SET_DROP_SHIP_PRODUCTS'
export const SET_RECEIVE_RESULTS = 'SET_RECEIVE_RESULTS'
export const SET_RUSH_PRIMARY_CATEGORIES = 'SET_RUSH_PRIMARY_CATEGORIES'
export const SET_RUSH_PRODUCT = 'SET_RUSH_PRODUCT'
export const SET_RUSH_PRODUCTS = 'SET_RUSH_PRODUCTS'
export const SET_RUSH_SECONDARY_CATEGORIES = 'SET_RUSH_SECONDARY_CATEGORIES'
export const SET_SKU = 'SET_SKU'
export const SET_STEP = 'SET_STEP'
export const SET_STEPS = 'SET_STEPS'
export const SET_STORES = 'SET_STORES'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_TOTAL_ROWS = 'SET_TOTAL_ROWS'
export const SET_USER = 'SET_USER'
export const SET_USER_BEFORE_EDIT = 'SET_USER_BEFORE_EDIT'
export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS'
export const SET_VENDOR = 'SET_VENDOR'
export const SET_VENDOR_PRODUCT = 'SET_VENDOR_PRODUCT'
export const SET_VENDOR_PRODUCT_BEFORE_EDIT = 'SET_VENDOR_PRODUCT_BEFORE_EDIT'
export const SET_VENDOR_PRODUCT_PROP = 'SET_VENDOR_PRODUCT_PROP'
export const SET_VENDOR_PRODUCT_READONLY_FIELDS = 'SET_VENDOR_PRODUCT_READONLY_FIELDS'
export const SET_VENDORS = 'SET_VENDORS'
