export default {
  path: '/orders',
  name: 'Orders',
  redirect: 'orders/lookup',
  component: {
    render(c) {
      return c('router-view')
    },
  },
  children: [
    { 
      path: 'lookup',
      name: 'OrderLookup',
      component: () => import('@/views/orders/OrderLookup'),
      meta: {
        label: 'Lookup',
        allow: [['isInternal'],['isPartner'],['isVendor','vendorSettings.isOrderLookupEnabled']]
      }, 
      children: [
        {
          path: ':sourceOrderName',
          name: 'OrderDetail',
          component: () => import('@/views/orders/OrderDetail'),
          meta: {
            label: ':sourceOrderName',
            allow: ['isInternal','isPartner',['isVendor','vendorSettings.isOrderLookupEnabled']]
          }
        },
      ]         
    },{
      path: 'dashboard',
      name: 'OrderDashboard',
      component: () => import('@/views/orders/OrderDashboard'),
      meta: {
        label: 'Dashboard',
        allow: ['isInternal','isPartner',['isVendor','vendorSettings.isOrderDashBoardEnabled']]
      },
      children: [
        {
          path: ':listName',
          name: 'OrderList',
          component: () => import('@/views/orders/OrderList'),
          meta: {
            label: ':listName',
            allow: ['isInternal','isPartner',['isVendor','vendorSettings.isOrderDashBoardEnabled']]
          },
          children: [
            {
              path: ':sourceOrderName',
              name: 'OrderDetail',
              component: () => import('@/views/orders/OrderDetail'),
              meta: {
                label: ':sourceOrderName',
                allow: ['isInternal','isPartner',['isVendor','vendorSettings.isOrderDashBoardEnabled']]
              }
            },
          ]
        }
      ]
    }
    // RM-4588 - removed
    // },{
    //   path: 'fulfillment',
    //   name: 'OrderFulfillment',
    //   component: () => import('@/views/orders/Fulfillment'),
    //   meta: {
    //     label: 'Tracking & Invoices',
    //     allow: ['isDSVendor']
    //   },
    // }
  ]
}