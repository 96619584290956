// based from: https://github.com/dmfilipenko/timezones.json/blob/master/timezones.json
export default [
  // Pacific
  {
    timezone: 'America/Los_Angeles',
    country: 'US',
    utcStandardOffset: 'UTC-08:00',
    utcDaylightSavingOffset: 'UTC-07:00',
    daylightSavingObserved: true,
    label: '(UTC-0800) Pacific Time (US & Canada)'
  },  
  // Arizona
  {
    timezone: 'America/Phoenix',
    country: 'US',
    utcStandardOffset: 'UTC-07:00',
    utcDaylightSavingOffset: 'UTC-07:00',
    daylightSavingObserved: false,
    label: '(UTC-07:00) Arizona'
  }, 
  // Mountain
  {
    timezone: 'America/Denver',
    country: 'US',
    utcStandardOffset: 'UTC-07:00',
    utcDaylightSavingOffset: 'UTC-06:00',
    daylightSavingObserved: true,
    label: '(UTC-07:00) Mountain Time (US & Canada)'
  },  
  // Central
  {
    timezone: 'America/Chicago',
    country: 'US',
    utcStandardOffset: 'UTC-06:00',
    utcDaylightSavingOffset: 'UTC-05:00',
    daylightSavingObserved: true,
    label: '(UTC-06:00) Central Time (US & Canada)'
  },  
  // Eastern
  {
    timezone: 'America/New_York',
    country: 'US',
    utcStandardOffset: 'UTC-06:00',
    utcDaylightSavingOffset: 'UTC-05:00',
    daylightSavingObserved: true,
    label: '(UTC-06:00) Eastern Time (US & Canada)'
  }, 
  // Indiana
  {
    timezone: 'America/Indiana/Indianapolis',
    country: 'US',
    utcStandardOffset: 'UTC-05:00',
    utcDaylightSavingOffset: 'UTC-05:00',
    daylightSavingObserved: false,
    label: '(UTC-05:00) Indiana (East)'
  },  
]
