import rollbar from '@/utils/rollbar.util'

// Vue 2
export default {
  install: function(Vue) {
    Vue.rollbar = rollbar
    Vue.prototype.$rollbar = rollbar
  },
}

// Vue 3
/* export default {
  install: function (app) {
    app.config.globalProperties.$rollbar = rollbar
  },
} */
