<template>
  <c-footer class="bg-white text-dark" :fixed="false">
    <!--footer-->
    <div class="ml-auto">
      <span class="mr-1">&copy;{{ new Date().getFullYear() }} RushRecommerce</span>
      <span class="text-secondary">v{{ version }}</span>
    </div>
  </c-footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AppFooter',

  computed: {
    ...mapState({
      version: state => state.app.version,
    }),
  },
}
</script>

<style lang="scss" scoped></style>
