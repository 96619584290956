import ApiService from "@/services/api.service";
import { camelCase } from 'lodash'
import {
  DELETE_FILE,
  DELETE_IMAGES,
  POST_IMAGE_URL,
  POST_IMAGES_MULTIPART,
} from "./actions.type";

const state = {

}

const getters = {
  imagePathPrefix: (state, getters) => {
    if (getters.isVendor) {
      return getters.currentVendorPrefix
    } else if (getters.isPartner) {
      return camelCase(getters.currentPartner.name)
    }
    return getters.currentVendorPrefix || 'notSpecified'
  }
}

const actions = {

  /**
   * can be used to upload images or other files to the image server using a URL
   * @param {*} context
   * @param {*} param1 {
   *  filename,
   *  uploadUrl,
   *  tag = '',
   *  catalogContext,
   *  relativePath,
   *  nameCollision
   * }
   * @returns {promise}
   */
  [POST_IMAGE_URL] (context, {
    uploadUrl,
    tag = '',
    storageContext,
    storagePath,
    nameCollision='UNIQUE',
    saveToCoreleap=false,
    sku=null,
    vendorId=null,
    vendorSku=null
  }) {
    return new Promise((resolve, reject) => {
      const fileArr = [{
        fileName: uploadUrl.split('?').shift().split('/').pop(),
        url: uploadUrl,
        tag: tag
      }]
      let queryArr = [
        `context=${storageContext}`,
        `relativePath=${storagePath}`,
        `nameCollision=${nameCollision}`
      ]
      // optional: API can save the file to the files table too. required sku or vendorId + vendorSku
      if (saveToCoreleap === true) {
        queryArr.push(`saveToCoreleap=true`)
        queryArr.push(`tag=${tag}`)
        if (sku) {
          queryArr.push(`sku=${sku}`)
        } else {
          queryArr.push(`vendorId=${vendorId}`)
          queryArr.push(`vendorSku=${vendorSku}`)
        }
      }
      ApiService.post(`/images/urls?${queryArr.join('&')}`, { images: fileArr }).then( ({data}) => {
        resolve(data)
      }).catch( ({ response }) => {
        reject(response)
      })
    })
  },
  /**
   * upload a multipart form field image to image server and optionally to coreleap files
   * @param {Object} context 
   * @param {FormData} payload.formData - formData.append('images', file)
   * @param {String} payload.storageContext
   * @param {String} payload.vendorPrefix - part of relative path
   * @param {String} payload.field - part of relative path. Usally something like mainKnockoutImage or altImage3
   * @param {String} [payload.nameCollision=UNIQUE]
   * @param {Boolean} [payload.saveToCoreleap=false]
   * @param {String} [payload.tag=null] - when saveToCoreleap is true, sets the tag: damage, market, shipping_label
   * @param {Integer} [payload.sku=null] - required if saveToCoreleap is true
   * @param {String} [payload.vendorId=null] - required if saveToCoreleap is false
   * @param {String} [payload.vendorSku=null] - required if saveToCoreleap is false
   * @returns {Promise}
   */
  [POST_IMAGES_MULTIPART](context, payload) {
    return new Promise((resolve, reject) => {
      let queryArr = [
        `context=${payload.storageContext}`,
        `relativePath=products/${payload.vendorPrefix}/${payload.field}`,
        `nameCollision=${payload.nameCollision || 'UNIQUE'}`
      ]
      // optional: API can save the file to the files table too. required sku or vendorId + vendorSku
      if (payload.saveToCoreleap === true) {
        queryArr.push(`saveToCoreleap=true`)
        if (payload.tag) {
          queryArr.push(`tag=${payload.tag}`)
        }
        if (payload.sku) {
          queryArr.push(`sku=${payload.sku}`)
        } else {
          queryArr.push(`vendorId=${payload.vendorId}`)
          queryArr.push(`vendorSku=${payload.vendorSku}`)
        }
      }
      const endpoint = `images/multipart?${queryArr.join('&')}`
      const headers = {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
      ApiService.post(endpoint, payload.formData, headers).then( ({ data }) => {
        resolve(data)
      }).catch( ({ response }) => {
        reject(response)
      })
    })
  },
  [DELETE_IMAGES](context, payload) {
    return new Promise((resolve, reject) => {
      if(payload.image === undefined || payload.image === null || payload.images === ''){
        reject({ statusCode: 400, message: "Image url is required." })
        return
      }
      // get the relative path from the full path...
      // we have to split the string into an array, reverse it, take the first 3 positions, reverse back and then join
      // it back to a normal string
      let arr = payload.image.split("/").reverse()
      let newArr = []
      for(let i=0; i<=arr.length; i++){
        if(i <= 2){
          newArr.push(arr[i])
        }
      }
      let path = newArr.reverse().join("/")
      let endpoint = 'images?context=' + payload.storageContext + '&relativePath=products/' + path
      ApiService.delete(endpoint).then( ({ data }) => {
        resolve(data)
      }).catch( ({ response }) => {
        reject(response)
      })
    })
  },
  /**
   * delete a coreleap.files record
   * @param {Object} context 
   * @param {Integer} fileId 
   */
  async [DELETE_FILE] (context, fileId) {
    await ApiService.delete(`files/${fileId}`)
  }
}

const mutations = {

}

export default {
  name: 'images',
  state,
  actions,
  mutations,
  getters
}
