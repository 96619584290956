export default {
  path: '/rushproducts',
  name: 'Rush Products',
  redirect: '/rushproducts/lookup',
  component: {
    render(c) {
      return c('router-view')
    },
  },
  children: [
    {
      path: 'lookup',
      name: 'Lookup',
      component: () => import('@/views/products/RushProducts'),
      meta: {
        allow: ['isPartner',['isVendor','vendorSettings.isRushProductLookupEnabled']]
      }
    },{
      path: 'move',
      name: 'MoveLocation',
      component: () => import('@/views/rushproducts/Move'),
      meta: {
        label: 'Move Location',
        allow: ['isPartner',['isVendor','vendorSettings.isRushProductChangeLocationEnabled']]
      }
    }
  ]
}