/**
 * Takes an array of items and sums a number field
 * @param {array} items
 * @param {string} property
 * @returns
 */
 export default function sumNumber (items, property) {
  return items.reduce( (sum, item) => {
    return sum + item[property]
  }, 0)
}