import { get } from 'lodash'
import store from '@/store'

// ------------------------------------------------------------------
// ACTIONS FOR TASK
// ------------------------------------------------------------------

// force these endpoints to use the task url
const ACTIONS_FOR_TASK_URL = [
  { url: '/catalogJobs', method: 'post' },
  { url: '/inventoryJobs', method: 'post' },
]


/**
 * @description updates the config object's baseURL if the request is for a task endpoint
 * @param {Object} config 
 * @returns {Object}
 */
export const actionForTask = (config) => {
  // actions that use the task url for the endpoint
  ACTIONS_FOR_TASK_URL.forEach(item => {
    if (config.url.includes(item.url) && config.method.toLowerCase() === item.method) {
      config.baseURL = process.env.VUE_APP_API_TASK_URL
    }
  })
  return config
}


// ------------------------------------------------------------------
// INTERNAL USER ACTIONS FOR EXTERNAL
// ------------------------------------------------------------------

// force these actions to call the external API
const ACTIONS_EXTERNAL_ONLY = [
  { url: 'users/current', method: 'get' },
  { url: 'products/exportJobs', method: 'get' },
  { url: 'products/exportJobs', method: 'post' },
]

/**
 * @description updates the config object's header X-APP-ID if the request is for an external endpoint
 * @param {Object} config 
 * @returns {Obejct}
 */
export const actionForExternal = (config, { isInternal }) => {

  // only for internal users
  if (isInternal !== true) {
    return config
  }

  // calls that are external only cannot switch the APP ID
  const externalCall = ACTIONS_EXTERNAL_ONLY.find(item => config.url.includes(item.url) && item.method.toLowerCase() === config.method) ? true : false
  if (externalCall === false) {
    config.headers.common['X-APP-ID'] = process.env.VUE_APP_I_API_APP_ID
  }
  return config
}


// ------------------------------------------------------------------
// VENDOR PERMISSIONS
// ------------------------------------------------------------------

// vendors can have special permissions to certain features that were originally integrated for partners (such as: product processing)
// we will open up those endpoints on the front end with these definitions in an array. Getters are from store modules
// getters are defined as an array of arrays:
// [ [getter1, getter2], [getter3] ]
// mean: getter1 && getter2 must both be true OR getter3 must be true for the endpoint to be accessible
//
// these same getters are used within routes and nav items as the first layer of security to restrict/allow access
const VENDOR_PERMISSIONS = [
  { url: '/images', method: 'delete', getters: [['vendorSettings.isRBRProcessingEnabled']] },
  { url: '/images/multipart', method: 'post', getters: [['vendorSettings.isRBRProcessingEnabled']] },
  { url: '/images/urls', method: 'post', getters: [['vendorSettings.isRBRProcessingEnabled']] },
  { url: '/files', method: 'delete', getters: [['vendorSettings.isRBRProcessingEnabled']] },
  { url: '/orders', method: 'get', getters: [['vendorSettings.isOrderDashBoardEnabled'],['vendorSettings.isOrderLookupEnabled']] },
  { url: '/orders/byId', method: 'get', getters: [['vendorSettings.isOrderDashBoardEnabled'],['vendorSettings.isOrderLookupEnabled']] },
  { url: '/orders/items', method: 'put', getters: [['vendorSettings.isOrderDashBoardEnabled'],['vendorSettings.isOrderLookupEnabled']] },
  { url: '/orders/pdf', method: 'post', getters: [['vendorSettings.isOrderDashBoardEnabled'],['vendorSettings.isOrderLookupEnabled']] },
  { url: '/partnerActivity', method: 'get', getters: [['vendorSettings.isActivityReportEnabled']] },
  { url: '/products/lookup', method: 'post', getters: [['vendorSettings.isRBRProcessingEnabled']] },
  { url: '/products/process/receive', method: 'put', getters: [['vendorSettings.isRBRProcessingEnabled']] },
  { url: '/products/process/receive', method: 'post', getters: [['vendorSettings.isRBRProcessingEnabled']] },
  { url: '/products/process/verify', method: 'put', getters: [['vendorSettings.isRBRProcessingEnabled']] },
  { url: '/products/process/reshipping', method: 'put', getters: [['vendorSettings.isRBRProcessingEnabled']] },
  { url: '/products/process/condition', method: 'put', getters: [['vendorSettings.isRBRProcessingEnabled']] },
  { url: '/products/process/locate', method: 'put', getters: [['vendorSettings.isRushProductChangeLocationEnabled'],['vendorSettings.isRBRProcessingEnabled']] },
]

/**
 * @description checks the request to see if the vendor has access to the endpoint
 * @param {Object} config - config from the axios interceptor
 * @returns {Boolean}
 */
export const hasVendorPermission = (config, { isVendor }) => {

  // only for vendors
  if (isVendor !== true) {
    return true
  }

  // check to see if the endpoint/method exists in the defintions
  const perm = VENDOR_PERMISSIONS.find(item => config.url.includes(item.url) && item.method.toLowerCase() === config.method)

  // if not defined in VENDOR_PERMISSIONS, then allow it by default
  if (!perm) {
    return true
  }
 
  const allowed = perm.getters.filter(item => item.every(getter => get(store.getters,getter) === true))

  return allowed.length === 0 ? false : true
}