<template>
  <c-button
      :type="type"
      :color="color"
      :variant="variant"
      :disabled="disabled || loading"
      :to="to"
      @click="$emit('click')">
    <template v-if="loading"><i class="fa fa-spin fa-spinner" />&nbsp;{{ loadingLabel }}</template>
    <template v-else-if="!hasBtnSlot">{{ label }}</template>
    <slot v-else/>
  </c-button>
</template>

<script>
export default {
  name: 'SubmitButton',
  props: {
    type: { type: String, required: false, default: 'submit' },
    label: { type: String, required: false, default: 'Submit' },
    loadingLabel: { type: String, required: false, default: 'Please wait...' },
    loading: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    to: { type: String, required: false, default: '' },
    variant: { type: String, required: false, default: '' },
    color: { type: String, required: false, default: 'primary' },
  },
  computed: {
    hasBtnSlot () {
      return !!this.$slots.default
    }
  },
  methods: {
    clicked() {
      this.$emit('clicked')
    },
  },
}
</script>
