import ApiService from '@/services/api.service'
import {
  FETCH_ORDER,
  FETCH_ORDER_LINE_LOG,
  FETCH_ORDERS,
  FETCH_ORDERS_PDF,
  FULFILL_ORDER_ITEM,
  UPDATE_ORDER_LINE,
  UPDATE_ORDER_LINES,
} from "./actions.type"
import {

} from "./mutations.type"

const state = {

}

const getters = {

}

const actions = {
  /**
   * @description fullfil an order item
   * @function FULFILL_ORDER_ITEM
   * @param {Object} context
   * @param {String} params.vendorId
   * @param {Integer} params.payload.orderId - source_order_name
   * @param {String} params.payload.invoiceNumber - the vendors invoice number
   * @param {String} params.payload.vendorSku
   * @param {String} params.payload.tracking
   * @returns {Promise}
   */
  async [FULFILL_ORDER_ITEM] (context, { vendorId, payload }) {
    return await ApiService.post(`/vendors/${vendorId}/fulfill`, payload)
  },
  /**
   * @description fetch orders
   * @function FETCH_ORDERS
   * @param {Object} context
   * @param {Integer} params.currentPage
   * @param {Integer} params.perPage
   * @param {Integer} params.productStoreId
   * @param {String} [params.orderLineStaticStatus] - 'ISNULL' or the desired status
   * @param {String} [params.customerFirstName]
   * @param {String} [params.customerLastName]
   * @param {String} [params.customerEmail]
   * @param {Integer} [params.sourceOrderName]
   * @param {Integer} [params.sku]
   * @param {String} [params.sortBy] - ie. 'orderDateCreated DESC'
   * @param {Boolean} [params.countOnly=false]
   * @returns {Array}
   */
  async [FETCH_ORDERS] (context, params = {}) {
    const response = await ApiService.query('/orders', { params });
    return response.data.data;
  },
  /**
   * @description fetch order
   * @function FETCH_ORDER
   * @param {Object} context
   * @param {Integer} params.sourceOrderName
   * @returns {Object}
   */
  async [FETCH_ORDER] (context, {
    sourceOrderName
  }) {
    const response = await ApiService.query(`/orders/byId/${sourceOrderName}`, {
      params: {
        productStoreId: context.getters.currentStoreId
      }
    });
    return response.data.data;
  },
  /**
   * @description fetch order line log
   * @function FETCH_ORDER_LINE_LOG
   * @param {Object} context
   * @param {Integer} params.orderLineStaticId
   * @returns {Array}
   */
  async [FETCH_ORDER_LINE_LOG] (context, params = {}) {
    const { orderLineStaticId } = params;
    const response = await ApiService.query(`/orderLineLog/${orderLineStaticId}`);
    return response.data.data
  },

  /**
   * @description update a specific order line
   * @function UPDATE_ORDER_LINE
   * @param {Object} context 
   * @param {Integer} param.sourceLineId
   * @param {Integer} param.sku - Rush SKU
   * @param {String} param.status
   * @param {Array} [param.boxes]
   * @param {String} [param.trackingNumber]
   * @param {String} [param.issueReason]
   * @param {String} [param.notes]
   * @param {String} [param.resolutionReason]
   * @param {String} [param.resolutionNotes]
   * @returns {Promise}
   */
   [UPDATE_ORDER_LINE] (context, { 
    sourceLineId,  
    sku,
    status,
    boxes=null,
    trackingNumber=null,
    issueReason=null,
    notes=null,
    resolutionReason=null,
    resolutionNotes=null
  }) {
    return ApiService.put(`/orders/items/${sourceLineId}/?productStoreId=${context.getters.currentStoreId}&rushSku=${sku}`, {
      status,
      boxes,
      trackingNumber,
      issueReason,
      notes,
      resolutionReason,
      resolutionNotes     
    })
  },

  /**
   * @dsescription update all order lines
   * @function UPDATE_ORDER_LINES
   * @param {Object} context 
   * @param {Integer} param.sourceOrderName
   * @param {Object} param.payload - { status }
   * @returns {Promise}
   */
  async [UPDATE_ORDER_LINES] (context, { sourceOrderName, payload={} }) {
    const response = await ApiService.put(`/orders/${sourceOrderName}/items?productStoreId=${context.getters.currentStoreId}`, payload)
    return response
  },
  /**
   * @description generate a PDF for the given orderIds
   * @function FETCH_ORDERS_PDF
   * @param {Object} context
   * @param {Integer[]} params.orderIds - sourceOrderName
   * @param {Boolean} [params.forceMultiOrderFormat=false]
   * @returns {Blob} the PDF
   */
   async [FETCH_ORDERS_PDF] (context, { orderIds, forceMultiOrderFormat=false }) {
    const payload = {
      orderIds,
      forceMultiOrderFormat
    }
    const response = await ApiService.post(`/orders/pdf?productStoreId=${context.getters.currentStoreId}`, payload, { responseType: 'blob' });
    return response.data
  },  
}

const mutations = {

}

export default {
  name: 'orders',
  state,
  actions,
  mutations,
  getters
}
