import ApiService from '@/services/api.service'
import { get } from 'lodash'
import { FETCH_CONDITION_TOOLTIP, GET_MASTER_DATA } from './actions.type'
import { SET_MASTER_DATA_STATE } from './mutations.type'
import { useVuexCache } from '@/composables/useVuexCache'

const cache = useVuexCache()

const state = {
  conditionTooltip: cache.use(null)
}

const getters = {
  conditionTooltip: state => cache.get(state.conditionTooltip) || null
}

const actions = {

  /**
   * fetch the condition help tooltip html
   * @param {object} context 
   * @param {boolean} param.refreshCache
   * @returns 
   */
  async [FETCH_CONDITION_TOOLTIP] (context) {
    if (cache.isValid(context.state.conditionTooltip)) {
      return
    }
    const { data } = await ApiService.query('masterdata', { params: { type: 'conditionToolTip' }})
    context.commit(SET_MASTER_DATA_STATE, { 
      key: 'conditionTooltip', 
      value: get(data,'data.masterData[0].descriptionPlus') || null,
      useCache: true
    })
  },

  [GET_MASTER_DATA](context, {
    type,
    active='Y',
    limit=10,
    offset=0,
  }) {
    return ApiService.query('masterdata', {
      params: {
        type,
        active,
        limit,
        offset
      }
    })
  },
}

const mutations = {
  [SET_MASTER_DATA_STATE] (state, { key, value, useCache=false }) {
    state[key] = (useCache) ? cache.set(value) : value
  }
}

export default {
  name: 'masterdata',
  state,
  actions,
  mutations,
  getters,
}
