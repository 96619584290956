/*
  name {String} - label of the nav item
  to {String} - route to go to
  fontIcon {String} - icon to show
  showItem {Array} -

  Details about showItem...
  Items of the array are getters. 
  If any are true, the item will be shown.
  An item can be an array of props to indicate multiple must be true. 
  A few examples:

  Both isDSVendor and navCreateDropShipProducts must be true to show it...
  showItem: [
    ['isDSVendor','navCreateDropShipProducts']
  ]

  If isDSVendor or navCreateDropShipProducts is true, it would be shown...
  showItem: ['isDSVendor','navCreateDropShipProducts']

  Must be either isInternal or [isVendor and allowProductEdit]...
  showItem: [
    'isInternal',
    ['isVendor','allowProductEdit']
  ]
 */

import store from '@/store'
import { get } from 'lodash'

export default [
  {
    _name: 'c-sidebar-nav',
    _children: [
      // dashboard
      {
        _name: 'c-sidebar-nav-item',
        name: 'Dashboard',
        to: '/dashboard',
        fontIcon: 'fa fa-tachometer'
      },
      // products
      {
        _name: 'c-sidebar-nav-dropdown',
        name: 'Products',
        to: '/products',
        fontIcon: 'fa fa-couch',
        items: [
          {
            name: store.getters.isVendor ? 'Process Returns' : 'Process RBR',
            to: '/products/process',
            fontIcon: 'fa fa-arrow-rotate-left',
            showItem: [
              ['isPartner'],
              ['isVendor', 'vendorSettings.isRBRProcessingEnabled']
            ]
          },{
            name: 'Process Manifested',
            to: '/products/process-truckload',
            fontIcon: 'fa fa-truck-ramp-box',
            showItem: ['isPartner']
          },{
            name: 'Product Catalog',
            to: '/products/catalog',
            fontIcon: 'fa fa-list',
            showItem: ['isVendor']
          },{
            name: 'Rush SKU Lookup',
            to: '/products/lookup',
            fontIcon: 'fa fa-search',
            showItem: [
              ['isPartner'],
              ['isVendor', 'vendorSettings.isRushProductLookupEnabled']
            ]
          },{
            name: 'Move Rush SKUs',
            to: '/rushproducts/move',
            fontIcon: 'fa fa-up-down-left-right',
            showItem: [
              ['isPartner'],
              ['isVendor', 'vendorSettings.isRushProductChangeLocationEnabled']
            ]
          },{
            name: store.getters.isVendor && get(store.getters,'vendorSettings.isRBRProcessingEnabled') ? 'Returns On Hand' : 'RBR On Hand',
            to: '/products/rbr',
            fontIcon: 'fa fa-list',
            showItem: ['isRBRVendor']
          },{
            name: 'Active Drop Ship',
            to: '/products/dropship',
            fontIcon: 'fa fa-list',
            showItem: ['isDSVendor']
          },{
            name: 'Create Drop Ship Products',
            to: '/products/dropship/create',
            fontIcon: 'fa fa-copy',
            showItem: [
              ['isDSVendor','navCreateDropShipProducts']
            ]
          },{
            name: 'Upload Products',
            to: '/products/upload',
            fontIcon: 'fa fa-cloud-upload',
            showItem: [
              ['isInternal','isVendor']
            ]
          },{
            name: 'Product Exports',
            to: '/products/exports',
            fontIcon: 'fa fa-cloud-download',
            showItem: [
              ['isPartner'],
              ['isVendor']
            ]
          },
        ],
      },
      // orders
      {
        _name: 'c-sidebar-nav-dropdown',
        name: 'Orders',
        to: '/orders',
        fontIcon: 'fa fa-shopping-cart',
        showItem: ['isPartner','vendorSettings.isOrderLookupEnabled','vendorSettings.isOrderDashBoardEnabled'],
        items: [
          {
            name: 'Dashboard',
            to: '/orders/dashboard',
            fontIcon: 'fa fa-tachometer',
            showItem: [
              ['isPartner'],
              ['isVendor','vendorSettings.isOrderDashBoardEnabled']
            ]
          },{
            name: 'Order Lookup',
            to: '/orders/lookup',
            fontIcon: 'fa fa-search',
            showItem: [
              ['isPartner'],
              ['isVendor','vendorSettings.isOrderLookupEnabled']
            ]
          }
          // RM-4588 - removed removed
          // },{
          //   name: 'Order Fulfillment',
          //   to: '/orders/fulfillment',
          //   fontIcon: 'fa fa-file-invoice',
          //   showItem: ['isDSVendor'],
          // }
        ],
      },
      // inventory
      {
        _name: 'c-sidebar-nav-dropdown',
        name: 'Inventory',
        to: '/inventory',
        fontIcon: 'fa fa-cart-flatbed',
        showItem: [
          ['isInternal','isVendor']
        ],
        items: [
          {
            name: 'Upload Inventory',
            to: '/inventory/upload',
            fontIcon: 'fa fa-cloud-upload',
          },
        ],
      },
      // images
      {
        _name: 'c-sidebar-nav-dropdown',
        name: 'Images',
        to: '/images',
        fontIcon: 'fa fa-images',
        showItem: ['isVendor'],
        items: [
          {
            name: 'Upload Images',
            to: '/images/upload',
            fontIcon: 'fa fa-cloud-upload',
          },
        ],
      },
      // reporting
      {
        _name: 'c-sidebar-nav-dropdown',
        name: 'Reporting',
        to: '/reporting',
        fontIcon: 'fa fa-chart-line',
        showItem: [
          ['isRBRVendor','vendorSettings.isRBRActivityReportEnabled'],
          ['isDSVendor'],
          ['isPartner','isAdmin'],
          ['isPartner','isManager'],
          ['isVendor', 'vendorSettings.isActivityReportEnabled'],
        ],
        items: [
          {
            name: store.getters.isVendor ? 'Rush Activity' : 'RBR Activity',
            to: '/reporting/rbr-activity',
            fontIcon: 'fa fa-list',
            showItem: [
              ['isRBRVendor','vendorSettings.isRBRActivityReportEnabled']
            ]
          },{
            name: 'Drop Ship Activity',
            to: '/reporting/dropship-activity',
            fontIcon: 'fa fa-list',
            showItem: ['isDSVendor']
          },{
            name: store.getters.isVendor ? 'Vendor Activity' : 'Activity',
            to: '/reporting/partner-activity',
            fontIcon: 'fa fa-list',
            showItem: [
              ['isVendor', 'vendorSettings.isActivityReportEnabled'],
              ['isPartner','isAdmin'],
              ['isPartner','isManager'],              
            ]
          }
        ],
      },
      // routing
      {
        _name: 'c-sidebar-nav-dropdown',
        name: 'Routing',
        to: '/routing',
        fontIcon: 'fa fa-truck-arrow-right',
        showItem: [
          ['isInternal','isVendor'],
          ['isReturnRoutingEnabled'],
          ['isLtlReturnsRoutingEnabled']
        ],
        items: [
          {
            name: 'Small Parcel Routing',
            to: '/routing/route-my-return',
            fontIcon: 'fa fa-boxes-packing',
            showItem: ['isReturnRoutingEnabled'],
          },{
            name: 'LTL Return Pick Ups',
            to: '/routing/ltl',
            fontIcon: 'fa fa-truck-arrow-right',
            showItem: [
              ['isInternal','isVendor'],
              ['isLtlReturnsRoutingEnabled']
            ],
          },
        ],
      },
      // administrative
      {
        _name: 'c-sidebar-nav-dropdown',
        name: 'Administrative',
        to: '/administrative',
        fontIcon: 'fa fa-folder-open',
        showItem: [
          ['isAdmin','isPartner']
        ],
        items: [
          {
            name: 'Accounting',
            to: '/administrative/accounting',
            fontIcon: 'fa fa-calculator'
          },{
            name: 'Business Entity',
            to: '/administrative/business-entity',
            fontIcon: 'fa fa-globe'
          },{
            name: 'Customer Service',
            to: '/administrative/cs',
            fontIcon: 'fa fa-headset'
          },{
            name: 'Facilities',
            to: '/administrative/facilities',
            fontIcon: 'fa fa-warehouse'
          },{
            name: 'Holiday Schedule',
            to: '/administrative/holiday-schedule',
            fontIcon: 'fa fa-calendar'
          },{
            name: 'Users',
            to: '/administrative/users',
            fontIcon: 'fa fa-users'
          },
        ],
      },
    ],
  },
]
