import ApiService from '@/services/api.service'
import { get } from 'lodash'
import { FETCH_DISPOSAL_FEES } from './actions.type'
import { SET_DISPOSAL_FEES } from './mutations.type'


const state = {
  disposalFees: []
}

const getters = {
  disposalFees (state) {
    return state.disposalFees
  }
}

const actions = {
  /**
   * get disposal fee tiers by vendor
   * @param {Object} context
   * @param {String} params.vendorId <required>
   * @param {String} params.active
   * @param {String} params.sortBy
   * @param {Integer} params.limit
   */
  async [FETCH_DISPOSAL_FEES] (context, {
    vendorId,
    active = 'Y',
    sortBy = 'boxSizeMin:ASC',
    limit = 9999,
  }) {
    try {
      const params = { vendorId, active, sortBy, limit }
      const { data } = await ApiService.query('/productDisposalFeeRules', { params: params })
      context.commit(SET_DISPOSAL_FEES, get(data,'data.disposalFeeRules',[]))
    } catch (err) {
      context.commit(SET_DISPOSAL_FEES, [])
      if (get(err,'response.statusCode') !== 404) {
        throw(err)
      }
    }
  }
}

const mutations = {
  [SET_DISPOSAL_FEES] (state, disposalFees) {
    state.disposalFees = disposalFees
  }
}

export default {
  name: 'disposalFees',
  state,
  actions,
  mutations,
  getters
}
