import ApiService from '@/services/api.service'
import { get } from 'lodash'
import formatMoment from '@/shared/filters/moment'
import {
  EXPORT_RBR_ON_HAND,
  FETCH_RUSH_PRODUCTS
} from './actions.type'
import {
  SET_RUSH_PRODUCTS,
  SET_TOTAL_ROWS,
} from './mutations.type'

const state = {
  rushProducts: [],
  totalRows: 0,
}

const getters = {
  rushProducts: state => state.rushProducts,
  totalRows: state => state.totalRows,
}

const actions = {

  /**
   * 
   * @param {Object} context 
   * @param {String} params.label
   * @param {Object} params.filters - rushProducts filters
   * @returns 
   */
  [EXPORT_RBR_ON_HAND] (context, { 
    label='RBR On Hand', 
    filters={}
  }) {
    // currently only for a vendor/internal user
    // if (context.getters.isVendor === false) {
    //   throw new Error('Export function is not available')
    // }

    // flatten any property that is an array into a comma list
    filters = Object.entries(filters).reduce((acc,[key, val]) => {
      acc[key] = Array.isArray(val) ? val.join(',') : val
      return acc
    }, {})

    if (filters.location) {
      filters.storageArea = filters.location.area.join(',') || null
      filters.storageZone = filters.location.zone.join(',') || null
      filters.storageLocation = filters.location.location.join(',') || null
      delete filters.location
    }

    const dateForFilename = formatMoment(new Date(), 'YYYY-MM-DD')
    return ApiService.post(`/products/exportJobs`, {
      ...filters,
      context: 'RUSHPRODUCTS',
      format: 'RBR-ON-HAND',
      label: `${label} ${dateForFilename}`,
      storeId: context.getters.currentStoreId,
      vendorId: context.getters.currentVendorId,
      submitterType: context.getters.submitterType,
      submitterId: context.getters.currentVendorId,
      sendToEmail: context.getters.isInternal ? context.getters.currentUser.email : context.getters.currentVendor.email
    })   
  },
  /**
   * get rush products data
   * @param {Object} context
   * @param {String} [params.vendorSku]
   * @param {String|Array} [params.status]
   * @param {String} [params.sku] - Rush SKU - accepts comma list
   * @param {String|Array} [params.conditionName]
   * @param {Integer} [params.category1Id] - Rush category id
   * @param {Integer} [params.category2Id] - Rush category id
   * @param {String} [params.upc]
   * @param {String} [params.mpn]
   * @param {String} [params.manifestSource]
   * @param {String} [params.storageArea]
   * @param {String} [params.storageZone]
   * @param {String} [params.storageLocation]
   * @param {String[]} [params.processedBy] - Vendor | Rush - sent to API as comma list
   * @param {Integer} [params.perPage]
   * @param {Integer} [params.currentPage]
   * @param {String} [params.sortBy]
   * @param {String} [params.sortDesc]
   */
  async [FETCH_RUSH_PRODUCTS] (context, {
    vendorSku=null,
    status=null,
    sku=null,
    conditionName=null,
    category1Id=null,
    category2Id=null,
    upc=null,
    mpn=null,
    manifestSource='RBR',
    storageArea=null,
    storageZone=null,
    storageLocation=null,
    processedBy=undefined,
    perPage=null,
    currentPage=null,
    sortBy=null,
    sortDesc=null,
  }) {
    // make sure this is present before calling
    if (!context.getters.currentVendorId && context.getters.currentStoreId === 0) {
      return
    }
    // parters can process both RBR and DIRECT_BUY loads, we need to make sure the UI is always displaying both
    if (context.getters.isPartner) {
      manifestSource = 'RBR,DIRECT_BUY'
    }
    const limit = (perPage) ? perPage : null
    let offset = null
    if (perPage && currentPage) {
      offset = (currentPage === 1) ? 0 : ((currentPage-1) * limit)
    }
    if (Array.isArray(conditionName)) {
      conditionName = (conditionName.length) ? conditionName.join(',') : null
    }
    if (Array.isArray(status)) {
      status = (status.length) ? status.join(',') : null
    }

    // of processdBy is sent in, make sure the storeId parameter is not set
    // we are looking at "Rush" or "Vendor" which logic on API side dictates which storeIds to use
    let queryStoreId = context.getters.currentStoreId || null
    if (processedBy !== undefined && processedBy !== null) {
      queryStoreId = null
    }

    const { data } = await ApiService.query(`/rushProducts`, {
      params: {
        storeId: queryStoreId,
        vendorId: context.getters.currentVendorId || null,
        partnerId: context.getters.currentPartnerId || null,
        vendorSku,
        status,
        sku,
        conditionName,
        category1Id,
        category2Id,
        upc,
        mpn,
        manifestSource,
        storageArea,
        storageZone,
        storageLocation,
        processedBy: processedBy ? processedBy.join(',') : null,
        limit,
        offset,
        sortBy: (sortBy && sortDesc) ? `${sortBy}:${sortDesc}` : null,
      }
    })
    context.commit(SET_TOTAL_ROWS, get(data,'metaData.totalCount',0))
    context.commit(SET_RUSH_PRODUCTS, get(data,'data.rushProducts',[]))
    // in case we have multiple instances of rush products on the page, we can return them instead of using state
    // this is the case for the product details page where DS and RBR products are shown
    return data
  },
}

const mutations = {
  [SET_RUSH_PRODUCTS] (state, rushProducts) {
    state.rushProducts = rushProducts
  },
  [SET_TOTAL_ROWS] (state, num) {
    state.totalRows = num
  }
}

export default {
  name: 'rushProducts',
  state,
  actions,
  mutations,
  getters,
}
