import ApiService from '@/services/api.service'
import {  
  ADD_PARTNER_HOLIDAY,
  DELETE_PARTNER_HOLIDAY,
  FETCH_PARTNER_HOLIDAYS,
  SAVE_PARTNER_HOLIDAY,
} from './actions.type'

const state = {}

const getters = {}

const actions = {
  /**
   * add a new partner holiday
   * @param {Object} context 
   * @param {String} param.label
   * @param {Date} param.day - yyyy-mm-dd
   * @returns 
   */
  async [ADD_PARTNER_HOLIDAY](context, {
    label,
    day
  }) {
    return await ApiService.post(`/partners/${context.getters.currentPartnerId}/holidays`, {
      label,
      day
    })
  },

  /**
   * delete a partner holiday
   * @param {Object} context 
   * @param {String} holidayId
   * @returns 
   */  
  async [DELETE_PARTNER_HOLIDAY](context, holidayId) {
    return await ApiService.delete(`/partners/${context.getters.currentPartnerId}/holidays/${holidayId}`)
  },

  /**
   * get partner holidays
   * @returns 
   */  
  async [FETCH_PARTNER_HOLIDAYS](context) {
    return await ApiService.get(`/partners/${context.getters.currentPartnerId}/holidays`)
  },

  /**
   * update an existing partner holiday
   * @param {Object} context 
   * @param {String} param.id
   * @param {String} param.label
   * @param {Date} param.day - yyyy-mm-dd
   * @returns 
   */  
  async [SAVE_PARTNER_HOLIDAY](context, {
    id,
    label,
    day
  }) {
    return await ApiService.put(`/partners/${context.getters.currentPartnerId}/holidays/${id}`, {
      label,
      day
    })
  },
}

const mutations = {}

export default {
  name: 'partnersHolidaySchedule',
  state,
  actions,
  mutations,
  getters,
}
