export const DEFAULT_RECEIVING_REQUIREMENTS = {
  shippingLabelPhotoRequired: 'N',
  trackingNumberRequired: 'N',
  trashPhotoRequired: 'Y'
}

export const DEFAULT_RUSH_SKU = { 
  sku: '', 
  _loading: false, 
  _error: null 
}

export const DEFAULT_RECEIVE_FORM = {
  customerFirstName: null,
  customerLastName: null,
  isTrash: false,
  notes: null,
  productSearchText: null,
  putBackInNewStock: false,
  quantity: 1,
  receivingRequirements: {...DEFAULT_RECEIVING_REQUIREMENTS},
  selectedProduct: {},
  selectedManifest: null,
  selectedVendor: null,
  shippingLabelImages: [],
  skus: [{...DEFAULT_RUSH_SKU}],
  trackingNumber: null,
  trashImages: [],
  vendorSupplierCode: null,
}
