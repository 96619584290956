import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import { debugLog } from '@/utils/debugger.util'
import { pathFromObj } from '@/shared/utils'
import { CHECK_AUTH, RESET_LOADERS } from '../store/actions.type'
import { has } from 'lodash'

import dashboardRouter from './dashboard.router'
import productsRouter from './products.router'
import rushProductsRouter from './rushProducts.router'
import inventoryRouter from './inventory.router'
import imagesRouter from './images.router'
import usersRouter from './users.router'
import accountRouter from './account.router'
import ordersRouter from './orders.router'
import reportingRouter from './reporting.router'
import routingRouter from './routing.router'
import administrativeRouter from './administrative.router'

Vue.use(Router)

/**
 * meta {Object}
 * meta.label {String} - for breadcrumb (use :sourceOrderName to replace value with URL param value)
 * meta.allow {array} - see top of _nav.js for more details
 * meta.public {Boolean}
 * meta.hideBreadcrumbs {Boolean}
 * meta.hideSideBar {Boolean}
 */

const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
    },
    dashboardRouter,
    productsRouter,
    rushProductsRouter,
    inventoryRouter,
    imagesRouter,
    usersRouter,
    accountRouter,
    ordersRouter,
    reportingRouter,
    routingRouter,
    administrativeRouter,
    {
      path: '*',
      name: 'NotFound',
      component: () => import('@/views/NotFound'),
    },
  ],
})


const checkAuth = (to,from,next) => {
  store
  .dispatch(CHECK_AUTH)
  .then(() => {
    if (to.name !== 'ChangePassword' && store.getters.changePassword === true) {
      // force password change
      next({ name: 'ChangePassword' })
    } else if (has(to.meta, 'allow')) {
      // check to see if there are any rules to allow it
      // examples and more explanation at top of _nav.js - allow and showItem follow same logic
      // if any are true, allow the route
      const allowItem = to.meta.allow.find(prop => {
        // if the prop is an array of props, all must be true
        if (Array.isArray(prop)) {
          const foundFalse = prop.find(p => pathFromObj(store.getters,p) === false)
          return !foundFalse
        }
        return pathFromObj(store.getters,prop) === true
      })
      if (allowItem) {
        next()
      } else {
        next({ name: 'NotFound' })
      }
    } else {
      // item does not have any allow rules, always allow it
      next()
    }
  })
  .catch(error => {
    debugLog('CHECK AUTH error in beforeEach -> ', error)
    // if you are already on the login page, reload it
    if (from.name === 'Login') {
      router.go()
    } else {
      next({ name: 'Login' })
    }
  })
}

const checkPublic = (to,from,next) => {
  // allow public pages to continue, else check for authorization
  if (to.meta.public) {
    next()
  } else {
    checkAuth(to,from,next)
  }
}

const waitForStorageToBeReady = async (to, from, next) => {

  await store.restored

  store.dispatch(RESET_LOADERS) // may need to move this to app.vue for added logic
  Vue.prototype.$toast.removeAll() // clear all toasts

  checkPublic(to, from, next)
}

router.beforeEach(waitForStorageToBeReady)

export default router
