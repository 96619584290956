<template>
  <c-breadcrumb :items="breadcrumbs" class="border-bottom bg-white mb-0 shadow-sm" />
</template>

<script>
import { capFirst } from '@/shared/utils'
export default {
  name: 'AppBreadcrumbs',
  computed: {
    breadcrumbs() {
      return [
        { text: 'Home', to: { name: 'Home' } },
        ...this.$route.matched
          .filter(route => route.meta.label || route.name)
          .map(r => ({
            text: this.labelText(r),
            // replace any variable names in the route path with their current values
            to: Object.entries(this.$route.params).reduce((to, [param, value]) => {
              return to.replace(`:${param}`, value)
            }, r.path),
          })),
      ]
    },
  },
  methods: {
    labelText(r) {
      // check for parameter being used, this will put the actual value of the param in the breadcrumb
      if (r.meta.label && r.meta.label.includes(':')) {
        const param = r.meta.label.replace(':', '')
        return capFirst(this.$route.params[param])
      }
      return r.meta.label || r.name
    },
  },
}
</script>

<style lang="scss" scoped></style>
