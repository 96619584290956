/**
 * Takes an array of items and sums a currency field (ie price)
 * @param {array} items
 * @param {string} property
 * @returns
 */
export default function sumCurrency (items, property) {
  return items.reduce( (sum, item) => {
    return sum + item[property]
  }, 0)
}