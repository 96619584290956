import ApiService from '@/services/api.service'
import { get } from 'lodash'
import { FETCH_PROCESSING_FEES } from './actions.type'
import { SET_PROCESSING_FEES } from './mutations.type'


const state = {
  processingFees: []
}

const getters = {
  processingFees (state) {
    return state.processingFees
  }
}

const actions = {
  /**
   * get processing fee tiers by vendor
   * @param {Object} context
   * @param {String} params.vendorId <required>
   * @param {String} params.active
   * @param {String} params.sortBy
   * @param {Integer} params.limit
   */
  async [FETCH_PROCESSING_FEES] (context, {
    vendorId,
    active = 'Y',
    sortBy = 'boxSizeMin:ASC',
    limit = 9999,
  }) {
    try {
      const params = { vendorId, active, sortBy, limit }
      const { data } = await ApiService.query('/productProcessingFeeRules', { params: params })
      context.commit(SET_PROCESSING_FEES, get(data,'data.processingFeeRules',[]))
    } catch (err) {
      context.commit(SET_PROCESSING_FEES, [])
      if (get(err,'response.statusCode') !== 404) {
        throw(err)
      }
    }
  }
}

const mutations = {
  [SET_PROCESSING_FEES] (state, processingFees) {
    state.processingFees = processingFees
  }
}

export default {
  name: 'processingFees',
  state,
  actions,
  mutations,
  getters
}
