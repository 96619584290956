<template>
  <CElementCover
    v-show="loading"
    :boundaries="[{ sides: ['top', 'left'], query: selector }]"
    :opacity="opacity"
    :class="coverClasses"
  >
    <div class="d-inline-block py-3 px-4 shadow bg-white rounded nowrap">
      <h3 v-html="text"></h3>
      <CProgress :value="100" size="sm" animated />
    </div>
  </CElementCover>
</template>

<script>
export default {
  name: 'Overlay',
  props: {
    selector: {
      required: true,
      type: String,
      default: 'body',
    },
    loading: {
      required: true,
      type: Boolean,
      default: false,
    },
    text: {
      required: false,
      type: String,
      default: 'Please wait...',
    },
    opacity: {
      required: false,
      type: Number,
      default: 0.8,
    },
    rounded: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    coverClasses() {
      let classes = []
      if (this.rounded) {
        classes.push('rounded')
      }
      return classes
    },
  },
}
</script>
